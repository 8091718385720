import { Injectable } from '@angular/core';
import { ConfigurationService } from '@smals/ngx-configuration-service';
import { config } from '../configuration-data';


/*
We have override ConfigurationService because we have a problem with aotAngular, spread not working in module.
I extend Configuration service and override getEnvironmentVariable()
  - first check normal in variable
  - second check defaultConfig variable
*/
export class UrlService {
  public getCurrentUrl(): string {
    return window.location.href;
  }
}

@Injectable()
export class ConfigurationServiceExtended extends ConfigurationService {


  constructor() {
    super(config, new UrlService(), null);
  }

  public getEnvironmentVariable(key: string): any {
    const env = super.getEnvironment();
    if (super.getEnvironmentVariable(key) != undefined) {
      return super.getEnvironmentVariable(key);
    } else if (config.variables[env].defaultConfig != undefined && config.variables[env].defaultConfig[key] != undefined) {
      return config.variables[env].defaultConfig[key];
    }
    return;
  }



}


