import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpErrorResponse } from '@angular/common/http';
import { NGXLogger } from "ngx-logger";

import { StoreService } from "../store/store.service";
import { StateFn } from '../store/StateFn';
import {
  AuthenticateException,
  CommonException,
  BusinessException,
  TechnicalException,
  ProviderException
} from './CommonException';
import { ActionsStore } from '../store/actions-store';
import { AuthenticationState } from '../store/store-data-interface';
import { Operation } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData/Operation';

import { ErrorService } from "../error.service";
import { CacheStorageService } from '../services/cacheStorage.service';
import { ApplicationConstants } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class HandleExceptionService {

  private _exception: CommonException;

  constructor(private logger: NGXLogger, private _router: Router, private _actionsStore: ActionsStore, private storeService: StoreService, private stateFn: StateFn
    , private _errorService: ErrorService, private _cacheStorageService: CacheStorageService, private _appConstant: ApplicationConstants) {
  }

  resetLogin() {
    sessionStorage.clear();
    this._cacheStorageService.clearLocalStorageAndKeepOptin(this.storeService.store.getValue().userSession?.user?.cbeNumber);
    this._actionsStore.removeError();

  }

  invalidatedToken() {

    this.exception = new AuthenticateException();
    this.exception.code = 401;
    this.exception.error = 'tokenIsInvalid';
    this.errorHandled();
    this.forwardToErrorPage()
  }
  forbiddenAccessException = new AuthenticateException('forbidden', 403);

  private forwardToErrorPage() {
    if (!this.stateFn.isCurrentPage('error')) {
      this._router.navigate(['/error'], { skipLocationChange: true });
    }
  }

  invalidatedClockToken() {

    this.exception = new AuthenticateException();
    this.exception.code = 401;
    this.exception.error = 'tokenHasClockNotSynchronized';
    this._actionsStore.nextStepAuthentication(AuthenticationState.error);

    this.errorHandled();
    this.forwardToErrorPage()
  }

  forbiddenAccess(exception: AuthenticateException = this.forbiddenAccessException) {

    this.exception = new AuthenticateException();
    this.exception.code = 403;
    this.exception.error = exception.error;
    this.exception.message = exception.message;

    this._actionsStore.nextStepAuthentication(AuthenticationState.error);
    this.errorHandled();

    this.forwardToErrorPage()
  }
  forbiddenScopesAccess() {
    const exception: AuthenticateException = this.forbiddenAccessException;

    exception.redirect = true;

    this.forbiddenAccess(exception);
  }

  tokenIsExpired() {
    this.storeService.store.next({
      ...this.storeService.store.getValue(),
      tokenIsExpired: true,
    });
  }

  loadingTimeout() {

    const exception = new TechnicalException();
    exception.code = 504;
    exception.message = 'Timeout loading Screen';
    exception.error = 'generalError';
    exception.redirect = true;
    this.handlerError(exception);
  }

  handlerError(error: any) {

    const originalException = (error['originalException'] == undefined) ? error?.error : error['originalException'];

    let providerRegistryId;
    //come from handleError in eboxenterpriseClient
    if (error["registryId"] != undefined) {
      providerRegistryId = error["registryId"];
    }
    if (error["operation"] != undefined) {
      const message = error?.message;
      const errorResponse = (error instanceof Response) ? error : error["error"];
      error = new HttpErrorResponse({ error: errorResponse, status: errorResponse.status, statusText: error['operation'], url: errorResponse.url });
      if (message != undefined) error.message = message;
    }
    if (error instanceof HttpErrorResponse) {

      this.exception = new AuthenticateException();
      const errorResponse: HttpErrorResponse = error as HttpErrorResponse
      this.exception.code = errorResponse.status;
      this.exception.message = errorResponse.message;
      this.exception.error = this.getExceptionError(errorResponse.status);
      if(errorResponse.url) this.exception.uri = errorResponse.url;
      switch (errorResponse.status) {

        case 401:
          this.tokenIsInvalid();
          this.exception.redirect = true;
          break;

        case 403:
          this.exception.redirect = true;
          break;

        case 504:
          this.exception = new BusinessException({ error: 'timeout', message: this.exception.message, redirect: false, code: this.exception.code });
          (<CommonException>this.exception).type = (errorResponse.statusText == Operation.GET_DOCUMENT_CONTENT || errorResponse.statusText == Operation.GET_MESSAGE) ? errorResponse.statusText : 'page';


          break;
        case 404:
          this.exception.redirect = true;
          (<CommonException>this.exception).type = (errorResponse.statusText == Operation.GET_DOCUMENT_CONTENT || errorResponse.statusText == Operation.GET_MESSAGE) ? errorResponse.statusText : 'page';
          break;

        case 500:
          this.exception.redirect = false;
          break;

        case 503:
          this.exception.redirect = false;
          break;

        default:
          this.exception.redirect = false;
          break;
      }
      this.logger.error('exception error', this.exception.error);

    } else if (error instanceof BusinessException || error instanceof TechnicalException || error instanceof AuthenticateException || error instanceof CommonException || error instanceof ProviderException) {
      if (!this._appConstant.isNullOrUndefined(error.code) && this._appConstant.isNullOrUndefined(error.error)) {
        (<CommonException>error).error = this.getExceptionError(error.code);
      }
      this.exception = error;
    } else {
      this.exception = { type: 'other', error: 'generalError', message: error['message'], code: 500, redirect: false };
      this.logger.error('exception error', error);
    }
    if (providerRegistryId != undefined) {
      this.exception.providerRegistryId = [providerRegistryId];
    }
    this.exception.originalException = originalException;
    if (this.exception.error !== '') {

      this.errorHandled();

      if (this.exception.redirect) {
        this._actionsStore.nextStepAuthentication(AuthenticationState.error);

        if (this.exception.code === 404) {
          this.forwardToErrorPage()
        } else if (this.exception.code === 401 && !this.stateFn.isAttemptConnection()) {
          const language: string = localStorage.getItem('be_social_security_workenv_language');
          this.resetLogin();

          localStorage.setItem("INFO_SITE_VISITED", "true");
          localStorage.setItem("attemptLastConnection", "handleException");
          if (language !== null) {
            this._router.navigate(['/reset'], { queryParams: { language: language } });

          } else {
            this._router.navigate(['/reset']);
          }
        } else {
          this.forwardToErrorPage()
        }
      } else {
        this._actionsStore.addAlert("error", this.exception);
      }
    }
  }

  handlerProviderError(providers: ProviderException[]) {
    this._actionsStore.addException("error", providers);
    providers.forEach(providerException => this.handlerError(providerException));
  }


  // Warning! Do not put it in actions.service to avoid circular dependency
  private tokenIsInvalid() {
    this.storeService.store.next({
      ...this.storeService.store.getValue(),
      tokenIsInvalid: true,
    });
  }

  private errorHandled() {
    this.storeService.store.next({
      ...this.storeService.store.getValue(),
      subsystems: {
        ...this.storeService.store.getValue().subsystems,
        error: this.exception,
      }
    });
    this._errorService.handleError(this.exception);

  }

  public get exception(): CommonException {
    return this._exception;
  }

  public set exception(value: CommonException) {
    this._exception = value;
  }

  private getExceptionError(status: number): string {

    switch (status) {

      case 401:
        return 'tokenIsInvalid';

      case 403:
        return 'forbidden';

      case 504:
        return "timeout"
      case 404:
        return 'notFound';

      case 500:
        return 'internalServer';
        break;

      case 503:
        return 'serviceUnavailable';

      default: return 'generalError';
    }
  }

}
