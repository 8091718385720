import { Injectable } from "@angular/core";
import { INGXLoggerConfig, INGXLoggerMetadata, NgxLoggerLevel, NGXLoggerWriterService } from "ngx-logger";

@Injectable()
export class NGXLoggerWriterServiceCustom extends NGXLoggerWriterService{
/* eslint-disable no-console */
    

     /** Log to the console specifically for IE */
     logIE(metadata: INGXLoggerMetadata, config: INGXLoggerConfig, metaString: string) {
        // Coloring doesn't work in IE
        
        switch (metadata.level) {
            case NgxLoggerLevel.WARN:
                
                console.warn(`${metaString} `, this.resolveMessage(metadata));
                break;
            case NgxLoggerLevel.ERROR:
            case NgxLoggerLevel.FATAL:
                // eslint-disable-next-line no-console
                console.error(`${metaString} `, this.resolveMessage(metadata));
                break;
            case NgxLoggerLevel.INFO:
                console.info(`${metaString} `, this.resolveMessage(metadata));// eslint-disable-next-line no-console
                break;
            default:
                console.log(`${metaString} `, this.resolveMessage(metadata));// eslint-disable-next-line no-console
        }
    }
    /** Log to the console */
    logModern(metadata: INGXLoggerMetadata, config: INGXLoggerConfig, metaString: string) {
        const color = this.getColor(metadata, config);
        switch (metadata.level) {
            case NgxLoggerLevel.WARN:
                console.warn(`%c${metaString}`, `color:${color}`, this.resolveMessage(metadata));
                break;
            case NgxLoggerLevel.ERROR:
            case NgxLoggerLevel.FATAL:
                console.error(`%c${metaString}`, `color:${color}`, this.resolveMessage(metadata));
                break;
            case NgxLoggerLevel.INFO:
                console.info(`%c${metaString}`, `color:${color}`, this.resolveMessage(metadata));
                break;
            //  Disabling console.trace since the stack trace is not helpful. it is showing the stack trace of
            // the console.trace statement
            // case NgxLoggerLevel.TRACE:
            //   console.trace(`%c${metaString}`, `color:${color}`, message, ...additional);
            //   break;
            case NgxLoggerLevel.DEBUG:
                console.debug(`%c${metaString}`, `color:${color}`, this.resolveMessage(metadata));
                break;
            default:
                console.log(`%c${metaString}`, `color:${color}`, this.resolveMessage(metadata));
        }
    }

    resolveMessage(metadata: INGXLoggerMetadata) :string{let message:string = metadata.message;

        const additional = metadata.additional || [];
        additional.forEach(add => {
           message= message.replace("%s",String(add));
            
        });
        return message;
    }

    /* eslint-enable no-console */

}
