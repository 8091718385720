import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { EnterprisePreferencesService, LoadingService } from '../../../services';
import { StateFn } from "../../../store/StateFn";
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from "ngx-logger";
import { StoreService } from '../../../store/store.service';
import { ActionsStore } from '../../../store/actions-store';
import { StatusForms } from '../../../store/store-data-interface';
import { FormsHelper } from '../../../helper/Forms-helper';
import { NgModel } from '@angular/forms';
import { ApplicationConstants } from '../../../app.constants';
import { EmailType } from '../../../interfaces/enterprise-preferences';
import { EmailsToUpdate } from '../../../interfaces/emailsToUpdate';

@Component({
  selector: 'app-email-update',
  templateUrl: './email-update.component.html',
  styles: []
})
export class EmailUpdateComponent implements OnInit, OnDestroy {

  emailPattern = "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])";

  emailEnterpriseModel: string;
  emailEnterpriseConfirmed: boolean;
  emailMandataryModel: string;
  emailMandataryConfirmed: boolean;

  private subscriptions: Subscription[] = [];


  @ViewChild('emailEnterprise') emailEnterprise: NgModel;
  @ViewChild('emailMandatary') emailMandatary: NgModel;


  @Input()
  accessReadOnly: boolean;

  editMode = false;

  @Input()
  submitAction: Observable<boolean>;
  @Input()
  isOptionFlow = false;
  @Output()
  actionEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  isEnterpriseAdmin: boolean;
  isMandataryAdmin: boolean;
  isMandatary: boolean;
  isEnterprise: boolean;
  isAccessAdmin :boolean;
  isRae:boolean;

  statusForm: StatusForms;
  handlingsError: {
    status: StatusForms, errorOBj: {
      typeOfError: string;
      args?: [string];
    }
  };

  StatusFormsEnum: any;
  emailTypeEdit: any;

  constructor(private logger: NGXLogger, private enterprisePreferencesService: EnterprisePreferencesService,
    private _route: ActivatedRoute, private stateFn: StateFn,
    private _loadingService: LoadingService, private storeService: StoreService, private _actionsStore: ActionsStore, public appConstant: ApplicationConstants) {
  }

  ngOnInit() {
    this.statusForm = null;
    this.submitAction?.subscribe((isValidate: boolean) => {
      if (isValidate) {
        this.onSubmit();
      }
    })
    if (this.stateFn.isAccessTokenValid()) {
      this.subscriptions.push(this.storeService.store.subscribe(s => {
        this.editMode = this.stateFn.getDataForms().isModeUpdateMail && !this.accessReadOnly;
        if (s.userSession.user !== null && (this.emailEnterpriseModel == null || this.emailMandataryModel == null)) {
          s.userSession.user.emails.forEach(emailObjct => {
            if (EmailType.ENTERPRISE == emailObjct.emailType) {
                this.emailEnterpriseModel = emailObjct.email ?? '';
                this.emailEnterpriseConfirmed = emailObjct.isConfirmed;
            } else if (EmailType.MANDATARY == emailObjct.emailType) {
                this.emailMandataryModel = emailObjct.email ?? '';
                this.emailMandataryConfirmed = emailObjct.isConfirmed;
            }
          })
        }
      }));
      this.isEnterpriseAdmin = this.stateFn.isEnterpriseAdmin();
      this.isMandataryAdmin = this.stateFn.isMandataryAdmin();
      this.isMandatary = this.stateFn.isMandatary();
      this.isAccessAdmin = this.stateFn.isAccessAdmin();
      this.isRae = this.stateFn.isRae();
      this.isEnterprise = this.stateFn.isEnterpriseUser() || this.isEnterpriseAdmin;

      this._route.queryParams.subscribe(params => {
        if (params.modeUpdateMail != undefined && params.modeUpdateMail || this.editMode) {
          this._actionsStore.updateModeMail(true);
          if(params.typeEditMail){
            this.emailTypeEdit = params.typeEditMail;
          }
        }
      });

    }
  }

  onEditMode(emailTypeEdit) {
    this.emailTypeEdit = emailTypeEdit;
    this._actionsStore.updateModeMail(!this.editMode);
    window.history.pushState(null, "", location.pathname + "?modeUpdateMail=" + this.editMode+"&typeEditMail="+emailTypeEdit);
  }

  onSubmit(): Promise<boolean> {
    let result = Promise.resolve(true);
    if (this.stateFn.isAccessTokenValid()) {
      const emailsToUpdate: EmailsToUpdate = { emails: [] };
      this.storeService.store.getValue().userSession.user.emails.forEach(emailInner => {

        if (EmailType.ENTERPRISE === emailInner.emailType && this.stateFn.isEnterpriseAdmin() && this.stateFn.isAdmin() && this.emailEnterprise !=undefined) {
          emailsToUpdate.emails.push({ email: this.emailEnterprise.control.value, emailType: EmailType.ENTERPRISE })
        } else if (EmailType.MANDATARY === emailInner.emailType && this.stateFn.isMandataryAdmin() && this.stateFn.isAdmin() && this.emailMandatary != undefined) {
          emailsToUpdate.emails.push({ email: this.emailMandatary.control.value, emailType: EmailType.MANDATARY })
        }
      })
      if (emailsToUpdate.emails.length != 0) {
        this._loadingService.start({ serviceRunStart: "submit email-update", delayedStart: true });
        return this.enterprisePreferencesService.updateEnterpriseEmails(emailsToUpdate).then(resp => {
          this._actionsStore.updateEmail(emailsToUpdate.emails, null);
          window.history.pushState(null, "", location.pathname);
          this.emailTypeEdit=undefined;
          return StatusForms.success;
        }).catch(error => {
          this.handlingsError = FormsHelper.handleSendErrorForms(error, StatusForms.error);
          // TODO PROPER ERROR HANDLING
          this.logger.error('Error update Mail', error);
          if (this.handlingsError.status == StatusForms.failed) {
            this.resetEmail();
          }

          if (this.emailEnterprise != undefined) {
            this.emailEnterprise.control.markAsDirty();
            this.emailEnterprise.control.setErrors({ "pattern": true });
          }
          if (this.emailMandatary != undefined) {
            this.emailMandatary.control.markAsDirty();
            this.emailMandatary.control.setErrors({ "pattern": true });
          }
          result = Promise.resolve(false);
          return this.handlingsError.status;
        }).then(
          (resp: StatusForms) => {
            this._loadingService.stop('Email Update Component');
            this._actionsStore.updateStatusMailForms((resp));

            this.statusForm = null;
            this.actionEmitter.emit(resp === StatusForms.success);


            return result;
          }
        );
      } else {
        if (this.accessReadOnly) {
          sessionStorage.setItem("ebox_".concat(this.stateFn.getUser().cbeNumber).concat("_hidePopup"), "true");
        }
        this.actionEmitter.emit(true);

        return result;
      }
    } else {
      this.actionEmitter.emit(true);

      return result;
    }


  }

  onCancel() {
    if (this.stateFn.isAccessTokenValid()) {
      this.resetEmail();
    }
    this._actionsStore.updateModeMail(!this.editMode);
    window.history.pushState(null, "", location.pathname);
    this.emailTypeEdit= undefined;
  }

  private resetEmail() {
    this.storeService.store.getValue().userSession.user.emails.forEach(emailObjct => {
      
      if (EmailType.ENTERPRISE == emailObjct.emailType) {
          this.emailEnterpriseModel = emailObjct.email ?? '';
      } else if (EmailType.MANDATARY == emailObjct.emailType) {
          this.emailMandataryModel = emailObjct.email ?? '';
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }



}
