import { TranslateService } from "@ngx-translate/core";
import { ConfigurationRegistries, LocalRegistry } from "./configuration-registries";
import * as Sentry from '@sentry/browser';
import {StoreService} from "../store/store.service";


export interface RegsitryApiI {
    resetDocumentProviders();
    addDocumentProviders(...registries);
    getDocumentProviders();
    showAdded();
    toggleDocumentProvider(providerId: string);
}
export class RegistriesApi implements RegsitryApiI{
    

    
    private __translateService: TranslateService;
    private idNumberProvider = 0;
    private __storeService : StoreService;

    init(_translateService: TranslateService,  _storeService:StoreService) {
        this.__translateService = _translateService;
        this.__storeService = _storeService;
        return this;
    }
    info(){
        return "App:"+"\n"
                    + "Method:" +"\n"
            + "    - app.showAdded()" + "\n"
            + "    - app.getDocumentProviders()" + "\n"
            + "    - app.addDocumentProvider(Registry[]) : Registry =>{ 'url':string,'version':string,'names':{fr:string,nl:string,de:string}}" +"\n"
            + "    - app.resetAllFromStorage()" + "\n"
            + "    - app.toggleDocumentProvider(idRegistry:string) : Disabled/Enabled any document provider" + "\n"
;
    }
//changeState
    getDocumentProviders(){
        if(this.__storeService.store.getValue().userSession.configClient != null &&  this.__storeService.store.getValue().userSession.configClient.registries != null) {
            return JSON.stringify(this.__storeService.store.getValue().userSession.configClient.registries);
          };
          return null;
    }

    showAdded() {
        Sentry.captureMessage("Use add to show all registries");
        if (localStorage.getItem(ConfigurationRegistries.KEY_STORAGE_DP) != null) {
            return JSON.stringify(localStorage.getItem(ConfigurationRegistries.KEY_STORAGE_DP));
        }else{
            return this.__translateService.instant("registry.showLocalStorage.none");  
        }
    }

    addDocumentProviders(...registries) {
        Sentry.captureMessage("Use add to addDocumentProviders: " + JSON.stringify(registries));
        let localRegistries: LocalRegistry[] = [];
        if (localStorage.getItem(ConfigurationRegistries.KEY_STORAGE_DP) != null) {
             localRegistries = JSON.parse(localStorage.getItem(ConfigurationRegistries.KEY_STORAGE_DP));
            
            
        }
        const mergedRegistries: LocalRegistry[] = Array.prototype.concat(...registries as LocalRegistry[], localRegistries);
         

        mergedRegistries.forEach((registry:LocalRegistry)=>{
            registry["id"] ="providerId_"+this.idNumberProvider;
            this.idNumberProvider++;
        })

        localStorage.setItem(ConfigurationRegistries.KEY_STORAGE_DP, JSON.stringify(mergedRegistries));
        return this.__translateService.instant("registry.add.response").replace("{{value1}}", JSON.stringify(mergedRegistries));  

    }
    /*removeFromStorage(providerId: string) {
        if (localStorage.getItem(ConfigurationRegistries.KEY_STORAGE_DP) != null) {
        const localRegistries: Registry[] = JSON.parse(localStorage.getItem(ConfigurationRegistries.KEY_STORAGE_DP));
        const newLocalRegistry: Registry[] = localRegistries.filter(registry => registry.id != providerId);
        if(newLocalRegistry.length !=0){
            localStorage.setItem(ConfigurationRegistries.KEY_STORAGE_DP, JSON.stringify(newLocalRegistry));
            return this.__translateService.instant("registry.add.response").replace("{{value1}}", JSON.stringify(newLocalRegistry));  
        }else{
            localStorage.removeItem(ConfigurationRegistries.KEY_STORAGE_DP);
            return this.__translateService.instant("registry.remove.last") + " \n "+  this.__translateService.instant("registry.remove.none");  
        }
        }else{
            return this.__translateService.instant("registry.remove.none");  
        }

    }*/
    resetDocumentProviders() {
        localStorage.removeItem(ConfigurationRegistries.KEY_STORAGE_DP);
        localStorage.removeItem(ConfigurationRegistries.KEY_STORAGE_DP_STATE);
        return this.__translateService.instant("registry.removeAll");  
    }

    toggleDocumentProvider(providerId:string){
        Sentry.captureMessage("Use add to toggleDocumentProvider: ");

        let newState: string[] = [providerId];
        if(localStorage.getItem(ConfigurationRegistries.KEY_STORAGE_DP_STATE) != null){
            let stateForAllProviders:string[] = JSON.parse(localStorage.getItem(ConfigurationRegistries.KEY_STORAGE_DP_STATE));
            const value =stateForAllProviders.find((id:string) => id===providerId);
            if(value ===undefined){
                stateForAllProviders.push(providerId);
            }else{
                stateForAllProviders = stateForAllProviders.filter((id:string) => id != providerId);
            }
            newState = stateForAllProviders;
        }
        if (newState.length == 0) {
            localStorage.removeItem(ConfigurationRegistries.KEY_STORAGE_DP_STATE);
            return this.__translateService.instant("registry.changeState.enabled").replace("{{value1}}", providerId) + " \n " + this.__translateService.instant("registry.changeState.empty");
        }else{
            localStorage.setItem(ConfigurationRegistries.KEY_STORAGE_DP_STATE, JSON.stringify(newState));
            return this.__translateService.instant("registry.changeState.disabled").replace("{{value1}}", JSON.stringify(newState));
        }

    }
    
}


