import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ActionsService, LoadingService } from "../../services";
import { StoreService } from "../../store/store.service";
import { StateFn } from "../../store/StateFn";
import { UserType } from "../interfaces/enum/userType";
import { Partition } from "../interfaces/partition";
import { PacmanActionStore } from '../../pacman/store/pacmanActionStore';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { AddEditPartitionComponent } from "./add-edit-partition.component";
import { PacmanDataForms, StatusForms } from "../../store/store-data-interface";
import { PacmanProblem } from "../interfaces/pacmanProblem";
import { DeletePartitionComponent } from "./delete-partition.component";
import { PacmanActionService } from "../services/pacmanAction.service";
import { MessagesMoveToOtherPartition } from "@smals/ebox-enterprise-client/dist/src/external-lib/client_api";
import { BusinessException, CommonException } from "../../error/CommonException";
import { HandleExceptionService } from "../../error/handle-exception-service.service";
import { ResponseWithState } from "@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData";

export interface PartitionManagement extends Partition {
  humanUsers: Number,
  applicationUsers: Number,


}

@Component({
  selector: 'app-management-partitions',
  templateUrl: './management-partitions.component.html',
  styles: []
})
export class ManagementPartitionsComponent implements OnInit, OnDestroy {


  allPartitions: PartitionManagement[];
  storePartitionisLoaded: boolean;
  userTypeEnum: any;
  isMobile: boolean;
  subScribe: Subscription;
  currentLang: string;
  successMessage: string;
  errorMessage: string;
  showOnlyMyPartitions = true;
  hasAdminRightPartitition: boolean;
  pacmanDataForms: PacmanDataForms;

  constructor(
    private _router: Router, private modalService: NgbModal, private _pacmanActionsStore: PacmanActionStore, private _pacmanActionsService: PacmanActionService, private _actionsService: ActionsService, private _storeService: StoreService,
    private _stateFn: StateFn, private loadingService: LoadingService, private _logger: NGXLogger, private errorHandlerService: HandleExceptionService) {
    this.userTypeEnum = UserType;
    this.isMobile = false;
    this.loadingService.start({ serviceRunStart: "Management partition", delayedStart: false });
  }
  ngOnDestroy(): void {
    this.subScribe?.unsubscribe();

  }

  ngOnInit(): void {
    this.hasAdminRightPartitition = this._stateFn.isAdmin();
    if (this.hasAdminRightPartitition) {
      this.subScribe = this._storeService.store.subscribe(state => {
        this.isMobile = state.isMobile;
        this.currentLang = this._stateFn.getLanguage();
        this.allPartitions = this._stateFn.getAllPartitions() as PartitionManagement[];
        this.storePartitionisLoaded = true;
        this._logger.debug('stop loading partitions')
        this.loadingService.stop("management partitions");
      });
    } else {
      this.loadingService.stop("Management partition: No access to one adminPartition");
    }
  }

  delete(partitionToDelete?: PartitionManagement) {
    this.clearMessages();
    const options: NgbModalOptions = {
      keyboard: true,
      backdrop: 'static',
      centered: true,
      windowClass: 'deletePartitionModal',

    };
    options.keyboard = false;
    const deletePartitionModal = this.modalService.open(DeletePartitionComponent, options);
    deletePartitionModal.shown.subscribe(() => this.loadingService.stop("start open delete Partition"));
    deletePartitionModal.componentInstance.isMobile = true;
    deletePartitionModal.componentInstance.myPartitions = this.allPartitions.filter(p => p.id != partitionToDelete.id);
    deletePartitionModal.componentInstance.partitionToDelete = partitionToDelete;
    const self = this;
    deletePartitionModal.closed.subscribe(result => {
      if (result['targetPartitionId'] != null) {
        const moveByRegistry: Record<string, MessagesMoveToOtherPartition> = {};
        this._stateFn.getConfigRegistriesLoaded().forEach(registry => {
          moveByRegistry[registry.id] = {
            sourcePartitionId: partitionToDelete.id,
            targetRecipientPartitionId: result["targetPartitionId"],
            targetSenderPartitionId: result["targetPartitionId"]
          } as MessagesMoveToOtherPartition;
        });
        this._actionsService.moveMessagesIdToPartition(moveByRegistry).then(resultMove => {
          self._pacmanActionsService.deletePartition(partitionToDelete.id).then(success => {
            self.successMessage = 'pacman.managementPartition.delete.success';
            self.allPartitions.splice(self.allPartitions.indexOf(partitionToDelete), 1);
            this._pacmanActionsStore.updatePartitionUserForms(partitionToDelete.id, StatusForms.success);
          }).catch(error => {
            this._logger.log(error);
            self.errorMessage = 'pacman.managementPartition.delete.error';
            this._pacmanActionsStore.updatePartitionUserForms(partitionToDelete.id, StatusForms.error);
          })
        }).catch(error => {
          const stateErr = error as ResponseWithState;
          const errorBusiness = new BusinessException({ message: 'moveBeforeDelete', error: error, redirect: false, code: 404 });
          (<CommonException>errorBusiness).type = "MOVE_BEFORE_DELETE";
          errorBusiness['providerRegistryId'] = Object.keys(stateErr.state);
          this.errorHandlerService.handlerError(errorBusiness);
          this._pacmanActionsStore.updatePartitionUserForms(partitionToDelete.id, StatusForms.error);
        }).finally(() => {
          self.loadingService.stop("end delete");
        });
      }
    });
  }

  backResultToManagePage() {
    this._pacmanActionsStore.clearPacmanStatusForms();
    this._router.navigateByUrl("/management");
  }

  addPartition(partition?: PartitionManagement) {
    this.clearMessages();
    const options: NgbModalOptions = {
      modalDialogClass: 'addEditPartition',
      keyboard: true,
      backdrop: 'static',
      centered: true
    };
    if (this.isMobile) {
      options.container = ".managementPartition";
      options.centered = false;
      options.backdropClass = "bg-white"
    }
    const modalAddPartRef = this.modalService.open(AddEditPartitionComponent, options);
    modalAddPartRef.componentInstance.isMobile = this.isMobile;
    const self = this;
    modalAddPartRef.closed.subscribe(result => {
      if (result["status"] === StatusForms.error) {
        const pacmanProblem = result["errorOBj"] as PacmanProblem;
        this.errorMessage = 'pacman.pacmanProblem.' + pacmanProblem.type.toString();
        return;
      }
      const newPartition = result["partition"] as Partition;
      if (result["edit"]) {
        self.successMessage = 'pacman.addPartition.messageUpdateSuccess';
        self.allPartitions.find(p => p.id == newPartition.id).name = newPartition.name;
      } else {
        self.successMessage = 'pacman.addPartition.messageCreateSuccess';
        const name = newPartition.name != undefined ? newPartition.name : null;
        self.allPartitions.push({
          applicationUsers: 0,
          hasAdminRight: true,
          nbHumanUsers: 1,
          humanUsers: 1,
          users: new Array({ id: this._stateFn.getUser().ssin, type: UserType.HUMAN }),
          id: newPartition.id,
          name: name,
          hasAccess: true
        })
      }
      this._pacmanActionsStore.updatePartitionUserForms(newPartition.id, StatusForms.error);
    });
    modalAddPartRef.componentInstance.partitionToEdit = partition;
  }

  private clearMessages(): void {
    this.successMessage = null;
    this.errorMessage = null;
  }

  public get partitions(): PartitionManagement[] {
    if (this.showOnlyMyPartitions) {
      return this.allPartitions.filter(p => p.hasAdminRight && p.hasAccess);
    }
    return this.allPartitions.filter(p => p.hasAdminRight);
  }

}


