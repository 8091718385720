import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { StateFn } from '../../store/StateFn';
import { ActionsService } from "../../store/actions.service";


@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styles: []
})
export class SearchBarComponent implements OnInit {

  @ViewChild('searchTerms') inputSearch: ElementRef<HTMLInputElement>;

  @Output("searchTermsChanged") searchTermsChangedEmitter = new EventEmitter<string>();

  @Output("openSearchFilters") openSearchFiltersEmitter = new EventEmitter<boolean>();

  @Output("submitSearchWithFilters") submitSearchWithFiltersEmitter = new EventEmitter<boolean>();

  filtersOpen = false;

  currentValue = '';

  @Input()
  isMobile:boolean;

  showResetButton:boolean;

  constructor( private _actionService: ActionsService, private stateFn: StateFn) {
    this.showResetButton = false;
  }

  ngOnInit() {
    this.stateFn.register(state => {
      const ret = state.messageFilters.q;
      this.currentValue = ret == null ? '' : ret;
      if (this.currentValue.trim().length > 0) {
        this.showResetButton = true;
      } else if (this.showResetButton) {
        this.showResetButton = false;
      }
      this.filtersOpen = this.searchHasFilters();
      if (this.filtersOpen) {
        this.openSearchFiltersEmitter.emit(this.filtersOpen);
      }
    });

  }

  onSearchTermsChange(event) {
    this.currentValue = event.target.value;
    if (this.currentValue.trim().length > 0 && !this.showResetButton){
      this.showResetButton = true;
    } else if (this.currentValue.trim().length == 0 && this.showResetButton){
      this.showResetButton = false;
    }
    this.searchTermsChangedEmitter.emit(this.currentValue);
  }

  onSearchSubmit(type: string) {
    if (this.currentValue.trim() !== '') {
      this._actionService.startNewSearch(this.currentValue);
    }
    else {
      this._actionService.clearSearchAndMessageFiltersAndPage();
    }
  }

  @Output()
  public closeSearchMobile = new EventEmitter();

  clickCloseSearchMobile() {
    if (this.inputSearch != undefined){
      this.inputSearch.nativeElement.value ="";
      this.currentValue = "";
      this.searchTermsChangedEmitter.emit(this.currentValue)
      this.showResetButton = false;
    }
  };

  public clickAdvanced() {
    this.filtersOpen = ! this.filtersOpen;
    this.openSearchFiltersEmitter.emit(this.filtersOpen);
    setTimeout(() => {
      this.searchTermsChangedEmitter.emit(this.currentValue)
    }, 200);
  }

  searchHasFilters(): boolean {
    const open = this.stateFn.getMessageFilters().includeExpiringMessages
        || this.stateFn.getMessageFilters().includeReadMessages
        || this.stateFn.getMessageFilters().includeRegisteredMessages
        || this.stateFn.getMessageFilters().includeRequiredAction
        || this.stateFn.getMessageFilters().publicationDateTo != null
        || this.stateFn.getMessageFilters().publicationDateFrom != null
        || this.stateFn.getMessageFilters().senderOrganizationSelection != null
        || this.stateFn.getMessageFilters().senderApplicationSelection != null;
    return open;
  }

  protected readonly onkeydown = onkeydown;

  listenForSubmit($event: KeyboardEvent) {
    if (this.filtersOpen && $event.key == "Enter") {
      this.submitSearchWithFiltersEmitter.emit(true);
      $event.preventDefault();
    }
  }
}
