import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetterUpperCase'
})
export class FirstLetterUpperCasePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value != null) {
      value = value.toLowerCase();
    }
    return value.substring(0, 1).toUpperCase() + value.substring(1);

  }

}

