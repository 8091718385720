<div class=" managementPartitionUsers">
    <ng-container
        *ngIf="(isMobile && !modalOpened || !isMobile) && (storeIsLoaded || !hasAdminRightPartitition)">
        <p class="me-sm-0 ms-sm-0 mt-0 mb-sm-0 w-100 d-block">
            <a href="javascript:void(0)" (click)="backResultTopartitionlist()"
                data-testid="returnLink2" class="returnLink">{{"pacman.managementUser.backlinkPartitionsList"|translate}}</a>
        </p>

        <div
            class="{{isMobile ? 'd-block': 'd-flex align-items-center'}} border-bottom">
            <h2 class="ps-0 pt-0 pb-0  m-0 ">{{"pacman.managementUser.title"|
                translate:{value1 : (currentPartition == undefined)? '':(currentPartition.id
                | partitionNamePipe)} }}</h2>
        </div>
        <div *ngIf="!hasAdminRightPartitition" class="alert alert-danger mb-0">
            <div class="container-fluid">
                <div class="row"
                    [innerHTML]="'pacman.pacmanProblem.noAccesInAdminPartition' | translate">
                </div>
            </div>
        </div>
        <ng-container *ngIf="hasAdminRightPartitition">
            <div
                *ngIf="resultForm!=null && (resultForm==StatusFormsEnum.success || resultForm==StatusFormsEnum.failed)"
                role="alert"
                class="alert alert-{{resultForm==StatusFormsEnum.success ?'success':'danger'}} w-100 mt-2  text-body ms-0 me-sm-0 pt-2 pb-2 border-0">
                <p>
                    {{'pacman.managementUser.forms.status.'+(resultForm ==
                    StatusFormsEnum.success ?'success':'generalError') |
                    translate}}
                </p>
            </div>
            <nav class="mt-4">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item" role="presentation"><a
                            class="nav-link {{ currentUserType==UserTypeEnum.HUMAN ? 'active':''}}"
                            id="managementUser-tab" data-bs-toggle="tab"
                            href="#managementContent" role="tab"
                            aria-controls="managementContent"
                            [attr.aria-selected]="currentUserType==UserTypeEnum.HUMAN ? true : false"
                            (click)="changeView(UserTypeEnum.HUMAN)">{{"pacman.managementUser.tabs.users"|translate}}
                            ({{currentPartition.nbHumanUsers}})</a></li>

                </ul>
            </nav>
            <div
                class="tab-content p-3 bg-white border-start border-end border-bottom"
                id="nav-tabContent">
                <div class="tab-pane fade show active pb-4" role="tabpanel"
                    [attr.aria-labelledby]="currentUserType==UserTypeEnum.HUMAN ? 'managementUser-tab' : 'managementApp-tab'"
                    id="managementContent">
                    <button *ngIf="currentUserType==UserTypeEnum.HUMAN"
                        type="button"
                        class="btn btn-primary mt-2 mb-4 float-end"
                        (click)="addUser()"><i class="fa-solid  fa-circle-plus"></i>
                        {{"pacman.managementUser.adduserButton"|translate}}</button>
                    <table class="table mt-1">
                        <thead class="desktop-only">
                            <tr>
                                <th class="col-11" scope="col">{{"pacman.managementUser.tabs.".concat(currentUserType).concat("User.label")|translate}}</th>
                                <th class="col-1 text-center" scope="col"
                                    *ngIf="currentUserType==UserTypeEnum.HUMAN">{{"pacman.managementUser.actions.title"|translate}}</th>
                            </tr>
                        </thead>
                        <tbody class="d-xs-block">
                            <ng-container
                                *ngFor="let user of currentPartition.users; let i = index; let last = last">
                                <tr
                                    class="justify-content-center d-xs-block mb-2 mb-sm-0"
                                    *ngIf="user.type ==currentUserType">
                                    <td scope="row" class="d-xs-block">
                                        {{user.id | ssinPipe:"xx.xx.xx-xxx.xx"
                                        }}

                                        <ng-container
                                            *ngIf="user.userValue != undefined && (user.userValue.firstName !=undefined || user.userValue.lastName !=undefined)">
                                            -
                                            <ng-container
                                                *ngIf="user.userValue.firstName !=undefined">{{user.userValue.firstName}}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="user.userValue.lastName !=undefined">{{user.userValue.lastName}}</ng-container>
                                        </ng-container>
                                    </td>

                                    <td
                                        class="actions text-center d-xs-block  {{last && isMobile ? 'pb-0' :''}}"
                                        *ngIf="currentUserType==UserTypeEnum.HUMAN">
                                        <button placement="left"
                                            triggers="hover focus"
                                            [autoClose]="'outside'"
                                            [disableTooltip]="isMobile ? 'true' : null"
                                            ngbTooltip="{{'pacman.managementUser.actions.delete.user.tooltip' | translate:{value: (currentPartition.id | partitionNamePipe)} }}"
                                            type="button"
                                            class="btn {{isMobile ? 'float-end btn-secondary' :''}}"
                                            (click)="delete(user)">
                                            <i class="fa-solid fa-trash"></i>
                                            <span
                                                [class]="isMobile ?'d-inline-block ms-2':'visually-hidden'">{{'pacman.managementUser.actions.delete.user.button'
                                                | translate}}</span>
                                        </button>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </ng-container>
    </ng-container>

</div>
