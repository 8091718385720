import { Pipe, PipeTransform } from '@angular/core';
import { ObjectHelper } from '../../helper/ObjectHelper';
import { TranslatedStringPipe } from '../../pipes';
import { StateFn } from '../../store/StateFn';
import { Partition } from '../interfaces/partition';

@Pipe({
  name: 'partitionNamePipe',
  pure: false
})
export class PartitionNamePipe extends TranslatedStringPipe implements PipeTransform {

  private readonly partitions: Partition[];

  constructor(stateFn: StateFn) {
    super(stateFn);
    this.partitions = stateFn.getAllPartitions();
  }

  transform(partitionName: string, ...args: unknown[]): string {
    if (this.partitions != null && this.partitions.length > 0) {
      const partition = this.partitions.find(searchPartition => searchPartition.id == partitionName);
      if (partition != null && !ObjectHelper.isEmptyObject(partition.name) && partition.name[this.stateFn.getLanguage()] != undefined) {
        return super.transform(partition.name);
      }
    }
    return partitionName;
  }

}
