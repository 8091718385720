import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
  AttachmentData,
  MessageData,
  MessagesToUpdateWithRegistryConfig
} from "@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData";
import { ActionsService } from "../../store/actions.service";
import { StoreService } from "../../store/store.service";
import { BusinessException, CommonException } from "../../error/CommonException";
import { DownloadDocumentService, LoadingService } from "../../services";
import { HandleExceptionService } from "../../error/handle-exception-service.service";
import { StateFn } from "../../store/StateFn";
import { ActionsStore } from "../../store/actions-store";

import { ResponseZip } from '../../interfaces/download/response-zip';
import { ResponseStatus } from "@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData";
import { Operation } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData/Operation';
import { MessagesMoveToOtherPartition } from '@smals/ebox-enterprise-client/dist/src/external-lib/client_api';
import { Folder } from '../../store/store-data-interface';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MovePartitionComponent } from './movePartition/move-partition.component';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-actions',
  templateUrl: 'actions.component.html'
})

export class ActionsComponent implements OnInit, OnDestroy {

  @Input()
  public canReply: boolean;
  @Input()
  public messageId: string;
  @Input()
  public messageRegistryId: string;
  @Input()
  public isVisible: boolean;

  public folder: string;

  private modalPartitionChoice: NgbModalRef;



  @ViewChildren('checkboxOperation') messageCheckboxes: QueryList<ElementRef>;

  private _messagesSelectedDownload: string[] = [];
  selectedAllMessage: boolean;
  allPartitions: any;


  constructor(private modalService: NgbModal,
    private actionsStore: ActionsStore, private _stateFn: StateFn, private _handleException: HandleExceptionService,
    private _loadingService: LoadingService, private _actionsService: ActionsService,
    private storeService: StoreService, private _downloadService: DownloadDocumentService, private _logger: NGXLogger) {
    this.folder = this._stateFn.convertFolderToStr(this._stateFn.getCurrentFolder());
    this.actionsStore.clearedMessagesSelected();

    this.selectedAllMessage = false;
  }

  ngOnInit() {

    this.storeService.store.subscribe(state => {
      this.selectedAllMessage = false;

      this._messagesSelectedDownload = state.userActions.messagesSelected;
      if (this._messagesSelectedDownload.length == state.searchState.pageSize) {
        this.selectedAllMessage = true;
      }

      this.folder = this._stateFn.convertFolderToStr(this._stateFn.getCurrentFolder());
    });

    this.actionsStore.clearedMessagesSelected();

    this.allPartitions = this._stateFn.getAllPartitions();


  }

  public get messagesSelectedDownload(): string[] {
    return this._messagesSelectedDownload;
  }


  selectMessages(checkboxMessage: HTMLInputElement, type: string, allDefaultValue?: boolean) {
    const selections: { value: string, status: boolean }[] = []
    if (type === 'all') {
      this._stateFn.getSearchResult().items.forEach((message) => {
        selections.push({ value: "mid_" + message.messageId + "-rid_" + message.registryId, status: checkboxMessage.checked });
      });

      this._actionsService.filterSelectedMessages(selections);

    }

  }

  public getDownloadAllDocuments() {

    const listMessagetoZipped: { messageId: string, registryId: string, listDocument: AttachmentData[], hasBody: boolean }[] = [];


    this.messagesSelectedDownload.map((msg) => {
      this._stateFn.getSearchResult().items.forEach((message: MessageData) => {

        if ("mid_".concat(message.messageId, "-rid_", message.registryId) == msg) {

          message.statusMessageData.readStatus = true;
          listMessagetoZipped.push({
            messageId: message.messageId,
            registryId: message.registryId,
            listDocument: message.attachments,
            hasBody: message.hasBody
          });
        }

      })

    })



    this._downloadService.zipFileDocument(listMessagetoZipped).then((response: ResponseZip) => {
      if (response.status == ResponseStatus.complete || response.status == ResponseStatus.partial) {
        this._messagesSelectedDownload = [];
        this.messageCheckboxes.forEach((checkboxElmt) => {
          const checkbox: HTMLInputElement = checkboxElmt.nativeElement;
          if (checkbox.checked) {
            let parentNodeHtml: HTMLElement = <HTMLElement>checkbox;
            while (parentNodeHtml.nodeName !== 'TR') {
              parentNodeHtml = <HTMLElement>parentNodeHtml.parentNode;
            }
            parentNodeHtml.classList.remove('unread');
          }
          checkbox.checked = false;
        });


        this._actionsService.initializeAlertMessage();
        this.actionsStore.clearedMessagesSelected();
        this._loadingService.stop('actions-component getDownloadAllDocuments');
      }
      if (response.status == ResponseStatus.partial || response.status == ResponseStatus.fail) {
        throw response;
      }
    }).catch((responseZip: ResponseZip) => {
      let listErrorProviderId: string[];
      if (responseZip.listMessageError != undefined) {
        listErrorProviderId = responseZip.listMessageError.map(error => error.providerId);
      } else {
        listErrorProviderId = listMessagetoZipped.map(listMsgSelected => listMsgSelected.registryId);
      }


      listErrorProviderId = listErrorProviderId.filter((item, index) => listErrorProviderId.indexOf(item) == index);


      const errorBusiness = new BusinessException({ message: Operation.GET_DOCUMENT_CONTENT, error: responseZip.error, redirect: false, code: 404 });
      (<CommonException>errorBusiness).type = Operation.GET_DOCUMENT_CONTENT;
      errorBusiness['providerRegistryId'] = listErrorProviderId;
      if (responseZip.status == ResponseStatus.partial && responseZip?.listMessageError.length == 1) {
        errorBusiness.message = 'attachmentNotFound.one'
      } else if (responseZip.status == ResponseStatus.partial) {
        errorBusiness.message = 'attachmentNotFound.many'
      } else {
        errorBusiness.message = 'attachmentNotFound.none'
      }
      this._handleException.handlerError(errorBusiness);

    });

  }
  changeStateOfMessages() {

    const listMessageForChangeState: MessagesToUpdateWithRegistryConfig = { messagesToUpdateWithRegistryConfig: {} };

    this._stateFn.getSearchResult().items.forEach(message => {
      if (this.messagesSelectedDownload.indexOf("mid_" + message.messageId + "-rid_" + message.registryId) != -1) {
        if (listMessageForChangeState.messagesToUpdateWithRegistryConfig[message.registryId] == undefined) {
          listMessageForChangeState.messagesToUpdateWithRegistryConfig[message.registryId] = { messageIds: [] };
        }
        listMessageForChangeState.messagesToUpdateWithRegistryConfig[message.registryId].messageIds.push(message.messageId);
        listMessageForChangeState.messagesToUpdateWithRegistryConfig[message.registryId].isVisible = !message.statusMessageData.visible;
      }
    });

    this._stateFn.getSearchResult().items.map((message) => ({ messageId: message.messageId, registryId: message.registryId, listDocument: message.attachments })).filter(resp => resp != null)

    this._actionsService.updateVisibility(listMessageForChangeState).then(resp => {
      this.actionsStore.clearedMessagesSelected();
    })

  }

  private moveMessage(toPartitionId: string): Record<string, MessagesMoveToOtherPartition> {

    const messagesToMoveByRegistry: Record<string, MessagesMoveToOtherPartition> = {};
    this.messagesSelectedDownload.map((msg) => {
      const messageWithRegistry: { messageId: string, registryId: string } = this.getMessageIdAndRegistryId(msg);
      if (messageWithRegistry != null) {
        if (messagesToMoveByRegistry[messageWithRegistry.registryId] == undefined) {
          const messagesToMove: MessagesMoveToOtherPartition = {}
          messagesToMove.messageIds = [];
          //messagesToMove.sourcePartitionId
          if (Folder.out == this._stateFn.getCurrentFolder()) {
            messagesToMove.targetSenderPartitionId = toPartitionId;
          } else {
            messagesToMove.targetRecipientPartitionId = toPartitionId;
          }

          messagesToMoveByRegistry[messageWithRegistry.registryId] = messagesToMove;
        }
        messagesToMoveByRegistry[messageWithRegistry.registryId].messageIds.push(messageWithRegistry.messageId);

      }
      // 

    })
    return messagesToMoveByRegistry;

  }

  moveSelectedMessagesToOtherPartition() {
    this.openMovePartitionChoice();
    this.modalPartitionChoice.result.then(
      (statusFormModal) => {
        const result = this.moveMessage(statusFormModal["partitionId"]);
        this._actionsService.moveMessagesIdToPartition(result).then(refresh => {
          this.actionsStore.clearedMessagesSelected();
          this._actionsService.setNavbarAndSearchBasedOnState();

        });
      }, (rejected => {
        //default NGMODAL modal-dismiss-reasons need a object for our custom error
        this._logger.info('close modal');
      }));

  }

  openMovePartitionChoice() {

    const options: NgbModalOptions = {
      keyboard: true,
      backdrop: 'static',
      centered: true,
      windowClass: 'movePartitionModal',

    };
    options.keyboard = false;
    this.modalPartitionChoice = this.modalService.open(MovePartitionComponent, options);
    this.modalPartitionChoice.shown.subscribe(() => this._loadingService.stop("start open Move Partition choice"));
    this.modalPartitionChoice.componentInstance.isMobile = true;
    this.modalPartitionChoice.componentInstance.myPartitions = this.allPartitions;
    this.modalPartitionChoice.componentInstance.nbMessageSelected = this.messagesSelectedDownload.length;

  }

  private getMessageIdAndRegistryId(messageRefCheckBox): { messageId: string, registryId: string } {

    const regexpMessageIdAndRegistryId = /mid_(.+)-rid_(\w+)/;
    const match = messageRefCheckBox.match(regexpMessageIdAndRegistryId);
    if (match != null) {
      return { messageId: match[1], registryId: match[2] }
    }

    return null;
  }



  ngOnDestroy(): void {
    this.actionsStore.clearedMessagesSelected();
  }
}
