import {Injectable} from "@angular/core";
import { DatePipe } from "@angular/common";
import {ApplicationConstants} from "../app.constants";


@Injectable()
export class DateHelper {
  static DISPLAY_FORMAT = 'dd/MM/yy';
  static DEFAULT_FORMAT_FULL_YEAR = 'dd/MM/yyyy';
  static DEFAULT_FORMAT = 'yyyy-MM-dd';

  constructor(private datepipe: DatePipe){}

  getFormatedDate(date: Date, format: string = DateHelper.DEFAULT_FORMAT): string{
    return this.datepipe.transform(date, format);
  }

  dateIsExpired(dateExpirate: any, offDay: number= ApplicationConstants.offDay) {
    if (typeof (dateExpirate) === 'string') {
      const appExpirationDate = new DatePipe('en-US').transform(dateExpirate, ApplicationConstants.DATEPATTERN).split('/');
      dateExpirate = new Date(Number(appExpirationDate[2]), Number(appExpirationDate[1]) - 1, Number(appExpirationDate[0]));
  
    }
  
    const expiredDate: Date = new Date();
    expiredDate.setDate(expiredDate.getDate() + offDay);
  
    return dateExpirate <= expiredDate;
  }
}
