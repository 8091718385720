import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ActionsStore } from '../store/actions-store';
import { LoadingService } from '../services/loading.service';
import { ConfigurationService } from '@smals/ngx-configuration-service';
import { AuthenticationError, LOGIN_PATHNAME, OAUTH_CALLBACK_PATHNAME, OpenIdService } from './openIdService';
import { NavHelper } from "../helper/NavHelper";
import { StateFn } from '../store/StateFn';
import { ActionsService } from "../store/actions.service";
import { StoreService } from '../store/store.service';
import { AuthenticationState } from '../store/store-data-interface';
import { AuthorizationEndpointParameterService, AuthResultSocSec, LoginParameterService } from './open-id-configurator';
import { catchError, filter, finalize, map, Observable, throwError } from 'rxjs';
import { HandleExceptionService } from '../error/handle-exception-service.service';
import { AuthenticateException } from '../error/CommonException';
import { CallbackContext } from 'angular-auth-oidc-client/lib/flows/callback-context';
import { ApplicationConstants } from '../app.constants';
import { AuthOidcClientConfig } from './authOidcClientConfig';
import {
    AuthenticatedResult,
    EventTypes,
    LoginResponse,
    OidcSecurityService,
    PublicEventsService,
    ValidationResult
} from 'angular-auth-oidc-client';


@Injectable()
export class Authentication {

    private validationResultToken: ValidationResult;

    constructor(private openIdService: OpenIdService, private _router: Router, private _loadingService: LoadingService, private configService: ConfigurationService,
        private navHelper: NavHelper, private actionService: ActionsService, private actionsStore: ActionsStore, private storeService: StoreService, private stateFn: StateFn, private _authorizationEndpointService: AuthorizationEndpointParameterService, private _oidcSecurityService: OidcSecurityService, private _handleExceptionService: HandleExceptionService, private _appConstant: ApplicationConstants, private _openIdConfid_config: AuthOidcClientConfig, private _openIdEventService: PublicEventsService) {
        this._openIdEventService
            .registerForEvents()
            .pipe(filter((notification) => (notification.type === EventTypes.NewAuthenticationResult && notification.value["isAuthenticated"] == true && notification.value["validationResult"] == ValidationResult.Ok && notification.value["isRenewProcess"] == true)))
            .subscribe((value) => {
                this._oidcSecurityService.forceRefreshSession().subscribe(({ accessToken }: LoginResponse) => {
                    this.openIdService.updateAccessToken(accessToken);
                });
            });
        this._openIdEventService
            .registerForEvents()
            .pipe(filter((notification) => (notification.type === EventTypes.NewAuthenticationResult && notification.value["validationResult"] !== ValidationResult.Ok)))
            .subscribe((noti) => {
                this.validationResultToken = noti.value["validationResult"];
            });

    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this._oidcSecurityService.isAuthenticated$.pipe(
            catchError(err => this.errorHandler(err)),
            map((result: AuthenticatedResult) => {

                const isLoginUrl = window.location.pathname == LOGIN_PATHNAME;
                const isOauthCallback = window.location.pathname == OAUTH_CALLBACK_PATHNAME;
                const isSessionExist = !this._appConstant.isNullOrUndefined(this.getAuthResultSocSec(this._openIdConfid_config.openIdConfig.configId));

                if (!result.isAuthenticated) {

                    const language = this.getLanguage();
                    // Default the language is it is present in the url or is invalid

                    this.initCsamFluxSimplified();
                    this.initLoginContext();
                    if (this.storeService.store.getValue().userSession.csamFluxSimplified != null && this.storeService.store.getValue().userSession.csamFluxSimplified) {
                        const companyId = this.getParamFromUrl('companyId');
                        const newDestination = this.configService.getEnvironmentVariable('urlCsam') + '&login.context=4' +
                            '&login.language=' + language +
                            (companyId != null ? '&companyId=' + companyId : '') +
                            "&returnURLOK=" + this.configService.getEnvironmentVariable('frontendUrl') + '?login.language=' + language + '&login.type=enterprise&prior.localisation=NOSS';
                        ;

                        this.navHelper.changeWindowLocationTo(newDestination);
                    } else if (this.storeService.store.getValue().userSession.accessToken === null || this.storeService.store.getValue().userSession.typeAuthentication === null) {
                        if (this.stateFn.getAuthenticationState() == AuthenticationState.login || (!isOauthCallback)) {
                            this._loadingService.firstStart({ "serviceRunStart": "First start authentication", delayedStart: false });
                        } else {
                            this._loadingService.start({ serviceRunStart: "authentication", delayedStart: false });
                        }

                        if (!isOauthCallback && !isLoginUrl && !isSessionExist) {
                            this.actionsStore.nextStepAuthentication(AuthenticationState.init);
                            this.openIdService.reset();



                            this.navHelper.changeWindowLocationTo(this.configService.getEnvironmentVariable('urlWebsiteInfo'));

                        } else if ((isOauthCallback || isSessionExist) && !isLoginUrl) {
                            this.actionsStore.nextStepAuthentication(AuthenticationState.callbackLogin);


                            let stateProcessed = this._oidcSecurityService.checkAuth();

                            if (isSessionExist && !this.tokenIsValid(this.getKeyFromOauthSessionFromSessionStorage(this._openIdConfid_config.openIdConfig.configId, "access_token_expires_at"))) {
                                stateProcessed = this._oidcSecurityService.forceRefreshSession();
                            } else {
                                stateProcessed = this._oidcSecurityService.checkAuth();

                            }

                            return stateProcessed
                                .pipe(catchError(err => this.errorHandler(err)))
                                .subscribe((response: LoginResponse) => {

                                    if (!response.errorMessage) {

                                        if (this.stateFn.isAttemptConnection()) {
                                            localStorage.removeItem('attemptLastConnection');
                                        }
                                        this.actionsStore.nextStepAuthentication(AuthenticationState.logged);


                                        return this._oidcSecurityService.getAuthenticationResult()
                                            .pipe(catchError(err => this.errorHandler(err)))
                                            .pipe(finalize(() => this._loadingService.stop("Finalize Authentication")))
                                            .subscribe((authRes: AuthResultSocSec) =>
                                                this.openIdService.oidcCompleteStoreO(response.userData, response.accessToken, authRes.scope)
                                                    .pipe(catchError(err => this.errorHandler(err)))
                                                    .subscribe(

                                                        {
                                                            next: (v) => {
                                                                if (isLoginUrl || isOauthCallback) {

                                                                    this._router.navigateByUrl("/", {
                                                                        replaceUrl: true,
                                                                    });

                                                                }
                                                                return true

                                                            }
                                                        }
                                                    )
                                            );


                                    } else {
                                        //treatment error ...
                                        // http://localhost:4200/oauthCallback?error=invalid_scope&state=22665c46fd866e35604e861b7b6a0aec4aN9chMrt&login.language=fr
                                        if (response.errorMessage.includes('no code in url')) {
                                            return this.convertToError(this.getCodeFromUrl());
                                        } else if (!this._appConstant.isNullOrUndefined(this.validationResultToken)) {
                                            switch (this.validationResultToken) {

                                                case ValidationResult.MaxOffsetExpired: return this.errorHandler(new Error(AuthenticationError.tokenHasClockNotSynchronized));
                                                default: return this.errorHandler(new Error(this.validationResultToken));
                                            }
                                        }

                                    }

                                }, err => this.errorHandler(err)).closed
                        } else {

                            this.actionsStore.nextStepAuthentication(AuthenticationState.oidcLogin);
                            //defineEndpoint
                            this._authorizationEndpointService.authorizationEndpoint = this.defineEndpoint(language);
                            //scope validation
                            this.openIdService.signinRedirect(this._authorizationEndpointService.authorizationEndpoint);


                        }

                    }

                    return false;
                } else {
                    return true;
                }

            },
                err => this.errorHandler(err)
            ),
            catchError(err => this.errorHandler(err)),
        );
    }
    errorHandler(error: any): Observable<boolean> {
        const err = error.toString();
        const authenticateException = new AuthenticateException();
        if (!err.includes('Error: ' + AuthenticationError.invalidScopes) && !err.includes('Error: ' + AuthenticationError.tokenHasClockNotSynchronized)) {
            authenticateException.message = err;
            authenticateException.error = "errorProcessLogin";
            authenticateException.code = 401;
            authenticateException.redirect = true;
            this._handleExceptionService.handlerError(authenticateException);


        } else if (err.includes('Error: ' + AuthenticationError.tokenHasClockNotSynchronized)) {
            this._handleExceptionService.invalidatedClockToken();
        } else if (err.includes('Error: ' + AuthenticationError.invalidScopes)) {
            this._handleExceptionService.forbiddenScopesAccess();

        }
        this._loadingService.stop("Stop loading Error Authentication");
        return throwError(() => error);
    }

    tokenIsValid(expires_in) {
        const now = Date.now();
        return now < expires_in;
    }






    private defineEndpoint(language): LoginParameterService {
        const authOption: LoginParameterService = {};
        authOption['login.language'] = language;
        authOption['login.type'] = "enterprise";
        authOption['prior.localisation'] = "NOSS";
        if (this.storeService.store.getValue().userSession.contextAuthentication != null && this.storeService.store.getValue().userSession.contextAuthentication == '2') {
            authOption['login.context'] = "2"
        }
        return authOption;
    }
    private getParamFromUrl(param: string, sizeOfValue?: number): any {
        let result = null;
        // (window.location.search !== '' && window.location.search.indexOf('CAMRegistration') != -1) ? window.location.search.substr(window.location.search.indexOf('CAMRegistration=') + 'CAMRegistration='.length, 1) : null;
        param += '=';
        if ((window.location.search !== '' && window.location.search.indexOf(param) != -1)) {


            if (sizeOfValue != undefined) {
                result = window.location.search.substr(window.location.search.indexOf(param) + param.length, sizeOfValue);
            } else {

                const firstStep = window.location.search.substring(window.location.search.indexOf(param) + param.length);
                if (firstStep.indexOf('&') == -1) {
                    result = firstStep;
                } else {
                    result = firstStep.substring(0, firstStep.indexOf('&'));
                }
            }
        }
        return result;
    }

    private getLanguage(): string {
        let language = this.getParamFromUrl('language', 2);
        // Default the language is it is present in the url or is invalid
        if (language == null || (language != "fr" && language != "nl" && language != "de")) {
            language = this.storeService.store.getValue().userSession.localeParameter;
        } else {
            this.actionService.changeLanguage(language);
        }

        return language;
    }

    private initLoginContext() {
        const loginContext = this.getParamFromUrl('login.context', 1);
        if (loginContext != null) {
            this.storeService.store.getValue().userSession.contextAuthentication = loginContext;
            return loginContext;
        }
    }
    private initCsamFluxSimplified() {
        const csamFluxSimplified = this.getParamFromUrl('CAMRegistration');
        if (csamFluxSimplified != null) {
            this.storeService.store.getValue().userSession.csamFluxSimplified = csamFluxSimplified as boolean;
        }

    }

    private getAuthResultSocSec(clientId: string, subkey?: string): AuthResultSocSec | string {
        const authResult: AuthResultSocSec = this.getKeyFromOauthSessionFromSessionStorage<AuthResultSocSec>(clientId, 'authnResult');
        if (authResult != null && !this._appConstant.isNullOrUndefined(authResult)) {
            if (subkey != undefined) return authResult[subkey];
            return authResult;
        }
        return null;
    }

    private getKeyFromOauthSessionFromSessionStorage<T>(clientId: string, key: string): T {
        const sessionInfos: CallbackContext = JSON.parse(sessionStorage.getItem(clientId)) as CallbackContext;
        if (sessionInfos != null && !this._appConstant.isNullOrUndefined(sessionInfos[key])) {
            return sessionInfos[key] as T;
        }
        return null;
    }
    private getCodeFromUrl(): string {
        return this.getParamFromUrl("code") ?? this.getParamFromUrl("error");
    }

    private convertToError(code: string) {
        switch (code) {
            case 'invalid_scope': throw new Error(AuthenticationError.invalidScopes);

            default: throw new Error("Error code unknow");
        }
    }

}


