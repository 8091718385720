<div id='content'>

    <div class="container-fluid container-xxxxxl" id="errorPage"
        data-testid='errorPage'>
        <div class='row'>
            <div class="card mb-3 me-4 me-sm-auto ms-sm-auto ms-4">
                <div class="card-header">

                    <h2 class="text-white">{{ ((currentException.error=='notFound')?
                        ( currentException.type=="GET_DOCUMENT_CONTENT" ?
                        'error.notFound.attachment.title' :'error.notFound.page.title')
                        :'error.title') | translate}}
                    </h2>
                </div>
                <div
                    class="card-body {{currentException ? currentException.error : ''}}">
                    <div class=" d-block align-items-center"
                        *ngIf="currentException.error=='forbidden'"
                        [innerHTML]="'error.authentication.'.concat(currentException.error) | translate:{href1:webSiteInfo + ('infoPath' | translate), a1:webSiteInfo + ('infoPath' | translate), href2:oldEbox, a2:oldEbox}"></div>
                    <div class=" d-block d-sm-flex align-items-center"
                        *ngIf="currentException.error!='forbidden' && currentException.error!='notFound' && currentException.error!='tokenHasClockNotSynchronized'">
                        <p>{{'error.authentication.' + currentException.error |
                            translate}}</p>
                    </div>
                    <div class=" d-block d-sm-flex align-items-center"
                        *ngIf="currentException.error=='notFound'">
                        <p>{{'error.' + currentException.error +
                            (currentException.type=="GET_DOCUMENT_CONTENT"?".attachment":".page")
                            +'.content'| translate}}</p>
                    </div>
                    <div class=" d-block align-items-center"
                        *ngIf="currentException.error=='tokenHasClockNotSynchronized'"
                        [innerHTML]="'error.authentication.'.concat(currentException.error)| translate"></div>
                </div>
                <div class="card-footer"
                    *ngIf="currentException.type === 'authentication'">
                    <button
                        *ngIf="currentException.error==='tokenIsInvalid' || currentException.error==='errorProcessLogin'"
                        class="d-block ms-auto me-auto mt-3 mt-sm-0 btn btn-primary"
                        (click)="resetLogin()">{{'header.connect' | translate}}</button>
                </div>
            </div>
        </div>
    </div>

</div>