import { Pipe, PipeTransform } from '@angular/core';
import {StateFn} from "../store/StateFn";

@Pipe({
  name: 'translatedString',
  pure: false
})
export class TranslatedStringPipe implements PipeTransform {
  public stateFn:StateFn;

  constructor(_stateFn: StateFn) {
    this.stateFn = _stateFn;
  }
  transform(value: any, args?: any): any {
    switch (this.stateFn.getLanguage()) {
      case 'fr': return value.fr;
      case 'nl': return value.nl;
      case 'de': return value.de;
      default:
        return value.fr;
    }
  }

}
