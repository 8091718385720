import { LocalizedString } from "@smals/ebox-enterprise-client/dist/src/external-lib/client_api/api";
import { Registry } from "@smals/ebox-enterprise-client/dist/src/external-lib/ServiceClient/RegistriesConfig";
import { BehaviorSubject } from "rxjs";

export class ConfigurationRegistries {


    public _useLocalDp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    static readonly KEY_STORAGE_DP = "registriesConfig";
    static readonly KEY_STORAGE_DP_STATE = "stateForAllProviders";

    constructor() { }

    readonly REGISTRIES_CONFIG = {
        'local': [
            { url: 'https://services-test.socialsecurity.be/REST/ebox/enterprise/messageRegistry/v3', version: '3.0', name: 'socsec', id: 'socsec_v1', names: { fr: "Sécurité Sociale", nl: "Sociale Zekerheid", de: "Soziale Sicherheit" } } as LocalRegistry,
            //{ url: 'https://services-int.socialsecurity.be/REST/ebox/enterprise/messageRegistry/v2', version: '3.0', name: 'socsec', id: 'socsec_v1', names: { fr: "Sécurité Sociale", nl: "Sociale Zekerheid", de: "Soziale Sicherheit" } } as LocalRegistry,
            //{ url: 'http://localhost:8280/REST/ebox/enterprise/messageRegistry/v3', version: '3.0', name: 'socsec', id: 'socsec_v1', names: { fr: "Sécurité Sociale", nl: "Sociale Zekerheid", de: "Soziale Sicherheit" } } as LocalRegistry,

            //{ mockProviderRegistry: new BosaRegistryMock(), name: 'bosa', id: 'bosa', version: '2.2' } as Registry

        ],
        'test': [
            { url: 'https://services-test.socialsecurity.be/REST/ebox/enterprise/messageRegistry/v3', name: 'socsec', id: 'socsec_v1', version: '3.0', names: { fr: "Sécurité Sociale", nl: "Sociale Zekerheid", de: "Soziale Sicherheit" } } as LocalRegistry,
            //{ mockProviderRegistry: new BosaRegistryMock(), name: 'bosa', id: 'bosa', version: '2.2' } as Registry

        ],
        'int': [
            { url: 'https://services-int.socialsecurity.be/REST/ebox/enterprise/messageRegistry/v3', name: 'socsec', id: 'socsec_v1', version: '3.0', names: { fr: "Sécurité Sociale", nl: "Sociale Zekerheid", de: "Soziale Sicherheit" } } as LocalRegistry,
        ],

        'acc': [
            { url: 'https://services-acpt.socialsecurity.be/REST/ebox/enterprise/messageRegistry/v3', name: 'socsec', id: 'socsec_v1', version: '3.0', names: { fr: "Sécurité Sociale", nl: "Sociale Zekerheid", de: "Soziale Sicherheit" } } as LocalRegistry,
            { url: 'https://ebox-acc.economie.fgov.be/ebox/enterprise/v1', name: 'fod_economy', id: 'fod_economy_v1_CR', version: '3.0', names: { fr: "SPF Économie", nl: "FOD Economie", de: "FÖD Wirtschaft" } } as LocalRegistry,
            { url: 'https://public.int.fedservices.be/api/federated-ebox-dp/3.0/enterprise/hip', name: 'bosa', id: 'bosa_CR', version: '3.0', names: { fr: "SPF Stratégie & Appui", nl: "FOD Beleid & Ondersteuning", de: "FÖD Politik und Unterstützung" } } as LocalRegistry,
            { url: 'https://test-ebox-dp.speos-esp.com/v3.0/os', name: 'speos', id: 'speos_CR', version: '3.0', names: { fr: "Speos", nl: "Speos", de: "Speos" } } as LocalRegistry,
            { url: 'https://iv.api.tni-vlaanderen.be/api/v2/ebox-enterprise', name: 'MAGDA', id: 'MAGDA_CR', version: '3.0', names: { fr: "Vlaanderen", nl: "Vlaanderen", de: "Vlaanderen" } } as LocalRegistry
        ],
        'prod': [
            { url: 'https://services.socialsecurity.be/REST/ebox/enterprise/messageRegistry/v3', name: 'socsec', id: 'socsec_v1', version: '3.0', names: { fr: "Sécurité Sociale", nl: "Sociale Zekerheid", de: "Soziale Sicherheit" } } as LocalRegistry,
            { url: 'https://ebox.economie.fgov.be/ebox/enterprise/v1', name: 'fod_economy', id: 'fod_economy_v1_CR', version: '3.0', names: { fr: "SPF Économie", nl: "FOD Economie", de: "FÖD Wirtschaft" } } as LocalRegistry,
            { url: 'https://public.fedservices.be/api/federated-ebox-dp/3.0/enterprise/hip', name: 'bosa', id: 'bosa_CR', version: '3.0', names: { fr: "SPF Stratégie & Appui", nl: "FOD Beleid & Ondersteuning", de: "FÖD Politik und Unterstützung" } } as LocalRegistry,
            { url: 'https://ebox-dp.speos-esp.com/v3.0/os', name: 'speos', id: 'speos_CR', version: '3.0', names: { fr: "Speos", nl: "Speos", de: "Speos" } } as LocalRegistry,
            { url: 'https://iv.api.vlaanderen.be/api/v2/ebox-enterprise', name: 'MAGDA', id: 'MAGDA_CR', version: '3.0', names: { fr: "Vlaanderen", nl: "Vlaanderen", de: "Vlaanderen" } } as LocalRegistry

        ],

    }



    getDefaultRegistryConfig(env: string): LocalRegistry[] {
        return this.REGISTRIES_CONFIG[env];


    }

    mergeRegistriesConfigWithLocalStorageHasNeeded(remoteDp: LocalRegistry[]): { registries: LocalRegistry[], useLocalDp: boolean } {
        let useLocalDp = false;
        if (localStorage.getItem(ConfigurationRegistries.KEY_STORAGE_DP) != null) {
            const localRegistries: LocalRegistry[] = JSON.parse(localStorage.getItem(ConfigurationRegistries.KEY_STORAGE_DP));
            Array.prototype.push.apply(remoteDp, localRegistries);
        }

        if ((localStorage.getItem(ConfigurationRegistries.KEY_STORAGE_DP) != null || localStorage.getItem(ConfigurationRegistries.KEY_STORAGE_DP_STATE) != null)) {
            useLocalDp = true;
        } else {
            useLocalDp = false;
        }

        return { registries: this.filterState(remoteDp), useLocalDp: useLocalDp };
    }



    private filterState(registries: LocalRegistry[]): LocalRegistry[] {
        if (localStorage.getItem(ConfigurationRegistries.KEY_STORAGE_DP_STATE) != null) {
            const stateForAllProviders: [] = JSON.parse(localStorage.getItem(ConfigurationRegistries.KEY_STORAGE_DP_STATE));
            if (stateForAllProviders.length != 0) {
                const cleanUpRegistries = registries.filter((registry: LocalRegistry) => {
                    const enabledProvider = stateForAllProviders.find((prodiderId: string) => prodiderId == registry.id);
                    return enabledProvider == undefined;
                });
                return cleanUpRegistries;
            }
        }
        return registries;
    }

}
export interface LocalRegistry extends Registry {
    names?: LocalizedString;
}
