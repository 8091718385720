import { EnterpriseUser } from "../models/enterprise-user";
import { OpenIdProfile } from "../auth/openIdService";
import { Alert } from "../interfaces";
import { CommonException } from '../error/CommonException';
import { MessageData, ConfigurationClient, SenderApplicationWithRegistryConfig, SenderOrganizationWithRegistryConfig, MessageTypeWithRegistryConfig, Sender } from "@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData";
import { Partition } from "../pacman/interfaces/partition";
import { MessageRegistry } from "../registries/interface/Message-Registry";

export const LANGUAGE_LOCALSTORAGE_KEY = 'be_social_security_workenv_language';



export enum VisibilityStatus {
  all,
  visible,
  invisible
}

export enum AuthenticationState {
  init = 0, login = 1, oidcLogin = 2, callbackLogin = 3, profileProcess = 4, logged = 5, error = 6
}
export enum Folder {
  in, out, hide
}
export enum StatusForms {
  success, failed, error
}

export enum DataLoadingState {
  unloaded, loading, loaded,
}


export interface SearchResults {
  totalItems: number,
  items: MessageData[],
  pageNumber: number,
  totalItemsMessageAction: number
}

export interface MessageFilters {
  q: string,
  visibility: VisibilityStatus,
  includeRegisteredMessages: boolean
  includeExpiringMessages: boolean
  includeReadMessages: boolean
  includeUnreadMessages: boolean
  senderOrganizationSelection: string | null
  senderApplicationSelection: string | null
  publicationDateTo: Date,
  publicationDateFrom: Date,
  folder: Folder | null,
  recipientPartition: string | null,
  senderPartition: string | null;
  includeRequiredAction: boolean;
}

export interface UserSession {
  user: EnterpriseUser | null,
  scopes: string[] | null,
  oidcProfile: OpenIdProfile | null,
  accessToken: string | null,
  configClient: ConfigurationClient | null,
  typeAuthentication: string | null,
  contextAuthentication: string | null,
  csamFluxSimplified: boolean | null,
  stepAuthentication: AuthenticationState,
  localeParameter: string | null,
  localDpActive: boolean,
  isAdmin: boolean,
  allRegistries: MessageRegistry[]
}
export interface DataForms {
  isModeReply: boolean,
  isModeManual: boolean,
  isModeUpdateMail: boolean,
  isModeMoveMessage: boolean,
  statusForms: StatusForms | null,
  sender: Sender | null,
  partitionId: string | null
}
export interface UserActions {
  messagesSelected: string[],
  dataForms: DataForms,
  currentPage: string | null
}




export interface SearchState {
  //TODO Introduce resultLoadingState with error state
  //resultLoadingState:DataLoadingState,
  pageSize: number,
  searchResults: SearchResults | null,
}
export interface ReferencesDataState {
  senderApplicationsState: SenderApplicationWithRegistryConfig[],
  senderOrganizationsState: SenderOrganizationWithRegistryConfig[],
  messageTypesState: MessageTypeWithRegistryConfig[],
}

export interface PacmanDataForms {
  isAddPartition: boolean,
  isEditPartition: boolean,
  isDeletePartition: boolean,
  isAddUserToPartition: boolean,
  statusForms: StatusForms | null,
  isDeleteUserToPartition: boolean,
  partitionId?: string
}
export interface PacmanSession {
  partitions: Partition[] | null,
  hasConsultationRightGeneral: boolean | null,
  dataForms: PacmanDataForms
}

export interface StoreShape {
  userSession: UserSession,
  userActions: UserActions,
  tokenIsInvalid: boolean,
  tokenIsExpired: boolean,
  subsystems: {
    error: CommonException,
    oauth: boolean,
    openId: boolean,
    useDpFromStorage: boolean;
    preferences: boolean,
    pacmanConsultationService: boolean,
    documentProviders: {
      socsec: boolean
    }
    showErrorInConsoleLog: boolean
  },
  alerts: Record<string, Alert[] | string | CommonException | CommonException[]>,
  messageFilters: MessageFilters,
  searchState: SearchState,
  referencesDataState: ReferencesDataState,
  isMobile: boolean,
  pacmanSession: PacmanSession

}

export interface OptinStorage {
  firstConnectionDate: Date,
  hasOptin: boolean,
  lastReminderDate: Date
}
