import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { StateFn } from "../../store/StateFn";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActionsService, EnterprisePreferencesService } from "../../services";
import { NGXLogger } from "ngx-logger";
import { Subject } from 'rxjs';
import { ActionsStore } from '../../store/actions-store';
import { DatePipe } from "@angular/common";
import { ApplicationConstants } from "../../app.constants";


export enum FlowStep {
  optin,
  optinReminder,
  email,
  welcome

}

@Component({
  selector: 'app-optinflow',
  templateUrl: './optinFlow.component.html',
})


export class OptinflowComponent implements OnInit {

  @Input() isMobile: boolean;
  @Input() public incomeStep: [FlowStep];
  private incomeStepIndex: number;
  public currentStep: FlowStep;
  updateOptinForm: UntypedFormGroup;
  public stepEnum: any;
  language: string;

  public validateEmailSubject: Subject<boolean> = new Subject();


  constructor(public logger: NGXLogger,
              public activeModal: NgbActiveModal,
              private stateFn: StateFn,
              private enterprisePreferencesService: EnterprisePreferencesService,
              private _actionsStore: ActionsStore,
              public datepipe: DatePipe, private _actionsService: ActionsService) {

    this.updateOptinForm = new UntypedFormGroup({
      optin: new UntypedFormControl(false)
    });
    this.stepEnum = FlowStep;
    this.incomeStepIndex = 0;
    this.language = this.stateFn.getLanguage();
  }


  ngOnInit(): void {
    this.currentStep = this.stepEnum[this.incomeStep[0]];
    if (this.currentStep == this.stepEnum[FlowStep.email]) {
      this._actionsStore.updateModeMail(true);
    }
  }


  updateOptin() {
    this.enterprisePreferencesService.acceptOptin();
  }

  nextStep() {
    this.incomeStepIndex++;
    if (this.incomeStep.length <= this.incomeStepIndex || this.incomeStep.length == 1) {

      this.closeModal();
    } else {
      this.currentStep = this.stepEnum[this.incomeStep[this.incomeStepIndex]];
      if (this.currentStep == this.stepEnum[FlowStep.email]) {
        this._actionsStore.updateModeMail(true);
      }
    }

  }

  validateMailAndContinue(isNextStep: boolean) {
    if (isNextStep) {
      this.nextStep();
      this._actionsStore.clearStatusForms();
    }

  }


  isAdmin(){
    return this.stateFn.isAccessAdmin();
  }

  validateOptinAndContinue() {

    if (!this.stateFn.getUser().exclusivelyEbox && this.updateOptinForm.value.optin) {
      this.updateOptin();
      this._actionsService.updateLocalStorage();
    }

    if (!this.updateOptinForm.value.optin) {
      this._actionsService.updateLastRemindDate(new Date());
    }
    this.nextStep();

  }


  isAccessOptinAndEMailModifications() {
    return this.stateFn.isAccessAdmin();
  }


  closeModal() {
    this.activeModal.close();
  }

  public get languageSupported() {
    return ApplicationConstants.LANGUAGE_SUPPORTED;
  }

  setLanguage(lang) {
    this.language = lang;
    this._actionsService.changeLanguage(lang);
  }

}
