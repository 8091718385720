import { Component, OnInit } from '@angular/core';

import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerFormat } from '../../services/datepicker-format';
import { SearchParameters } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';
import { StateFn } from "../../store/StateFn";

/**
 * Contrary to other components this one is used from within ts files by use of the NgnModal service. You can find usage
 * by looking for the component class name
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'filter-bar-modal',
  templateUrl: './filter-bar-modal.component.html',
  providers: [{ provide: NgbDateParserFormatter, useClass: DatepickerFormat }]
})
// tslint:disable-next-line:component-class-suffix
export class FilterBarModal implements OnInit {

  handleEscape(closeModal: boolean) {
    if (closeModal) {
      this.closeModal();
    }
  }


  constructor(public activeModal: NgbActiveModal, private stateFn: StateFn) {
    this.stateFn.subscribeToSearchEvent(state => {
      this.activeModal.close();
    });
  }

  ngOnInit() {
  }

  handleFiltering(searchEvent: SearchParameters) {
    this.activeModal.close(searchEvent);
  }


  closeModal() {
    this.activeModal.close('close');
  }

}





