import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { AuthenticationState, Folder, StoreShape } from "./store-data-interface";
import { ConfigurationService } from "@smals/ngx-configuration-service";


@Injectable()
export class StoreService {

  private storeInitial: StoreShape;
  public store: BehaviorSubject<StoreShape>;

  constructor(private _configService: ConfigurationService) {
    const showErrorInConsoleLog: boolean = this._configService.getEnvironmentVariable("showErrorInConsoleLog") != undefined ? this._configService.getEnvironmentVariable("showErrorInConsoleLog") : false;
    this.storeInitial = {
      userSession: {
        user: null,
        scopes: null,
        oidcProfile: null,
        accessToken: null,
        configClient: null,
        typeAuthentication: null,
        contextAuthentication: null,
        csamFluxSimplified: null,
        stepAuthentication: AuthenticationState.init,
        localeParameter: "nl",
        localDpActive: false,
        isAdmin: null,
        allRegistries: null
      },
      userActions: {
        messagesSelected: [],
        dataForms: {
          isModeReply: false,
          isModeManual: false,
          isModeUpdateMail: false,
          isModeMoveMessage: false,
          statusForms: null,
          sender: null,
          partitionId: null
        },
        currentPage: null,
      },
      tokenIsInvalid: false,
      tokenIsExpired: false,
      subsystems: {
        error: null,
        oauth: true,
        openId: true,
        useDpFromStorage: false,
        preferences: true,
        pacmanConsultationService: true,
        documentProviders: {
          socsec: true,
        },
        showErrorInConsoleLog: showErrorInConsoleLog
      },
      alerts: {},
      searchState: {
        //resultLoadingState:DataLoadingState.unloaded,
        pageSize: 10,
        searchResults: null
      },
      messageFilters: {
        //   visibility:VisibilityStatus.visible,
        visibility: null,
        q: null,
        includeExpiringMessages: null,
        includeReadMessages: null,
        includeRegisteredMessages: null,
        includeUnreadMessages: null,
        publicationDateFrom: null,
        publicationDateTo: null,
        senderApplicationSelection: null,
        senderOrganizationSelection: null,
        folder: Folder.in,
        recipientPartition: null,
        senderPartition: null,
        includeRequiredAction: null
      },
      referencesDataState: {
        senderApplicationsState: [],
        senderOrganizationsState: [],
        messageTypesState: []
      },
      isMobile: true,
      pacmanSession: {
        partitions: null,
        hasConsultationRightGeneral: null,
        dataForms: {
          isAddPartition: null,
          isEditPartition: null,
          isDeletePartition: null,
          isAddUserToPartition: null,
          isDeleteUserToPartition: null,
          statusForms: null
        }
      }
    };

    this.store = new BehaviorSubject<StoreShape>(this.storeInitial);
  }

}
