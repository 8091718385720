import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { APP_VERSION } from '../../app-version';
import { ChannelConfiguration } from '../../current-channel';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',

})
export class FooterComponent implements OnInit {
  title: string;
  version: string;
  releaseTime: Date;
  channel:string;

  constructor(
    public _translateService: TranslateService) {
    this.channel = ChannelConfiguration.KEY_CHANNEL;
  }

  ngOnInit() {
    this.version = APP_VERSION.version;
    if (APP_VERSION.timestamp.indexOf('/') === -1) {
      const patternDateTime: string[] = APP_VERSION.timestamp.split(' ');
      const patternDate: string[] = patternDateTime[0].split('-');
      const patternTime: string[] = patternDateTime[1].split(':');
      this.releaseTime = new Date(
        Number('20' + patternDate[0]),
        Number(patternDate[1]) - 1,
        Number(patternDate[2]),
        Number(patternTime[0]),
        Number(patternTime[1]),
        Number(patternTime[2]));
    } else {
      const patternDateTime: string[] = APP_VERSION.timestamp.split(' ');
      const patternDate: string[] = patternDateTime[0].split('/');
      const patternTime: string[] = patternDateTime[1].split(':');
      this.releaseTime = new Date(
        Number('20' + patternDate[0]),
        Number(patternDate[1]) - 1,
        Number(patternDate[2]),
        Number(patternTime[0]),
        Number(patternTime[1]),
        Number(patternTime[2]));
    }
  }

}
