<ul class="list-unstyled g-0 {{isMobile ? '' : ' d-flex'}} me-sm-3"
    *ngIf="message !== undefined">

    <ng-container *ngTemplateOutlet="isMobile ? mobileRowOne : desktopRowOne"></ng-container>
    <ng-container *ngTemplateOutlet="isMobile ? mobileRowTwo : desktopRowTwo"></ng-container>

    <ng-container *ngIf="!isMobile">
        <li
            class="receiptDate me-0  {{dateIsExpired(message.expirationDate) ? 'text-danger':''}}">{{message.receiptDate
            | date:'dd/MM/yy'}}</li>
    </ng-container>

</ul>
<p class="title text-truncate m-0">
    {{message.title}}
</p>

<ng-template #mobileRowOne>
    <li>
        <ul class="d-flex list-unstyled g-0 w-100">
            <ng-container *ngTemplateOutlet="desktopRowOne"> </ng-container>
            <li
                class="receiptDate me-1 {{dateIsExpired(message.expirationDate) ? 'text-danger':''}}">{{message.receiptDate
                | date:'dd/MM/yy'}}</li>

        </ul>
    </li>
</ng-template>

<ng-template #desktopRowOne>
    <li
        class="text-truncate transmitter ms-0 {{isMobile || !showPartitionName ? 'flex-grow-1': ''}}"
        *ngIf="folder != 'out'" [title]="senderTitle">
        {{'detail-of-message.from' | translate}} :
        <ng-container *ngIf="message.sender.type=='citizen'">
            {{message.sender.value}}
        </ng-container>
        <ng-container *ngIf="message.sender.type=='enterprise'">
            {{senderLabel}}
            <ng-container *ngIf="message.sender.id !='APPLICATION_MANUAL_PUB'">
                -
                {{appConstant.isNullOrUndefined(message.sender.applicationName)
                ? ('referenceData.notAvailable' | translate) :
                message.sender.applicationName}}
            </ng-container>
        </ng-container>

    </li>
    <li
        class="receiver text-truncate ms-0 {{isMobile || !showPartitionName  ? 'flex-grow-1': ''}}"
        *ngIf="folder == 'out'" [title]="recipientTitle">
        <ng-container *ngIf="message.recipient.type=='citizen'">
            {{message.recipient.value}}
        </ng-container>

        <ng-container
            *ngIf="appConstant.isNullOrUndefined(message.recipient.type) || (!appConstant.isNullOrUndefined(message.recipient.type) && message.recipient.type=='enterprise')">
            {{recipientLabel}}
            {{(message.sender.id == 'ReplyMessage') ?
            (message.sender.applicationName == null) ? - ('referenceData.notAvailable'
            | translate) : - (message.sender.applicationName) : null}}

        </ng-container>

    </li>
</ng-template>
<ng-template #mobileRowTwo>
    <li class="ms-0">
        <ul class="d-flex list-unstyled g-0 w-100">
            <ng-container *ngTemplateOutlet="desktopRowTwo"> </ng-container>
        </ul>
    </li>
</ng-template>

<ng-template #desktopRowTwo>

    <li class="flex-grow-1 text-break partition" *ngIf="showPartitionName">
        <span class="border rounded p-1 badge-light-green">
            {{'detail-of-message.to' | translate}} :
            {{!appConstant.isNullOrUndefined(message.recipient.partition) ?
            (message.recipient.partition | partitionNamePipe) : ('pacman.partitionName.default'
            | translate) }}
        </span>
    </li>

    <li class="tag text-nowrap">
        <app-tag [message]="message"></app-tag>
    </li>
</ng-template>
