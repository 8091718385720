import {ErrorHandler, Injectable} from '@angular/core';
import * as Sentry from "@sentry/browser";
import { NGXLogger } from 'ngx-logger';
import { StateFn } from './store/StateFn';



@Injectable({
  providedIn: 'root'
})
export class ErrorService implements ErrorHandler  {


  constructor( private _state:StateFn, private _ngxLogger:NGXLogger) {}

  handleError(error: any) {
    if (this._state.showLogInConsole()){
      this._ngxLogger.error(error);
    }

    if(error !== undefined) {
      Sentry.captureException(error);
    }
  }
}

