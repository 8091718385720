import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ActionsService } from '../../services';
import { StateFn } from '../../store/StateFn';
import { MessageFilters, VisibilityStatus } from '../../store/store-data-interface';
import { StoreService } from '../../store/store.service';
import { FilterBarModal } from '../modal-elements/filter-bar-modal.component';
import { SearchFilterBarComponent } from "./search-filter-bar.component";
import { SearchBarComponent } from "./search-bar.component";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styles: []
})
export class SearchComponent implements OnInit {

  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  @ViewChild(SearchFilterBarComponent) searchFilterBar: SearchFilterBarComponent;

  searchIsActive:boolean;
  hasFiltersSelected: boolean;
  filterSelectorIsActive: boolean;
  public isMobile: boolean;
  messageVisibility: VisibilityStatus;
  private _filterIsCollapsed = false;
  private _filtersParamsLength:number;

  public modalSearch: NgbModalRef;
  filterParams: MessageFilters;
  numberOfSearchResult:number;

  //needed to notify search-filter-bar of search terms value
  searchTerms: string;


	public get filterIsCollapsed(): boolean {
		return this._filterIsCollapsed;
	}

	public set filterIsCollapsed(value: boolean) {
		this._filterIsCollapsed = value;
	}

  isSearchCollapsed = false;


  constructor(private stateFn: StateFn, private storeService: StoreService, private _actionsService: ActionsService, private _translateService: TranslateService, private _modalService: NgbModal, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {

    this.stateFn.register(state => {
      this.searchIsActive = state.messageFilters.q != null;
      this.hasFiltersSelected = state.messageFilters.includeUnreadMessages
        || state.messageFilters.includeReadMessages
        || state.messageFilters.includeRegisteredMessages
        || state.messageFilters.includeExpiringMessages
        && this.stateFn.getAllReferencesData() != null//TODO: This is strange
      this.filterSelectorIsActive = this.hasFiltersSelected;

      // state.messageFilters.visibility is not included since visibility is not the same kind of filters (cannot be associated to other filters)
      this.isMobile = this.storeService.store.getValue().isMobile;
      this.numberOfSearchResult = this.stateFn.getNumberOfSearchResults();

      /**
       * Listen to search results
       */
      //this.pageSize = this.stateFn.getSearchResultPageSize();
      this.filterParams = this.stateFn.getMessageFilters();
      if (state.searchState.searchResults != null) {
        this.isSearchCollapsed = true;
      }
    });

  }

  get maskedMessagesAreBeingDisplayed() {
    return this.messageVisibility != VisibilityStatus.visible;
  }

  toggleMaskedMessageDisplay() {
    if (this.maskedMessagesAreBeingDisplayed) {
      this._actionsService.clearSearchAndMessageFiltersAndPage()
    }
    else {
      this._actionsService.showMaskedMesssages();
    }
  }

  public modifyParamFromFilter() {
    this.modalSearch = this._modalService.open(FilterBarModal, {
      windowClass: 'modalNavbar search-form',
      ariaLabelledBy: this._translateService.instant('header.search-bar.filter.open'),
      centered: true,
      keyboard: false
    });
    this.modalSearch.componentInstance.id = 'navbarsFilterMobile-search';
  }

  public getFilterParamsLength() {


    return this._filtersParamsLength;
  }



  public get actionService(){
    return this._actionsService;
  }

  // generate javascript:void(0)
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  searchWithFilters() {
    this.searchFilterBar.submitSearch();
  }
}
