import { ActionsStore } from "../../store/actions-store";
import { Injectable } from "@angular/core";
import { PacmanDataForms, StatusForms, StoreShape } from "../../store/store-data-interface";
import { ConfigurationService } from "@smals/ngx-configuration-service";
import { NGXLogger } from "ngx-logger";
import { StateFn } from "../../store/StateFn";
import { StoreService } from "../../store/store.service";
import { calculateUsers, Partition } from "../interfaces/partition";
import { UserPartitionForm } from "../interfaces/userPartitionForm";
import { sortUserPartition } from "../interfaces/userPartition";

@Injectable()
export class PacmanActionStore extends ActionsStore {
  private _storeService;
  private _logger: NGXLogger;

  constructor(logger: NGXLogger, _configService: ConfigurationService, storeService: StoreService, stateFn: StateFn) {
    super(logger, _configService, storeService, stateFn);
    this._storeService = storeService;
    this._logger = logger;
  }

  private addOrDeleteUser(partitions: Partition[], userPartition: UserPartitionForm, isAddMode: boolean): Partition[] {
    partitions.forEach(partition => {
      if (partition.id == userPartition.partitionId) {
        const newPartitionsUser = partition.users.filter(user => user.id != userPartition.user.id);
        this._logger.info("oldUsers :", JSON.stringify(partition.users), " and newUsers ", JSON.stringify(newPartitionsUser));
        partition.users = newPartitionsUser;
        if (isAddMode) {
          partition.users.push({...userPartition.user, rights: userPartition.rights});
        }
        partition.users.sort(sortUserPartition());
      }
    })
    return partitions;
  }

  updateUserRightspartitions(results: Partition[]) {
    const calculatedUsersAndApplicationsUsers = calculateUsers(results);
    this._storeService.store.next({
      ...this._storeService.store.getValue(),
      pacmanSession: {
        ...this._storeService.store.getValue().pacmanSession,
        partitions: calculatedUsersAndApplicationsUsers
      },

    });
  }

  addOrUpdatePartition(partition: Partition) {
    const oldStore: StoreShape = this._storeService.store.getValue();
    const pacmanSession = oldStore.pacmanSession;
    const existingPartition = pacmanSession.partitions.find(p => p.id === partition.id);
    if (existingPartition) {
      Object.assign(existingPartition, partition);
    } else {
      Object.assign(partition, {
        nbHumanUsers: 1,
        nbApplicationUsers: 0,
        hasAccess: true
      })
      pacmanSession.partitions.push(partition);
    }
    if (!existingPartition.name) {
      existingPartition.name = {};
    }
    this._storeService.store.next({
      ...oldStore,
      pacmanSession: {
        ...oldStore.pacmanSession,
      }
    });
  }

  resetPacmanPartition() {
    this._storeService.store.next({
      ...this._storeService.store.getValue(),
      pacmanSession: {
        ...this._storeService.store.getValue().pacmanSession,
        partitions: null
      },

    });
  }

  updateStatusAddUserForms(newStatusForm: StatusForms, user?: UserPartitionForm) {
    const oldStore: StoreShape = this._storeService.store.getValue();
    this._storeService.store.next({
      ...oldStore,
      pacmanSession: {
        ...oldStore.pacmanSession,
        dataForms: {
          ...oldStore.pacmanSession.dataForms,
          isAddUserToPartition: newStatusForm == StatusForms.success ? false : oldStore.pacmanSession.dataForms.isAddUserToPartition,
          statusForms: newStatusForm
        }
      }
    });
    this.updateUserRightspartitions(newStatusForm == StatusForms.success && user !== undefined ? this.addOrDeleteUser(oldStore.pacmanSession.partitions, user, true) : oldStore.pacmanSession.partitions);
  }

  updateStatusDeleteUserForms(newStatusForm: StatusForms, user?: UserPartitionForm) {
    const oldStore: StoreShape = this._storeService.store.getValue();
    this._storeService.store.next({
      ...oldStore,
      pacmanSession: {
        ...oldStore.pacmanSession,
        dataForms: {
          ...oldStore.pacmanSession.dataForms,
          isDeleteUserToPartition: newStatusForm == StatusForms.success ? true : oldStore.pacmanSession.dataForms.isDeleteUserToPartition,
          statusForms: newStatusForm
        }
      }
    });

    this.updateUserRightspartitions(newStatusForm == StatusForms.success && user !== undefined ? this.addOrDeleteUser(oldStore.pacmanSession.partitions, user, false) : oldStore.pacmanSession.partitions);
  }

  updateModeDeleteForms(toggleAddUser: boolean) {
    const oldStore = this._storeService.store.getValue();
    this._storeService.store.next({
      ...oldStore,
      pacmanSession: {
        ...oldStore.pacmanSession,
        dataForms: {...oldStore.pacmanSession.dataForms, isDeleteUserToPartition: toggleAddUser}
      }
    });
  }
  updateModeAddUserForms(toggleAddUser: boolean) {
    const oldStore = this._storeService.store.getValue();
    this._storeService.store.next({
      ...oldStore,
      pacmanSession: {
        ...oldStore.pacmanSession,
        dataForms: {
          ...oldStore.pacmanSession.dataForms,
          isAddUserToPartition: toggleAddUser,
        }
      }
    });
  }

  updatePartitionUserForms(partitionId: string, status: StatusForms) {
    const oldStore = this._storeService.store.getValue();
    this._storeService.store.next({
      ...oldStore,
      pacmanSession: {
        ...oldStore.pacmanSession,
        dataForms: {
          ...oldStore.pacmanSession.dataForms,
          isDeletePartition: true,
          statusForms: status,
          partitionId: partitionId
        }
      }
    });
  }

  clearPacmanStatusForms() {
    const oldStore = this._storeService.store.getValue();
    this._storeService.store.next({
      ...oldStore,
      pacmanSession: {
        ...oldStore.pacmanSession,
        dataForms: this.clearedPacmanForms()
      }
    });
  }

  clearedPacmanForms(): PacmanDataForms {
    return {
      isAddPartition: false,
      isEditPartition: false,
      isDeletePartition: false,
      partitionId: null,
      isAddUserToPartition: false,
      isDeleteUserToPartition: false,
      statusForms: null
    } as PacmanDataForms;
  }

  setConsultationRightGeneral(hasConsultationRightGeneral:boolean){
    const oldStore = this._storeService.store.getValue();
    this._storeService.store.next({
      ...oldStore,
      pacmanSession:{
        ...oldStore.pacmanSession,
        hasConsultationRightGeneral:hasConsultationRightGeneral
      }
    });
  }

  hasConsultationRightGeneral():boolean{
    return (this._storeService.store.getValue()as StoreShape).pacmanSession.hasConsultationRightGeneral;

  }
  checkPacmanConsultationService(isAvailable: boolean){
    const oldStore = this._storeService.store.getValue();
    this._storeService.store.next({
      ...oldStore,
      subsystems: {
        ...oldStore.subsystems,
        pacmanConsultationService: isAvailable
      }
    });  }
}
