<ng-template #replyStatus>
    <span class="reply-status-until"
        *ngIf="appConstant.isNullOrUndefined(message.replyStatusMessage.lastReplyDate) && !previousMessage">{{'detail-of-message.reply.until'
        | translate:{value1:(message.expirationDate | date:'dd/MM/yyyy')} }}</span>
    <span class="reply-status-done"
        *ngIf="!appConstant.isNullOrUndefined(message.replyStatusMessage.lastReplyDate)">{{'detail-of-message.reply.done'
        | translate:{value1:(message.replyStatusMessage.lastReplyDate | date:'dd/MM/yyyy')}
        }}</span>
</ng-template>
<div
    class="card me-sm-0 ms-sm-0  border-0 rounded-0 bg-transparent {{!previousMessage ? 'pt-0':''}}">
    <div class="card-header border-0 rounded-0 bg-transparent">
        <div class="row" *ngIf="message.replyStatusMessage.canReply && isMobile">
            <div class="col reply-status">
                <ng-container *ngTemplateOutlet="replyStatus"></ng-container>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <strong *ngIf="message.sender.type=='citizen'">{{'detail-of-message.transmitter.value'
                    | translate }} - {{message.sender.value}}</strong>
                <strong *ngIf="message.sender.type=='enterprise'"> {{'detail-of-message.from'
                    | translate }}: {{message.sender.companyName == null &&
                    message.sender.value != enterpriseUser.cbeNumber ? ('detail-of-message.enterpriseNumber'
                    | translate : {value1 : message.sender.companyId}) :
                    ( message.sender.value == enterpriseUser.cbeNumber ? ('detail-of-message.myEnterprise'
                    | translate) : message.sender.companyName).concat(
                    (message.sender.id == 'APPLICATION_MANUAL_PUB' ||
                    message.sender.id == 'APPLICATION_REPLY' ? '' : " - " +
                    message.sender.applicationName) )}}</strong>
                <span class="ms-3 receiptDate" *ngIf="!isMobile">{{message.receiptDate
                    | date:'dd/MM/yyyy'}}</span>
            </div>

            <div class="col-auto text-end reply-status mt-sm-0 mb-sm-0"
                *ngIf="message.replyStatusMessage.canReply && !isMobile">
                <ng-container *ngTemplateOutlet="replyStatus"></ng-container>
            </div>
        </div>
        <p *ngIf="!appConstant.isNullOrUndefined(message.recipient)">
            <!--              &&((message.recipient.type != null && message.recipient.type=='enterprise') || message.recipient.type == null)-->
            <span
                *ngIf="!appConstant.isNullOrUndefined(message.recipient.type) && message.recipient.type=='citizen'">{{'detail-of-message.transmitter.value'
                | translate }} - {{message.recipient.value}}</span>
            <span
                *ngIf="appConstant.isNullOrUndefined(message.recipient.type) || message.recipient.type=='enterprise'">{{'detail-of-message.to'
                | translate }}:
                {{message.recipient.companyName == null ? ((folder == 'out' ?
                'detail-of-message.enterpriseNumber' :
                'detail-of-message.myEnterprise') | translate : {value1 :
                message.recipient.value}) : ( message.recipient.value ==
                enterpriseUser.cbeNumber ? ('detail-of-message.myEnterprise' |
                translate) : message.recipient.companyName )}}

            </span>
            <span class="border rounded mt-2 px-1 badge-light-green">{{'detail-of-message.in'
                | translate }}: {{recipientPartitionName}} </span>

        </p>
        <p *ngIf="message.forUser != null">
            <span>{{( 'messages.people.'.concat(isLoggedUser(message.forUser.ssin)?
                'self' : 'colleague') |
                translate).concat(!isLoggedUser(message.forUser.ssin)? (
                message.forUser.label != undefined ? ': '.concat(message.forUser.label)
                :'') : '' ) }}</span>
        </p>
    </div>
    <div
        class="card-body rounded-0 bg-transparent border-end-0 border-start-0">
        <div class="attachmentList" *ngIf="message.attachments.length !=0">
            <h3 class="visually-hidden"><span>{{'detail-of-message.file' |
                    translate }}</span></h3>
            <app-attachments [registryId]="message.registryId"
                [attachments]="message.attachments" [onlyMaincontent]="false"
                [message]="message"></app-attachments>
        </div>
        <form
            *ngIf="!this.appConstant.isNullOrUndefined(this.statusMessageWithRequiredAction) && !previousMessage && folder != 'out'"
            class="form-inline {{isExecuted ? 'bg-light-primary' : 'bg-light-blue'}}"
            class="input-light">
            <div class="form-check">
                <input id="isExecutedCheckbox" class="form-check-input"
                    type="checkbox" (click)="updateMessageActionStatus($event)"
                    [checked]="isExecuted ? isExecuted : null" />
                <label for="isExecutedCheckbox"
                    class="form-check--label ms-2 {{isExecuted ? 'text-primary' : 'text-blue'}}">
                    <strong>
                        {{isExecuted ? ('detail-of-message.actionRequired.executed'
                        | translate) : ('detail-of-message.actionRequired.notExecuted'
                        | translate) }}
                    </strong>
                </label>
            </div>

        </form>
        <div class="bodyContent" *ngIf="message.hasBody != undefined">
            <h3 class="visually-hidden"><span>{{'detail-of-message.body' |
                    translate }}</span></h3>
            <div
                class="card-body {{isMobile?'':'row'}} me-sm-0 ms-sm-0 pe-sm-0 ps-sm-0">
                <div class="col-md-4 col-lg p-0">
                    <p>{{bodyContent}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <div class="dataBlock" *ngIf="businessData.size > 0 || messageActionsValuesSize > 0">
            <h3>
                <a href="javascript:void(0)"
                    (click)="collapseBusinessData.toggle()"
                    [attr.aria-expanded]="!collapseBusinessData"
                    aria-controls="collapseBusinessData"
                    class="d-flex align-items-center  justify-content-between">
                    {{'detail-of-message.complement' | translate }}
                    ({{businessData.size + messageActionsValuesSize }})
                    <i
                        class="fa {{businessDataCollapsed ? 'fa-chevron-down' : 'fa-chevron-up' }}"
                        aria-hidden="true"></i>
                </a>
            </h3>
            <div #collapseBusinessData="ngbCollapse"
                [(ngbCollapse)]="businessDataCollapsed">
                <ul data-testid="businessDataCard" class="list-unstyled">
                    <ng-container *ngFor="let bd of businessData | keyvalue">
                        <li>
                            <span>{{bd.value['title']}}</span>
                            <strong>
                                <ng-container
                                    *ngIf="bd.value['values'].length > 1">
                                    <ul class="list-inline multiple-value">
                                        <li class="list-inline-item"
                                            *ngFor="let valueBusinessData of bd.value['values']">
                                            {{valueBusinessData === 'true' ||
                                            valueBusinessData =='false' ? ('detail-of-message.businessData.'.concat(valueBusinessData)
                                            | translate) : valueBusinessData}}
                                        </li>
                                    </ul>
                                </ng-container>
                                <ng-container
                                    *ngIf="bd.value['values'].length === 1">
                                    {{getFormattedValue(bd.value['values'][0])}}
                                </ng-container>
                            </strong>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="message.statusMessageActions && message.statusMessageActions.length != 0">
                        <ng-container *ngFor="let msgAction of message.statusMessageActions">
                            <li><span>{{ 'detail-of-message.actionRequired.toDo' | translate }}</span><strong>{{'detail-of-message.businessData.' .concat(!msgAction.executed) | translate}}</strong></li>
                            <li *ngIf="msgAction.executionDueDate"><span>{{ 'detail-of-message.actionRequired.dueDate' | translate }}</span><strong>{{msgAction.executionDueDate | date:'dd/MM/yyyy'}}</strong></li>
                        </ng-container>

                    </ng-container>

                </ul>
            </div>
        </div>
        <div class="dataBlock">
            <h3>
                <a href="javascript:void(0)" (click)="collapseTechData.toggle()"
                    [attr.aria-expanded]="!techDataCollapsed"
                    aria-controls="collapseTechnicalData"
                    class="d-flex align-items-center  justify-content-between">
                    {{'detail-of-message.technical' | translate }}
                    ({{technicalData.size + 4 + (displayReadDate ? 1 : 0)}})
                    <i
                        class="fa {{techDataCollapsed ? 'fa-chevron-down' : 'fa-chevron-up' }}"
                        aria-hidden="true"></i>
                </a>
            </h3>
            <div #collapseTechData="ngbCollapse"
                [(ngbCollapse)]="techDataCollapsed">
                <ul data-testid="technicalDataCard" class="list-unstyled">
                    <li>
                        <span>{{'detail-of-message.id' | translate }}</span>
                        <strong>{{message.messageId}}</strong>
                    </li>
                    <li>
                        <span>{{'detail-of-message.readStatus.label' | translate
                            }}</span>
                        <strong>{{'detail-of-message.readStatus.'.concat(message.statusMessageData.readStatus
                            ? 'read' : 'unread') | translate}}</strong>
                    </li>
                    <li *ngIf="displayReadDate">
                        <span>{{'detail-of-message.readDate' | translate }}</span>
                        <strong>{{message.statusMessageData.readDate | date:'dd/MM/yy'}}</strong>
                    </li>
                    <li>
                        <span>{{'detail-of-message.expirationDate' | translate
                            }}</span>
                        <strong>{{message.expirationDate | date:'dd/MM/yyyy'}}</strong>
                    </li>
                    <li>
                        <span>{{'detail-of-message.messageType' | translate }}</span>
                        <strong>{{message.messagetype == null ? ('referenceData.notAvailable'
                            | translate) : message.messagetype}}</strong>
                    </li>
                    <ng-container
                        *ngFor="let techData of technicalData | keyvalue">
                        <li>
                            <span>{{techData.value['title']}}</span>
                            <strong>
                                <ng-container
                                    *ngIf="techData.value['values'].length > 1">
                                    <ul class="list-inline multiple-value">
                                        <li class="list-inline-item"
                                            *ngFor="let valueTechData of techData.value['values']">
                                            {{valueTechData == 'true' ||
                                            valueTechData =='false' ? ('detail-of-message.techData.'.concat(valueTechData)
                                            | translate) : valueTechData}}
                                        </li>
                                    </ul>
                                </ng-container>
                                <ng-container
                                    *ngIf="techData.value['values'].length === 1">
                                    {{getFormattedValue(techData.value['values'][0])}}
                                </ng-container>
                            </strong>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</div>
