<div class="row justify-content-between">
    <div
        class="form-group pe-xl-0 col-12 col-xl-auto d-flex-sm align-items-sm-center mb-0">
        <input type="checkbox" [defaultChecked]="false" name="allMessage"
            id="selectAllMessage" [(ngModel)]="selectedAllMessage"
            (click)="selectMessages($event.target || $event.srcElement , 'all')" />

        <p class="d-inline-block me-3 ms-3 mb-2 mb-lg-0" role="status">{{'messages.actionsMessage.messageSelected.'.concat((this.messagesSelectedDownload.length===0
            ?'none' :(this.messagesSelectedDownload.length===1 ?'one' :'many')
            )) | translate:{value: this.messagesSelectedDownload.length} }}</p>

        <div class="d-inline-block float-end float-xl-none">
            <button
                [disabled]="this.messagesSelectedDownload !== null && this.messagesSelectedDownload.length==0"
                class="btn btn-primary me-2" (click)="getDownloadAllDocuments()">
                {{'download-window.body.allDocument.download.name' | translate}}
            </button>

            <button *ngIf="folder != 'out'"
                [disabled]="this.messagesSelectedDownload !== null && this.messagesSelectedDownload.length==0"
                (click)="changeStateOfMessages()" class="btn btn-primary me-2">
                {{(folder == 'in'?'messages.actionsMessage.maskUnMaskMessage.mask.name':'messages.actionsMessage.maskUnMaskMessage.unmask.name')
                | translate }}
            </button>

            <button *ngIf="allPartitions == null || allPartitions.length > 1"
                [disabled]="this.messagesSelectedDownload !== null && this.messagesSelectedDownload.length==0"
                (click)="moveSelectedMessagesToOtherPartition()"
                class="btn btn-primary "> {{'messages.actionsMessage.move.button'
                | translate}} </button>
        </div>
    </div>
    <app-select-items-by-page
        class="col-auto mt-2 mt-xl-0 text-end flex-grow-1 "></app-select-items-by-page>

</div>