<div class="modal-header">
    <h3 class="align-middle" id="modal-basic-title">{{
        'pacman.addPartition.title' | translate }}</h3>
    <button *ngIf="!isMobile" type="button" class=" close me-0 pe-0"
        aria-label="Close" (click)="close()">
        <i class="fas fa-times  me-0" aria-hidden="true"></i>
        <span class="visually-hidden">{{"form.action.cancel" | translate}}</span>
    </button>
</div>
<form #formPartition [formGroup]="newPartitionForm" accept-charset="utf-8"
    method="post"
    class="{{(newPartitionForm.status ==='INVALID') ? 'was-validated':''}} {{isMobile ? 'row' :''}}"
    enctype="multipart/form-data" (ngSubmit)="save()">
    <div class="modal-body  bg-white">
        <div class="card mb-3 col p-0 alert-danger" *ngIf="partitionErrorType"
            role="alert">
            <div class="card-header text-danger">
                <h4 class="alert-heading">{{'form.status.error' | translate}}</h4>
            </div>
            <div class="card-body px-2">
                <div class="d-block align-items-center">
                    <p role="alert" class="invalid-feedback d-block w-100">
                        {{('pacman.pacmanProblem.' + partitionErrorType) |
                        translate:{value1: partitionIdInput.value} }}
                    </p>
                </div>
            </div>
        </div>

        <div class="alert alert-info mb-3">
            <p class="g-0 d-flex py-1">
                <i class="fas fa-xl fa-info-circle col-auto mt-3 pe-2"
                    aria-hidden="true" aria-describedby="#srOnlyInfo"></i>
                <span class="visually-hidden" id="srOnlyInfo">{{'communications.info.title'
                    | translate}}</span>
                <span class="col pe-1"
                    [innerHTML]="'pacman.addPartition.info' | translate">
                </span>
            </p>
        </div>

        <div class="form-group  {{!isMobile ? 'g-0' :''}}">
            <label for="partitionId"
                [class]="(partitionIdInput.errors  && partitionIdInput.dirty) ? 'col invalid-field mb-2 mt-1' : 'col mb-2 mt-1'">
                <span>{{ 'pacman.addPartition.partitionId' | translate }}
                    <ng-container *ngIf="partitionToEdit == null">({{'form.required.label'
                        | translate}})</ng-container></span>
            </label>
            <div class="input-group">
                <input id="partitionId" (focus)="partitionErrorType = null"
                    [readOnly]="partitionToEdit"
                    [className]="partitionToEdit ? ' form-control-plaintext' : 'form-control' + (partitionIdInput.errors ? ' is-invalid' : '')"
                    formControlName="partitionId" maxlength="50" [attr.readonly]="partitionToEdit ? true : null" />
            </div>
            <div *ngIf="partitionIdInput.invalid && partitionIdInput.dirty"
                role="alert" class="invalid-feedback d-block w-100">
                <div *ngIf="partitionIdInput.errors?.['required']">
                    {{ 'pacman.addPartition.partitionIdMandatory' | translate }}
                </div>
                <div *ngIf="partitionIdInput.errors?.['pattern']">
                    {{ 'pacman.addPartition.partitionIdInvalid' | translate }}
                </div>
            </div>
        </div>

        <div class="form-group  {{!isMobile ? 'g-0' :''}}">
            <label for="nameFr"><span>{{ 'pacman.addPartition.nameFr' |
                    translate }}</span></label>
            <div class="input-group">
                <input id="nameFr" class="form-control" formControlName="nameFr"
                    maxlength="50" />
            </div>
        </div>
        <div class="form-group  {{!isMobile ? 'g-0' :''}}">
            <label for="nameNl"><span>{{ 'pacman.addPartition.nameNl' |
                    translate }}</span></label>
            <div class="input-group">
                <input id="nameNl" class="form-control" formControlName="nameNl"
                    maxlength="50" />
            </div>
        </div>
        <div class="form-group  {{!isMobile ? 'g-0' :''}}">
            <label for="nameDe"><span>{{ 'pacman.addPartition.nameDe' |
                    translate }}</span></label>
            <div class="input-group">
                <input id="nameDe" class="form-control" formControlName="nameDe"
                    maxlength="50" />
            </div>
        </div>

    </div>

    <div
        class="modal-footer justify-content-between d-lg-flex {{isMobile?'w-100' :''}} ">
        <a href="javascript:void(0)"
            class="text-underline btn btn-link p-0 ms-0" (click)="close()">{{'pacman.addPartition.btnCancel'
            | translate}}</a>
        <button type="submit" [disabled]="newPartitionForm.invalid"
            class="btn btn-primary">{{ 'pacman.addPartition.btnSave' | translate
            }}</button>
    </div>
</form>
