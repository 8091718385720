<div class="card border-0">

  <div class="modal-header bg-transparent border-0 p-0 d-flex flex-wrap">
    <h3>{{'pacman.managementPartition.delete.header' | translate}}</h3>

  </div>
  <form [formGroup]="deletePartitionForm" method="post"
    class=" {{(deletePartitionForm.status ==='INVALID') ? 'was-validated':''}}"
    (ngSubmit)="deletePartitionSubmit()" enctype="multipart/form-data">

    <div class="modal-body px-0">
      <div class="card border-0 p-0">
        <div class="card-header alert-danger bg-transparent"
          *ngIf="deletePartitionForm.errors != null" role="alert">
          <div class="text-danger">
            <h4 class="alert-heading">{{'form.status.error' | translate}}</h4>
          </div>
          <div class="p-2 d-block d-sm-flex align-items-center">
            <p>{{'pacman.managementPartition.delete.error.default' | translate}}</p>
          </div>

        </div>
        <div class="card-body"><p
            [innerHTML]="'pacman.managementPartition.delete.info' | translate"></p>
          <div class="form-group col-12 px-0">
            <label for="selectedPartition" id="labelSelectedPartition"
              [class]=" (deletePartitionForm.controls.selectedPartition.errors && deletePartitionForm.controls.selectedPartition.errors.selectedPartitionError) ? 'invalid-field' : '' ">
              <span>{{'pacman.managementPartition.delete.select.label' |
                translate}}</span>
              <div role="alert" class="invalid-feedback"
                id="errorSelectedPartition">
                <ng-container
                  *ngIf="deletePartitionForm.controls.selectedPartition.errors && deletePartitionForm.controls.selectedPartition.errors.selectedPartitionError">
                  <span
                    class="d-block"> ({{'pacman.managementPartition.delete.error.none'
                    | translate }} </span>
                </ng-container>
              </div>
            </label>
            <div class="col-12 px-0">
              <select id="selectedPartition" appSelectToDropdown
                class="form-select " formControlName="selectedPartition">
                <option value="default"
                  [selected]="deletePartitionForm.controls.selectedPartition.value == 'default' ? true : null">
                  {{'pacman.managementPartition.delete.select.default' |
                  translate}}
                </option>
                <option
                  *ngFor="let partition of myPartitions"
                  [value]="partition.id"
                  [selected]="deletePartitionForm.controls.selectedPartition.value == null ? true : null">{{partition.id
                  | partitionNamePipe}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal-footer justify-content-between border-0 d-xs-flex px-0">
      <a href="javascript:void(0)" class="text-underline btn btn-link p-0 ms-0"
        (click)="closeForms()">{{'pacman.managementPartition.delete.cancel'
        |translate }}</a>
      <button class="btn btn-primary" type="submit"
        [disabled]="deletePartitionForm.controls.selectedPartition.value == 'default' ? true : null">{{'pacman.managementPartition.delete.button'
        | translate }} </button>

    </div>
  </form>

</div>
