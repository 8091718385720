import { Directive, Input, OnInit, ElementRef, DoCheck, Renderer2, ViewContainerRef } from '@angular/core';

import { Image } from '../interfaces/image';

@Directive({
  selector: '[appLogoFormat]'
})
export class LogoFormatDirective implements OnInit, DoCheck {
  // tslint:disable-next-line:no-input-rename
  @Input('appLogoFormat')
  appLogoFormat: { 'images': Image[], 'format': string };


  constructor(private el: ElementRef, private render: Renderer2, private viewContainer: ViewContainerRef) { }

  ngOnInit(): void {
    const contentUrl: string = this.getLogoBySpecificFormat(this.appLogoFormat.images, this.appLogoFormat.format);

    if (contentUrl === null) {
      this.viewContainer.clear();
      this.render.addClass(this.el.nativeElement, 'hidden');
    } else {
      this.render.removeClass(this.el.nativeElement, 'hidden');
      this.el.nativeElement.src = contentUrl;
    }


  }

  ngDoCheck() {
    const contentUrl: string = this.getLogoBySpecificFormat(this.appLogoFormat.images, this.appLogoFormat.format);

    if (contentUrl === null) {
      this.viewContainer.clear();
      this.render.addClass(this.el.nativeElement, 'hidden');
    } else {
      this.render.removeClass(this.el.nativeElement, 'hidden');
      this.el.nativeElement.src = contentUrl;
    }

  }

  getLogoBySpecificFormat(images: Image[], format: string): string {
    if (images === null) {
      return null;
    }
    for (let index = 0; index < images.length; index++) {
      if (images[index].format === format) {
        return images[index].contentHref;
      }

    }
    return null;
  }



}

