import { Component, Input, OnInit } from '@angular/core';
import {  MessageData, StatusMessageActionData } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';
import { MessageActionType } from '../../../interfaces/enum/messageActionType';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styles: []
})
export class MessageComponent implements OnInit {


  @Input()
  folder : string;

  messageWithActionRequired: StatusMessageActionData;

  private _message:MessageData;

	public get message(): MessageData {
		return this._message;
	}

  @Input()
	public set message(value: MessageData) {
    this._message = value;
    if (this._message.statusMessageActions != undefined) {
      for (const messageAction of this._message.statusMessageActions) {
        if (messageAction.messageActionId === MessageActionType.MESSAGE_WITH_ACTION_REQUIRED) {
          this.messageWithActionRequired = messageAction;
        }
      }
    }
	}

  constructor() { }

  ngOnInit() {

  }

  public  numbersOfMainContent(){
    let teller = 0;

	  for (const attach of this.message.attachments ){
	    if (attach.isPrincipalContent == true){
	      teller++;
      }
    }

	  return teller;
  }

}
