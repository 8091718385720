import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ErrorService } from '../../error.service';
import { HandleExceptionService } from '../../error/handle-exception-service.service';
import { ActionsStore } from '../../store/actions-store';
import { StateFn } from '../../store/StateFn';
import { StoreService } from '../../store/store.service';
import { ApplicationConstants } from '../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class PacmanHandleExceptionService extends HandleExceptionService{

  private _logger :NGXLogger;

  constructor(logger: NGXLogger,_router: Router, _actionsStore: ActionsStore, storeService: StoreService, stateFn: StateFn
    , _errorService: ErrorService , _appConstant: ApplicationConstants){
      super(logger,_router,_actionsStore,storeService,stateFn,_errorService, null, _appConstant);
      this._logger = logger;
   }

   handlerError(error:any){
    if (error instanceof HttpErrorResponse) {
      if(error.status ==403) {
        this._logger.info("No access to partition");
      }else{
        super.handlerError(error)        
      }
     }else{
     super.handlerError(error);
   }
  }
}
