import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ActionsService, LoadingService, SetPositionFixedService } from '../../services';
import { ActionsStore } from '../../store/actions-store';
import { StateFn } from '../../store/StateFn';
import { Folder } from '../../store/store-data-interface';
import { Partition } from '../../pacman/interfaces/partition';
import { ApplicationConstants } from '../../app.constants';

@Component({
  selector: 'app-leftnav',
  templateUrl: './leftnav.component.html',
  styles: []
})
export class LeftnavComponent implements OnInit {

  @ViewChild('fixedLeftnav') fixedLeftnav: ElementRef;

  private _errorLogin: boolean;

  @Input()
  set errorLogin(errorLogin: boolean) {
    this._errorLogin = errorLogin;
  }

  get errorLogin(): boolean {
    return this._errorLogin;
  }

  @Input()
  dropdownReference: NgbDropdown;
  @Input()
  isConsultMessage: boolean;
  hasScopeManualPublication: boolean;
  public isOptinPage: boolean;
  public isManualpublicationPage: boolean;
  public filterPartitionList: Partition[];
  public currentSelectedPartition: Partition;
  @Input()
  public isMobile: boolean;
  public nbOfMessageWithActionRequired: number;
  public filteredOnRequiredAction: boolean;

  private _currentFolder: string;

  public get currentFolder(): string {
    return this._currentFolder;
  }

  public set currentFolder(value: string) {
    this._currentFolder = value;
  }

  constructor(private _router: Router, private stateFn: StateFn,
    private _actionsService: ActionsService, private _actionsStore: ActionsStore,
    private positionFixedService: SetPositionFixedService, private _loadingService: LoadingService,
    public appConstant: ApplicationConstants) {
    this._currentFolder = this.stateFn.convertFolderToStr(this.stateFn.getCurrentFolder());
    this.hasScopeManualPublication = false;
    this.filterPartitionList = [];
    this.nbOfMessageWithActionRequired = 0;
  }

  ngOnInit() {
    this._loadingService.loadingEvent.subscribe(bool => {
      if (!bool) {
        if (this.fixedLeftnav != undefined && this.isMobile) {
          this.positionFixedService.addElement(
            { idPanelToFixed: 'fixedLeftnav', elemntRef: this.fixedLeftnav, positionInit: this.fixedLeftnav.nativeElement.getBoundingClientRect().top }
          );
        }
      }
    });

    this.stateFn.register(state => {
      this._currentFolder = this.stateFn.convertFolderToStr(this.stateFn.getCurrentFolder());
      this.hasScopeManualPublication = this.stateFn.isAccessManualPublication();
      this.isManualpublicationPage = this.stateFn.isCurrentPage('manualPublishMessage');
      this.isOptinPage = this.stateFn.isCurrentPage('management');
      this.filterPartitionList = this.stateFn.getMyPartitionsWithAccess();
      if (this.filterPartitionList != null && this.currentSelectedPartition == undefined && (!this.appConstant.isNullOrUndefined(state.messageFilters.senderPartition) || !this.appConstant.isNullOrUndefined(state.messageFilters.recipientPartition))) {
        this.currentSelectedPartition = this.filterPartitionList.find(part => part.id == (state.messageFilters.senderPartition || state.messageFilters.recipientPartition));
      } else if (this.appConstant.isNullOrUndefined(state.messageFilters.senderPartition) && this.appConstant.isNullOrUndefined(state.messageFilters.recipientPartition)) {
        this.currentSelectedPartition = undefined;
      }
      this.filteredOnRequiredAction = this.stateFn.getMessageFilters().includeRequiredAction;
      if (state.searchState.searchResults !== null) {
        this.nbOfMessageWithActionRequired = state.searchState.searchResults.totalItemsMessageAction;
      }
    });
  }


  switchFolder(folder: string) {
    const newFolder: Folder = this.stateFn.convertStrToFolder(folder);
    this._actionsService.switchFolder(newFolder);
    if (this.dropdownReference != null && this.dropdownReference != undefined) {
      this.dropdownReference.close();
    }


  }

  public goToManualForms() {
    if (this.dropdownReference != null && this.dropdownReference != undefined) {
      this.dropdownReference.close();
    }
    this._actionsStore.clearStatusForms();
    this._router.navigate(['/manualPublishMessage']);

  }

  filterOnPartition(event: Event, partitionName: string) {
    this._loadingService.start({ serviceRunStart: "filterOnPartition", delayedStart: false });
    this.currentSelectedPartition = undefined;
    this._actionsService.filterOnPartition(partitionName);
    event.stopPropagation();
  }

  filterOnMessageWithRequiredAction(event: Event) {
    this._actionsService.filterOnMessageWithRequiredAction();
    event.stopPropagation();
  }

  get isAccessOnlyManualPublication(){
    return this.stateFn.isAccessOnlyManualPublication();
  }

}
