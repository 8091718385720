import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortProperties'
})
export class SortPropertiesPipe implements PipeTransform {

  transform(value: any, args?: any): string {

    args = (args.substring(0, 1) === '-') ? args.substring(1) : args;
    return ((value === 'desc') ? '-' : '') + args;
  }

}
