import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfigurationService } from '@smals/ngx-configuration-service';
import { HandleExceptionService } from '../error/handle-exception-service.service';
import { NGXLogger } from "ngx-logger";


@Injectable()
export class LoadingService implements OnDestroy {
  loadingEvent: Subject<boolean> = new Subject<boolean>();
  firstLoading: boolean;
  loadingInProgress: boolean;
  loadingPeriod: number;
  timeoutActive: boolean;
  allReadyStarted:boolean;

  private _loadingPercentProgress: number;
  private clockStartLoading: Date;
  private timeOutConnection: number;
  private interval: any;


  constructor(private logger: NGXLogger,private _configService: ConfigurationService, private _exceptionService: HandleExceptionService) {
    this.firstLoading = false; this.loadingInProgress = false;
    this.loadingPeriod = this._configService.getEnvironmentVariable('loadingPeriod');
    this.timeOutConnection = this._configService.getEnvironmentVariable('timeoutConnection');
    this.loadingPercentProgress = 100;
    this.timeoutActive = true;
  }

  private nextTrue() {

    this.clockStartLoading = new Date();
    this.loadingEvent.next(true);
    this.logger.debug('check Interval : %s, timeOutIsActive %s', (!this.interval), this.timeoutActive);
   // console.info('check Interval : %s, timeOutIsActive %s', (!this.interval), this.timeoutActive);
    if (!this.interval && this.timeoutActive) {

      this.interval = setInterval(() => {
        if (new Date().getTime() - this.clockStartLoading.getTime() > this.timeOutConnection) {
          this.loadingEvent.next(false);
          clearInterval(this.interval);
          this.stop("Timeout Stop");
          this._exceptionService.loadingTimeout();
          
          this.logger.warn('timeelapsed ', new Date().getTime() - this.clockStartLoading.getTime());

        }
      }, 100);
    }

  }
  start(config:LoadingServiceOptions) {
    this.logger.debug("Start Loading %s", config.serviceRunStart);
   // logger.warn("serviceRunStart %s", serviceRunStart);
    if (this.allReadyStarted) return;
    this.allReadyStarted = true;
    if (config.timeoutActive !== undefined) this.timeoutActive = config.timeoutActive;
    this.loadingInProgress = true;
    if (config.delayedStart === undefined || config.delayedStart) {
      setTimeout(() => {
        if (this.loadingInProgress) {
          this.nextTrue();
        }
      }, this.loadingPeriod);
    } else {
      this.nextTrue();
    }
  }

  firstStart(config:LoadingServiceOptions) {
    this.firstLoading = true;
    this.start(config);
  }

  stop(stoppedBy:String) {
    this.logger.debug("stop loading %s", stoppedBy);
   // logger.warn("stop loading %s", stoppedBy);
    this.firstLoading = false;
    this.loadingInProgress = false;
    this.loadingEvent.next(false);
    clearInterval(this.interval);
    this.interval = undefined;
    this.timeoutActive = false;
    this.allReadyStarted = false;
  }


  public get loadingPercentProgress(): number {
    return this._loadingPercentProgress;
  }


  public set loadingPercentProgress(value: number) {
    // this.logger.info('value: ', value);
    this._loadingPercentProgress = value;
  }

  ngOnDestroy(){
    this.stop("Destroy");
  }

}

export class LoadingServiceOptions{
  serviceRunStart:string;
  delayedStart?: boolean;
  timeoutActive?: boolean;
}