<div class="container policyContainer" *ngIf="policy != null">
    <div
            class="{{isMobile ? 'd-block mt-1': 'd-flex align-items-center'}} border-bottom">
        <h2 class="ps-0 pt-0 pb-0  m-0 ">{{'pacman.policyAcceptation.title' | translate}}</h2>
    </div>
    <div class="p-2">
        <p>{{'pacman.policyAcceptation.intro' | translate}}</p>
        <ol>
            <li>
                {{'pacman.policyAcceptation.one' | translate}}
                <div  class="bg-white p-2 border border-1 border-black" [innerHTML]="policy.summary | translatedString"></div>
            </li>
            <li>
                {{'pacman.policyAcceptation.two' | translate}}
                <div class="fixedSizeBox bg-white p-2 border border-1 border-black" [innerHTML]="policy.fullText | translatedString"  (scroll)="scrollEnds($event)">
                </div>
            </li>
            <li>{{'pacman.policyAcceptation.three' | translate}}</li>
        </ol>
    </div>


    <form>
        <div class="form-check pt-3">
            <input class="form-check-input" type="checkbox" [disabled]="!acceptCheckboxEnabled" (change)="acceptBtnEnabled = !acceptBtnEnabled" id="acceptPolicy" />
            <label class="form-check-label fw-bolder mx-2" for="acceptPolicy">{{ 'pacman.policyAcceptation.checkbox' | translate}}</label>
        </div>
        <div class="d-flex align-items-center justify-content-center p-2">
            <button type="submit" [disabled]="!acceptBtnEnabled" class="btn btn-primary" (click)="accept()">{{'pacman.policyAcceptation.accept' | translate}}</button>
        </div>
    </form>
</div>
