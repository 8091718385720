import { Injectable } from '@angular/core';
import { ApplicationConstants } from '../app.constants';
import { HttpMethod, WSHelper } from '../helper/WSHelper';
import { EnterprisePreferences } from '../interfaces/enterprise-preferences';
import { NGXLogger } from "ngx-logger";
import { ActionsStore } from '../store/actions-store';
import { UserPartition } from '../pacman/interfaces/userPartition';
import { UserType } from '../pacman/interfaces/enum/userType';
import { ActionsService } from "../store/actions.service";
import { HttpErrorResponse } from '@angular/common/http';
import { ConfigurationService } from "@smals/ngx-configuration-service";
import { Policy } from "../pacman/interfaces/policy";
import { StateFn } from "../store/StateFn";
import { EmailsToUpdate } from '../interfaces/emailsToUpdate';

@Injectable()
export class EnterprisePreferencesService {
  

  private baseUrl: string;

  constructor(private logger: NGXLogger, public appConstant: ApplicationConstants, private ws: WSHelper,
    private _actionsStore: ActionsStore, private stateFn: StateFn, private actionService: ActionsService, private configService: ConfigurationService) {
    this.baseUrl = this.configService.getEnvironmentVariable("urlWebAppBackend");
  }

  

  public getEnterprisePreferences(): Promise<boolean> {
    const url = this.baseUrl + "/enterpriseProfiles/" + this.getCbeNumber();
    return this.ws.callWs<EnterprisePreferences>(url, HttpMethod.GET).then(e => {
      this.actionService.updateEnterprisePreferences(e)
      return Promise.resolve(true);
    }).catch(error => {
      let message: string = error;
      if (error instanceof HttpErrorResponse) {
        message = error.error;
      }
      this._actionsStore.checkServicePreferences(false);
      this.logger.error('Error getEnterprisePreferences()', JSON.stringify(message));
      return Promise.reject('Error getEnterprisePreferences()' + JSON.stringify(message));
    });
  }

  private getCbeNumber(): string {
    const cbeNumber = this.stateFn.getUser().cbeNumber;
    return cbeNumber.length < 10 ? '0' + cbeNumber : cbeNumber;
  }

  public acceptOptin() {
    const url = this.baseUrl + "/enterpriseProfiles/" + this.getCbeNumber();
    const promise = this.ws.callWs<void>(url + '/activateOptin', HttpMethod.POST);
    promise.then(a => {
      this.actionService.updateOptin();
    }).catch(error => {
      // TODO PROPER ERROR HANDLING
      this.logger.error('Error post', error);
    });
  }

  public acceptPartitionPolicy(): Promise<void> {
    const url =  this.baseUrl + "/enterpriseProfiles/" + this.getCbeNumber() + "/policies/partition";
    const promise = this.ws.callWs<void>(url, HttpMethod.POST);
    return promise.then(a => {
      this.actionService.updatePartitionPolicy()
      return Promise.resolve();
    }).catch(error => {
      this.logger.error('Error post', error);
    });
  }

  public updateEnterprisePreferences(email: EmailsToUpdate): Promise<void> {
    const url = this.baseUrl + "/enterpriseProfiles/" + this.getCbeNumber();
    
    const promise = this.ws.callWs<void>(url, HttpMethod.PATCH, {}, { "enterpriseEmail": email });

    return promise;
  }

  public updateEnterpriseEmails(emails: EmailsToUpdate): Promise<void> {
    const url = this.baseUrl + "/enterpriseProfiles/" + this.getCbeNumber() + "/updateEmails";

    const promise = this.ws.callWs<void>(url, HttpMethod.POST, {}, emails);

    return promise;
  }

  public getUsersInfoForEnterprise(partitionId: string): Promise<Map<string, UserPartition>> {
    const url = this.baseUrl + "/enterprises/" + this.getCbeNumber() + "/users";
    return this.ws.callWs(url, HttpMethod.GET, { "partitionName": partitionId }
    ).then(personsWs => {
      const persons: { niss: string, firstName: string, lastName: string }[]  = (personsWs as { items: { ssin: string, familyName: string, givenName: string }[] })?.items.map((person: { ssin: string, familyName: string, givenName: string }) => {
          return { niss: person.ssin, firstName: person.givenName, lastName: person.familyName }
        });

      
      const results: Map<string, UserPartition> = new Map(
        persons.map((person: { niss: string, firstName: string, lastName: string }) => [person.niss, { id: person.niss, type: UserType.HUMAN, userValue: { firstName: person.firstName, lastName: person.lastName } }]
        )
      );
      return results;
    }).catch(error => {
      this._actionsStore.checkServicePreferences(false);
      this.logger.error('Error getEnterprisePreferences()', error);
      return new Map();
    });
  }

  public getPartitionPolicy(): Promise<Policy> {
    const url =  this.baseUrl + "/policies/partition";
    return this.ws.callWs<Policy>(url, HttpMethod.GET, {});
  }
  public getDocConsumerPolicy(): Promise<Policy> {
    const url = this.baseUrl + "/policies/documentconsumer";

    return this.ws.callWs<Policy>(url, HttpMethod.GET, {});
  }

  public acceptDocConsumerPolicy(): Promise<boolean> {
    const url =  this.baseUrl + "/enterpriseProfiles/" + this.getCbeNumber() + "/policies/documentconsumer";
    const promise = this.ws.callWs<void>(url, HttpMethod.POST);
    return promise.then(a => {
      this.actionService.updateDocConsumerPolicy();
      return Promise.resolve(true);
    }).catch(error => {
      this.logger.error('Error post', error);
      return Promise.resolve(false);
    });
  }

  
}
