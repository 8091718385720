import { inject, NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { FormsBidirectionalComponent, ManagementComponent, ManualPublicationComponent } from './components';
import { BlankComponent } from './components/oauthCallback/blank.component';
import { MockLoginComponent } from './auth/mock-login.component';
import { ErrorPageComponent } from './error/error-page.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MessagesComponent } from './components/messages/messages.component';
import { DetailsComponent } from "./components/detail/details.component";
import { ManagePartitionUsersComponent } from "./pacman/managePartitionUsers/manage-partition-users.component";
import { ManagementPartitionsComponent } from "./pacman/managePartitions/management-partitions.component";
import { authGuard } from './auth/auth-guard';
import { PartitionPolicyComponent } from "./components/management/partition-policy/partition-policy.component";
import { StateFn } from "./store/StateFn";
import { DocconsumerPolicyComponent } from "./components/management/docconsumer-policy/docconsumer-policy.component";


const policyAcceptedGuard = () => {
  const router = inject(Router);
  const stateFn = inject(StateFn);
  if (!stateFn.getUser().hasAcceptedPartitionSelfServicePolicy) {
    router.navigateByUrl("/management")
  }
  return true;
}

const routes: Routes = [
  { path: 'direct__', component: MessagesComponent, canActivate: [authGuard] },
  { path: 'oauthCallback', component: BlankComponent, canActivate: [authGuard] },
  { path: 'detail', component: DetailsComponent, canActivate: [authGuard] },
  { path: 'manualPublishMessage', component: ManualPublicationComponent, canActivate: [authGuard], runGuardsAndResolvers: 'always', },
  { path: 'replyToMessage', component: FormsBidirectionalComponent, canActivate: [authGuard] },
  { path: 'reset', redirectTo: '/messages', pathMatch: 'full' },
  { path: 'messages', component: MessagesComponent, canActivate: [authGuard] },
  {
    path: 'management', canActivate: [authGuard],
    children: [
      {
        path: '',
        component: ManagementComponent
      },
      {
        path: 'partitions',
        canActivate: [policyAcceptedGuard],
        children: [
          {
            path: ''
            , component: ManagementPartitionsComponent
          },
          { path: 'partitionUsers', component: ManagePartitionUsersComponent, runGuardsAndResolvers: 'always' }
        ]
      },
      {
        path: 'acceptPartitionPolicy',
        component: PartitionPolicyComponent
      },
      {
        path: 'docConsumerPolicy',
        component: DocconsumerPolicyComponent
      },
    ]
  },


  { path: '', redirectTo: '/messages', pathMatch: 'full' },
  { path: 'info', component: MockLoginComponent },
  { path: 'login', component: BlankComponent, canActivate: [authGuard] },
  { path: 'error', component: ErrorPageComponent },
  {
    path: '**', component: NotFoundComponent
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      enableTracing: false
      , onSameUrlNavigation: "reload", anchorScrolling: "enabled", scrollPositionRestoration: "enabled"
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }