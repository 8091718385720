<ul *ngIf="allMessages.length > 0" class="list-unstyled cards"
    data-testid="allMessages">
    <li *ngFor="let message of allMessages; let i = index"
        [class]="(( folder=='out' || message.statusMessageData.readStatus) ? 'read' : 'unread') + ' me-0 ms-0 row card flex-row rounded-0 border-bottom border-end-0 border-start-0 border-top-0'">
        <div class="checkbox ps-0 card-header col-auto d-none d-sm-block">
            <input type="checkbox" #checkboxOperation
                value="mid_{{message.messageId}}-rid_{{message.registryId}}"
                (click)="selectMessages($event.target || $event.srcElement , 'one')"
                id="mid_{{message.messageId}}-rid_{{message.registryId}}" />
            <label for="mid_{{message.messageId}}-rid_{{message.registryId}}"
                class="visually-hidden">{{'messages.actionsMessage.selected.one'
                | translate }}</label>
        </div>
        <a href="javascript:void(0)" class="col pe-0"
            (click)="getMessage(message)">
            <app-message [message]="message" [folder]="folder"></app-message>
        </a>
    </li>
</ul>

<p *ngIf="loadingMessage && allMessages.length == 0"
    [innerHTML]="'search.paginator.resultFound.none' | translate"
    class="font-weight-bold text-center mt-4">

</p>
<div class="paginationNav bottom float-end">
    <app-pagination
        *ngIf='allMessages.length !== 0 && this.numberOfSearchResult > pageSize'></app-pagination>
</div>