import { Pipe, PipeTransform } from '@angular/core';
import { TranslatedStringPipe } from './translated-string.pipe';
import { NGXLogger } from "ngx-logger";
import { SenderApplicationWithRegistryConfig } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';
import { StateFn } from '../store/StateFn';
interface ServiceParam { 'id': string, 'dataType': string, 'senderApplication': SenderApplicationWithRegistryConfig, 'registryId': string }

@Pipe({
  name: 'senderApplication',
  pure: false
})
export class SenderApplicationPipe implements PipeTransform {
  constructor(private logger: NGXLogger, private translatedPipe: TranslatedStringPipe, private stateFn: StateFn) {
  }

  transform(value: SenderApplicationWithRegistryConfig, args?: { 'id': string, 'dataType': string, 'registryId': string }): any {
    const serviceParam: ServiceParam = { id: null, dataType: null, senderApplication: null, registryId: null };
    serviceParam.id = args.id;
    serviceParam.registryId = args.registryId;
    serviceParam.dataType = args.dataType;
    serviceParam.senderApplication = value;
    const result = this.getSenderApplication(serviceParam);
    if (result === null) {
      this.logger.info('senderApplication %s no valid for id= %s', JSON.stringify(value), serviceParam.id)
      return "";
    }
    return result
  }

  public getSenderApplication(serviceParam: ServiceParam) {
    if (serviceParam.senderApplication == null) {
      try {
        let appFounded = null;
        const isReferenceDataLoaded = this.stateFn.getAllReferencesData() != null;
        if (isReferenceDataLoaded) {
          appFounded = this.stateFn.getAllReferencesData().senderApplicationsState.find(app => (app.id == serviceParam.id && app.registryConfig.configRegistry.id == serviceParam.registryId));
        }
        if (appFounded == null) throw Error("senderApplication notFounded id: " + serviceParam.id + ", registryId: " + serviceParam.registryId);
        serviceParam.senderApplication = appFounded;
      } catch (error) {
        this.logger.error(error);
        return null;
      }
    }

    try {
      switch (serviceParam['dataType']) {
        case 'logo':
          return serviceParam.senderApplication.logo[this.stateFn.getLanguage()];
        default: return (serviceParam.senderApplication.name == undefined ? serviceParam.senderApplication.id :  this.translatedPipe.transform(serviceParam.senderApplication.name, this.stateFn.getLanguage()));
      }
    } catch (error) {
      this.logger.error("error %s , senderApplication %s", error, serviceParam.senderApplication);
      return null;
    }
  }
}


