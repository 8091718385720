import { TranslationWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
    getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
        return this._translateService.instant('datepicker.weekdays')[weekday - 1];
    }

    constructor(private _translateService: TranslateService) {
        super();
    }

    getWeekdayShortName(weekday: number): string {

        return this._translateService.instant('datepicker.weekdays')[weekday - 1];
    }
    getMonthShortName(month: number): string {
        return this._translateService.instant('datepicker.monthShortName')[month - 1];
    }
    getMonthFullName(month: number): string {
        return this._translateService.instant('datepicker.monthFullName')[month - 1];

    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}/${date.month}/${date.year}`;
    }
}
