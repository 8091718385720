

<form class="w-100" name="mailForm" (ngSubmit)="!editMode ? null : f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <ng-container *ngIf="emailEnterpriseModel != null && emailMandataryModel != null ">
        <ul class="{{isOptionFlow ? 'px-0' : ''}}">
            <li><ng-container *ngTemplateOutlet="blockEnterprise"></ng-container></li>
            <li><ng-container *ngTemplateOutlet="blockMandatary"></ng-container></li>
        </ul>
    </ng-container>

    <ng-container *ngIf="!(emailEnterpriseModel != null && emailMandataryModel != null) ">
        <ng-container *ngIf="emailEnterpriseModel != null">

            <ng-container *ngTemplateOutlet="blockEnterprise"></ng-container>
        </ng-container>
        <ng-container *ngIf="emailMandataryModel != null">
            <ng-container *ngTemplateOutlet="blockMandatary"></ng-container>
        </ng-container>
        <p *ngIf="isOptionFlow && (isEnterpriseAdmin || isMandataryAdmin)">{{'optinFlow.email.description.admin' | translate}}</p>

    </ng-container>

    <ng-template #blockEnterprise>
        <div *ngIf="(isEnterprise || isAccessAdmin)" class="emailConfirmedBlock {{isOptionFlow ||  emailTypeEdit!='enterprise' ? 'mb-4':''}} mt-3">

            <p class="mb-1" [innerHTML]="'optinFlow.email.description.enterprise' | translate"></p>
            <ng-container *ngIf="!editMode || (editMode && emailTypeEdit!='enterprise')">
                <div class="alert alert-warning bg-yellow p-2 mb-2"
                    *ngIf="isMandataryAdmin && !isEnterpriseAdmin && !emailEnterpriseConfirmed">
                    {{'optinFlow.email.notConfirmed' | translate:{emailType: 'emailType.enterprise'|translate} }}

                </div>
                <p
                    *ngIf="emailEnterpriseModel != null && (isEnterprise || isMandataryAdmin) && (!editMode || (editMode && emailTypeEdit!='enterprise' && !isOptionFlow) || (isOptionFlow && (emailEnterpriseConfirmed || isMandataryAdmin && !isEnterpriseAdmin && !emailEnterpriseConfirmed)))"
                    class="mb-0">
                    <span><strong>{{'optinFlow.email.input' | translate:{emailType: 'emailType.enterprise' | translate} }}: </strong></span>{{ (appConstant.isNullOrUndefined(emailEnterpriseModel) || emailEnterpriseModel=='')? ('optinFlow.email.empty'
                    |
                    translate) : emailEnterpriseModel }}
                    <span *ngIf="isMandataryAdmin && !isRae  && (!emailEnterpriseConfirmed || !isOptionFlow)" class="form-text text-danger">{{'optinFlow.email.connected'
                        |
                        translate}}</span>
                    <span *ngIf="isOptionFlow && emailEnterpriseConfirmed" class="form-text text-danger">{{'optinFlow.email.alReadyConfirmed' |
                        translate}}</span>

                    <a class="ps-1" href="javascript:void(0)" (click)="onEditMode('enterprise')" [title]="'management.optin.edit' | translate" *ngIf="isEnterpriseAdmin && !isOptionFlow">
                        <span class="visually-hidden">{{'management.optin.edit' | translate}}</span>
                        <i [class]="'fas fa-pencil-alt'" aria-hidden="true"></i>
                    </a>
                </p>
            </ng-container>
        </div>
        <div
            class="form-group mb-4 me-3 col-10 {{isOptionFlow ? '' : 'col-sm-8'}} ps-0 pe-0"
            *ngIf="editMode && emailEnterpriseModel != null  && isEnterpriseAdmin && (emailTypeEdit=='enterprise' || (!emailEnterpriseConfirmed && isOptionFlow))">
            <div class="row justify-content-between">
                <label for="emailEnterprise" class="justify-content-start col-auto px-3 fw-bold">
                    {{'optinFlow.email.input' | translate:{emailType: 'emailType.enterprise' | translate} }}:
                </label>
                <div class="{{isOptionFlow ? 'col-7' : 'col-8'}}">
                    <input id="emailEnterprise" type="text" class="form-control"
                        [(ngModel)]="emailEnterpriseModel" name="emailEnterprise" #emailEnterprise="ngModel"
                        [ngClass]="{ 'is-invalid': !f.pristine && emailEnterprise.invalid }" required pattern="{{emailPattern}}">
                    <div *ngIf="!f.pristine && emailEnterprise.invalid" class="invalid-feedback"
                        role="alert">
                        <span *ngIf="emailEnterprise.errors.required">{{'management.optin.email.required'
                            | translate}}</span>
                        <span *ngIf="emailEnterprise.errors.pattern">{{'management.optin.email.invalid'
                            | translate}}</span>
                    </div></div>
            </div>

        </div>
        <ng-container *ngIf="isOptionFlow">
            <p *ngIf="(emailEnterpriseConfirmed == null || emailMandataryConfirmed == null) && !isMandataryAdmin && !isEnterpriseAdmin">{{'optinFlow.email.description.user' | translate}}</p>
        </ng-container>
        <div class="button groups" *ngIf="editMode && emailTypeEdit=='enterprise'">
            <ng-container *ngIf="!f.invalid"></ng-container>
            <button class="btn btn-primary mb-2"
                [disabled]="f.pristine || f.invalid" type="submit"
                title="{{'management.optin.confirm' | translate}}">{{'management.optin.confirm'
                | translate}}</button>
            <button class="btn btn-link mb-2" type="button" (click)="onCancel()"
                title="{{'management.optin.cancel' | translate}}">{{'management.optin.cancel'
                | translate}}</button>

        </div>
    </ng-template>
    <ng-template #blockMandatary>
        <div *ngIf="(isMandatary || isAccessAdmin)" class="emailConfirmedBlock mb-3">

            <p class="mb-1" [innerHTML]="'optinFlow.email.description.mandatary' | translate"></p>
            <ng-container *ngIf="!editMode || (editMode && emailTypeEdit!='mandatary')">
                <div class="alert alert-warning bg-yellow p-2 mb-2" *ngIf="isEnterpriseAdmin && !isMandataryAdmin && !emailMandataryConfirmed">
                    <p>{{'optinFlow.email.notConfirmed' | translate:{emailType: 'emailType.mandatary'|translate} }}</p>

                </div>
                
                <p
                    *ngIf="emailMandataryModel != null && (isMandatary || isEnterpriseAdmin) && (!editMode || (editMode && emailTypeEdit!='mandatary' && !isOptionFlow) || (isOptionFlow && (emailMandataryConfirmed || isEnterpriseAdmin && !isMandataryAdmin && !emailMandataryConfirmed)))"
                    class="mb-0">

                    <span><strong>{{'optinFlow.email.input' | translate:{emailType: 'emailType.mandatary' | translate} }}: </strong></span>{{ (appConstant.isNullOrUndefined(emailMandataryModel) || emailMandataryModel=='') ? ('optinFlow.email.empty' |
                    translate)
                    : emailMandataryModel }}
                    <span *ngIf="isEnterpriseAdmin && !isRae  && (!emailMandataryConfirmed || !isOptionFlow)" class="form-text text-danger">{{'optinFlow.email.connected'
                        |
                        translate}}</span>
                    <span *ngIf="isOptionFlow && emailMandataryConfirmed" class="form-text text-danger">{{'optinFlow.email.alReadyConfirmed' |
                        translate}}</span>

                    <a class="ps-1" href="javascript:void(0)" (click)="onEditMode('mandatary')"
                        [title]="'management.optin.edit' | translate" *ngIf="isMandataryAdmin && !isOptionFlow">
                        <span class="visually-hidden">{{'management.optin.edit' | translate}}</span>
                        <i [class]="'fas fa-pencil-alt'"
                            aria-hidden="true"></i>
                    </a>
                </p>
            </ng-container>
        </div>
        <div
            class="form-group mb-2 me-3 col-10 {{isOptionFlow ? '' : 'col-sm-8'}} ps-0 pe-0"
            *ngIf="editMode && emailMandataryModel != null && isMandataryAdmin && (emailTypeEdit=='mandatary' ||  (!emailMandataryConfirmed && isOptionFlow))">
            <div class="row justify-content-between">
                <label class="justify-content-start px-3 fw-bold  col-auto" for="emailMandatary">
                    {{'optinFlow.email.input' | translate:{emailType: 'emailType.mandatary' | translate} }}:
                </label>
                <div class="{{isOptionFlow ? 'col-7' : 'col-8'}}">
                    <input id="emailMandatary" type="text" class="form-control"
                        [(ngModel)]="emailMandataryModel" name="emailMandatary" #emailMandatary="ngModel"
                        [ngClass]="{ 'is-invalid': !f.pristine && emailMandatary.invalid }" required
                        pattern="{{emailPattern}}">
                    <div *ngIf="!f.pristine && emailMandatary.invalid" class="invalid-feedback"
                        role="alert">
                        <span *ngIf="emailMandatary.errors.required">{{'management.optin.email.required'
                            | translate}}</span>
                        <span *ngIf="emailMandatary.errors.pattern">{{'management.optin.email.invalid'
                            | translate}}</span>
                    </div>
                </div>
            </div>

        </div>
        <ng-container *ngIf="isOptionFlow">
            <p *ngIf="(emailEnterpriseConfirmed == null || emailMandataryConfirmed == null) && !isMandataryAdmin && !isEnterpriseAdmin">{{'optinFlow.email.description.user' | translate}}</p>

        </ng-container>
        <div class="button groups" *ngIf="editMode && emailTypeEdit=='mandatary'">

            <button class="btn btn-primary mb-2"
                [disabled]="f.pristine || f.invalid" type="submit"
                title="{{'management.optin.confirm' | translate}}">{{'management.optin.confirm'
                | translate}}</button>
            <button class="btn btn-link mb-2" type="button" (click)="onCancel()"
                title="{{'management.optin.cancel' | translate}}">{{'management.optin.cancel'
                | translate}}</button>

        </div>
    </ng-template>
</form>

<p *ngIf="emailEnterpriseConfirmed != null && emailMandataryConfirmed != null" class="mt-4">{{'optinFlow.email.description.2mails' | translate}}</p>



