import { Injectable } from '@angular/core';
import { DateHelper } from './DateHelper';

import { FormatData } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData/FormatData';

@Injectable()
export class ObjectHelper {

    constructor(_dateHelper: DateHelper) {

    }

    static isEmptyObject(object: {}): boolean {
        if (typeof (object) !== "object"){ 
            throw new Error('is not a object');
    }

        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                if (object[key] instanceof Object && !(object[key] instanceof Date)) {
                    if (!this.isEmptyObject(object[key])) return false;
                } else if (object[key] !== undefined) {
                    return false;
                }
            }

        }
        return true;

        // return (JSON.stringify(object)==='{}');
    }

    static objectEncode(object: {}, additionalKey?: string): string {

        const result: string[] = [];

        Object.keys(object).forEach(key => {
            try {
                if (!this.isEmptyObject(object[key])) {
                    result.push(this.objectEncode(object[key], key));
                } else {
                    if (additionalKey !== undefined) {
                        result.unshift(additionalKey + '-' + key + ":" + ((object[key] instanceof Date) ? FormatData.formateDateToWs(object[key]) : object[key]));

                    } else {
                        result.push(key + ":" + ((object[key] instanceof Date) ? FormatData.formateDateToWs(object[key]) : object[key]));
                    }

                }

            } catch (error) {
                if (object[key] !== undefined) {
                    result.push((additionalKey !== undefined ? additionalKey + '-' : '') + key + ":" + object[key]);
                }
            }
        })

        return result.join(',');
    }

    // TODO simplify the encoding
    static objectDecode<T>(segmentParams: string): T {
        const result: T = <T>{};
        const segmentParam: string[] = segmentParams.split(',');
        segmentParam.forEach(params => {
            const paramArr: string[] = params.split(':');
            // +1 for ':'
            const paramValue: any = params.substr(paramArr[0].length + 1);
            const subSegment: string[] = paramArr[0].split('-');
            if (subSegment.length > 1) {
                if (result[subSegment[0]] === undefined) result[subSegment[0]] = {};
                if (!isNaN(paramValue) && (paramValue.substring(0, 1) !== "0" || paramValue.length == 1)) {
                    result[subSegment[0]][subSegment[1]] = paramValue;
                } else  if (paramValue === 'true' || paramValue === 'false') {
                    result[subSegment[0]][subSegment[1]] = (paramValue === 'false') ? false : true;
                 }else{
                    const date = new Date(paramValue);
                    if (date instanceof Date && !isNaN(date.valueOf())) {
                        result[subSegment[0]][subSegment[1]] = new Date(paramValue);
                    } else {
                        result[subSegment[0]][subSegment[1]] = paramValue;
                    }
                }
            } else if (paramValue === 'true' || paramValue === 'false') {
                result[paramArr[0]] = (paramValue === 'false') ? false : true
            } else if (!isNaN(paramValue) && (paramValue.substring(0, 1) !== "0" || paramValue.length == 1)) {
                result[paramArr[0]] = Number(paramValue);
            } else {

                result[paramArr[0]] = paramValue.toString();

            }


        });

        return <T>result;
    }
}
