import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Partition } from '../../../pacman/interfaces/partition';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-move-partition',
  templateUrl: './move-partition.component.html'
})
export class MovePartitionComponent implements OnInit {


  @Input() isMobile: boolean;

  movePartitionForm: FormGroup;
  @Input() myPartitions: Partition[];
  @Input() nbMessageSelected: number;
  @ViewChild('selectPartition') selectPartitionRef: ElementRef;
  public hasAccessToPartition: boolean;

  private static readonly DEFAULT: string = "default"

  constructor(public activeModal: NgbActiveModal) {


  }

  ngOnInit(): void {
    this.movePartitionForm = new FormGroup({
      senderPartition: new FormControl<string>({ value: this.myPartitions.length == 1 ? this.myPartitions[0].id : MovePartitionComponent.DEFAULT, disabled: this.myPartitions.length == 0 }, { validators: [Validators.required] }),
    })
    if (MovePartitionComponent.DEFAULT == this.movePartitionForm.controls.senderPartition.value || (this.myPartitions.length == 1 && this.myPartitions[0].hasAccess)) {
      this.hasAccessToPartition = true;
    }
    this.movePartitionForm.controls.senderPartition.valueChanges.subscribe(element => {
      const select = this.selectPartitionRef.nativeElement as HTMLSelectElement;
      const option = select.options[select.selectedIndex]
      if (MovePartitionComponent.DEFAULT != element.value) {
        this.hasAccessToPartition = (/true/).test(option.getAttribute('data-hasaccess'));
      } else {
        this.hasAccessToPartition = null;
      }
    })

  }


  movePartitionSubmit() {
    if (MovePartitionComponent.DEFAULT == this.movePartitionForm.controls.senderPartition.value) {
      this.movePartitionForm.controls.senderPartition.setErrors({ senderPartitionError: true });
      this.movePartitionForm.setErrors({ senderPartitionError: true });

    } else {
      this.closeAndResetModal(this.movePartitionForm.controls.senderPartition.value);
    }

  }

  closeAndResetModal(partitionId?) {
    if (partitionId != undefined) {
      this.activeModal.close({ partitionId: partitionId });
    } else {
      this.activeModal.dismiss("close");
    }
  }

}


