import { Injectable } from "@angular/core";
import { StateFn } from "../../store/StateFn";
import { CacheStorageService } from "../cacheStorage.service";
import { SentryLocalStorage } from "./sentry-local-storage";
import { uniformizeCbeNumber } from "../../models/enterprise-user";

@Injectable({
	providedIn: 'root'
})
export class SentryManageStorage {

	private readonly KEY_STORAGE_SENTRY: string = 'SENTRY_FINGERPRINTS';


	private readonly HOURS_REMINDER: number = 12;


	constructor(private _stateFn: StateFn, private cacheStorageService: CacheStorageService) {
	}

	get keyUsedForStorage(): string {
		return this.KEY_STORAGE_SENTRY + '_' + uniformizeCbeNumber(this._stateFn.getUser().cbeNumber);
	}

	private getSentryFingerPrints(): SentryLocalStorage[] {

		const infoFromStorage = this.cacheStorageService.getStorage<SentryLocalStorage[]>(this.keyUsedForStorage);

		return infoFromStorage ?? [];
	}

	private findFingerPrint(fingerprintVal: string): SentryLocalStorage | undefined {
		const currentStorage: SentryLocalStorage[] = this.getSentryFingerPrints();
		if (currentStorage.length !== 0) {
			return currentStorage.find(storage =>  storage.fingerprint === fingerprintVal);
		}
		return undefined;

	}

	public addFingerPrintToStorage(fingerprintVal: string) {
		const storageInfo = this.findFingerPrint(fingerprintVal);
		const currentStorage: SentryLocalStorage[] = this.getSentryFingerPrints();
		if (storageInfo) {
			const dateFromStorage: Date = new Date(Date.parse(storageInfo.creationDate));
			const computeDateFromLastDateInStorage = new Date(dateFromStorage.setHours(dateFromStorage.getHours()+this.HOURS_REMINDER));

			if (storageInfo.fingerprint === fingerprintVal &&  new Date().getTime() > computeDateFromLastDateInStorage.getTime()) {
				currentStorage.find(storage => storage.fingerprint === fingerprintVal).creationDate = new Date().toLocaleString();
			}
		} else {
			currentStorage.push({ fingerprint: fingerprintVal, creationDate: new Date().toLocaleString() } as SentryLocalStorage);
		}

		this.cacheStorageService.setStorage(this.keyUsedForStorage, JSON.stringify(currentStorage));


	}

	public existAndNotExpiredInLocalStorage(fingerprintVal:string): boolean {
		const storage = this.findFingerPrint(fingerprintVal);
		if (storage) {
			return true;
		} else {
			return false;
		}
	}

}