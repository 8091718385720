<a class="btn btn-link text-body me-sm-1 ms-sm-1  ms-xxl-2"
    *ngIf="!errorLogin  && hasScopeManualPublication && (!isMobile  || dropdownReference !=undefined)"
    href="javascript:void(0)" (click)="goToManualForms()"
    title='{{"header.manualPublishMessage" | translate}}'
    data-testid="header.manualPublish"><i class="fas fa-envelope text-primary"></i><span>{{'header.manualPublishMessage'
        | translate}}</span></a>

<nav *ngIf="!isAccessOnlyManualPublication">
    <div *ngIf="isMobile && isConsultMessage" class="bg-white panelToFixed"
        #fixedLeftnav>
        <div
            class="navbar dropdown d-block {{filterPartitionList && filterPartitionList.length > 1 ? 'dropdownFolder' : ''}}">
            <a class="nav-link text-body dropdown-toggle pt-0 pe-2"
                href="javascript:void(0)" id="navbarFolderLink" role="button"
                data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false"
                [title]="'leftnav.'.concat(currentFolder).concat('.title') |  translate">
                <h2 class="mb-0"><span>{{'leftnav.'.concat(currentFolder).concat('.title')
                        | translate}}</span></h2>
            </a>
            <div class="dropdown-menu border-top-0 me-0"
                aria-labelledby="navbarFolderLink">
                <ul class="nav flex-column  list-unstyled mb-0">
                    <li
                        class="nav-item  {{currentFolder =='in' && !isOptinPage && !isManualpublicationPage ? 'active' : ''}} text-body">
                        <ng-container
                            *ngTemplateOutlet="generateLink;context : { $implicit : {'folder':'in','folderIsActive': currentFolder =='in' && !isOptinPage && !isManualpublicationPage} }"></ng-container>
                        <ul
                            *ngIf="currentFolder =='in' && !isOptinPage && !isManualpublicationPage && nbOfMessageWithActionRequired !=0"
                            class="subNav list-unstyled flex-column">
                            <li
                                class="nav-item text-body {{filteredOnRequiredAction ? '' : 'active'}}">
                                <a href="javascript:void(0)"
                                    (click)="switchFolder('in')"
                                    class="nav-link d-flex align-items-center justify-content-between"
                                    title="{{'leftnav.in.sub-title.messages' | translate}}">
                                    <span>{{'leftnav.in.sub-title.messages' |
                                        translate}}</span>
                                </a>
                            </li>
                            <li
                                class="nav-item {{filteredOnRequiredAction ? 'active' : ''}} text-body">
                                <a href="javascript:void(0)"
                                    (click)="filterOnMessageWithRequiredAction($event)"
                                    class="nav-link d-flex align-items-center justify-content-between"
                                    title="{{'leftnav.in.sub-title.actionRequired' | translate}}">
                                    <span>{{'leftnav.in.sub-title.actionRequired'
                                        | translate}}</span> <span
                                        class="badge badge-pill ">
                                        {{nbOfMessageWithActionRequired}}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li
                        class="nav-item  text-body {{currentFolder =='out' ?'active' :''}}">
                        <ng-container
                            *ngTemplateOutlet="generateLink;context : { $implicit : {'folder':'out','folderIsActive': currentFolder =='in' && !isOptinPage && !isManualpublicationPage} }"></ng-container>
                    </li>
                    <li
                        class="nav-item text-body {{currentFolder =='hide' ?'active' :''}}">
                        <ng-container
                            *ngTemplateOutlet="generateLink;context : { $implicit : {'folder':'hide','folderIsActive': currentFolder =='in' && !isOptinPage && !isManualpublicationPage} }"></ng-container>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="nav flex-column text-body"
        *ngIf="filterPartitionList && filterPartitionList.length > 1">
        <div ngbDropdown class="d-block dropdownPartition mb-2 pe-2 ps-2">
            <button type="button"
                class="btn border-light col text-body text-start w-100"
                id="dropdownBasic1" ngbDropdownToggle>
                <span class="d-block" class="position-relative">
                    {{ currentSelectedPartition != undefined ?
                    (currentSelectedPartition.id | partitionNamePipe) : ('leftnav.filterPartition.defaultLabel'
                    | translate) }}
                </span>
            </button>
            <ul class="list-unstyled" ngbDropdownMenu
                aria-labelledby="dropdownBasic1">
                <li *ngIf="currentSelectedPartition!=undefined"
                    class="position-relative pt-0 pb-0" ngbDropdownItem>
                    <a href="javascript:void(0)"
                        (click)="filterOnPartition($event)"
                        class="d-block text-body">
                        {{'leftnav.filterPartition.defaultLabel' | translate}}
                    </a>
                </li>
                <ng-container *ngFor="let part of filterPartitionList">
                    <li
                        *ngIf="currentSelectedPartition==undefined || (currentSelectedPartition.id != part.id)"
                        class="position-relative pt-0 pb-0" ngbDropdownItem>
                        <a href="javascript:void(0)"
                            (click)="filterOnPartition($event,part.id)"
                            class="d-block text-body">
                            {{part.id | partitionNamePipe}}
                        </a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>

    <ul class="nav flex-column text-body"
        *ngIf="!isMobile || dropdownReference !=undefined">
        <!--<li class="nav-item  {{currentFolder =='in' && !isOptinPage && !isManualpublicationPage ? 'active' : ''}} text-body">
            <ng-container *ngTemplateOutlet="generateLink;context : { $implicit : 'in' }"></ng-container>
        </li>-->
        <li
            class="m-0 nav-item  {{currentFolder =='in' && !isOptinPage && !isManualpublicationPage ? 'active' : ''}} text-body">
            <ng-container
                *ngTemplateOutlet="generateLink;context : { $implicit : {'folder':'in','folderIsActive': currentFolder =='in' && !isOptinPage && !isManualpublicationPage} }"></ng-container>
            <ul
                *ngIf="currentFolder =='in' && !isOptinPage && !isManualpublicationPage && nbOfMessageWithActionRequired !=0"
                class="subNav list-unstyled flex-column">
                <li
                    class="nav-item text-body {{filteredOnRequiredAction ? '' : 'active'}}">
                    <a href="javascript:void(0)" (click)="switchFolder('in')"
                        class="nav-link d-flex align-items-center justify-content-between"
                        title="{{'leftnav.in.sub-title.messages' | translate}}">
                        <span>{{'leftnav.in.sub-title.messages' | translate}}</span>
                    </a>
                </li>
                <li
                    class="nav-item {{filteredOnRequiredAction ? 'active' : ''}} text-body">
                    <a href="javascript:void(0)"
                        (click)="filterOnMessageWithRequiredAction($event)"
                        class="nav-link d-flex align-items-center justify-content-between"
                        title="{{'leftnav.in.sub-title.actionRequired' | translate}}">
                        <span>{{'leftnav.in.sub-title.actionRequired' |
                            translate}}</span> <span class="badge badge-pill "
                            id="nbOfMessageWithActionRequired" role="status"
                            aria-live="polite">
                            {{nbOfMessageWithActionRequired}}</span>
                    </a>
                </li>
            </ul>
        </li>
        <li
            class="nav-item {{currentFolder =='out' && !isOptinPage && !isManualpublicationPage ? 'active' : ''}} text-body">
            <ng-container
                *ngTemplateOutlet="generateLink;context : { $implicit : {'folder':'out','folderIsActive': currentFolder =='out' && !isOptinPage && !isManualpublicationPage} }"></ng-container>
        </li>
        <li
            class="nav-item {{currentFolder =='hide' && !isOptinPage && !isManualpublicationPage ? 'active' : ''}} text-body">
            <ng-container
                *ngTemplateOutlet="generateLink;context : { $implicit : {'folder':'hide','folderIsActive': currentFolder =='hide' && !isOptinPage && !isManualpublicationPage} }"></ng-container>
        </li>
    </ul>
</nav>

<ng-template #generateLink let-link>
    <ng-container
        *ngIf="(link.folder !== 'in' || (link.folder === 'in' && (!link.folderIsActive || nbOfMessageWithActionRequired ==0)))">
        <a href="javascript:void(0)" (click)="switchFolder(link.folder)"
            class="nav-link"
            title="{{'leftnav.'.concat(link.folder).concat('.title') | translate}}">
            <i
                class="fas fa-{{(link.folder=='in')? 'inbox' : (link.folder=='out' ? 'share':'eye-slash')}}"
                aria-hidden="true"></i>
            <span>{{'leftnav.'.concat(link.folder).concat('.title') |
                translate}}</span>
        </a>
    </ng-container>
    <ng-container
        *ngIf="link.folder === 'in' && link.folderIsActive && nbOfMessageWithActionRequired != 0">
        <a class="nav-link"
            title="{{'leftnav.'.concat(link.folder).concat('.title') | translate}}">
            <i
                class="fas fa-{{(link.folder=='in')? 'inbox' : (link.folder=='out' ? 'share':'eye-slash')}}"
                aria-hidden="true"></i>
            <span>{{'leftnav.'.concat(link.folder).concat('.title') |
                translate}}</span>
        </a>
    </ng-container>
</ng-template>