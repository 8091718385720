import { Pipe, PipeTransform } from '@angular/core';
import { TranslatedStringPipe } from './translated-string.pipe';
import { MessageTypeWithRegistryConfig } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';
import { NGXLogger } from "ngx-logger";
import { StateFn } from '../store/StateFn';

interface ServiceParam { 'id': string, 'dataType': string, 'messageType': MessageTypeWithRegistryConfig, 'registryId': string }

@Pipe({
  name: 'messageType',
  pure: false
})
export class MessageTypePipe implements PipeTransform {

  constructor(private logger: NGXLogger, private translatedPipe: TranslatedStringPipe, private stateFn: StateFn) {
  }

  transform(value: MessageTypeWithRegistryConfig, args?: { 'id': string, 'dataType': string, 'registryId': string }): any {
    const serviceParam: ServiceParam = { id: null, dataType: null, messageType: null, registryId: null };
    serviceParam['id'] = args.id;
    serviceParam['dataType'] = args.dataType;
    serviceParam.registryId = args.registryId;
    serviceParam.messageType = value;
    const result = this.getMessageType(serviceParam);
    if (result === null) {
      this.logger.info('messageType %s no valid for id= %s', JSON.stringify(value), serviceParam.id)
      return "";
    }
    return result
  }

  private getMessageType(serviceParam: ServiceParam) {
    if (serviceParam.messageType == null) {
      try {
        const msgTypeFounded = this.stateFn.getAllReferencesData().messageTypesState.find(msgType => (msgType.id == serviceParam.id && msgType.registryConfig.configRegistry.id == serviceParam.registryId));
        if (msgTypeFounded === undefined) throw Error("messageType notFounded id: " + serviceParam.id + ", registryId: " + serviceParam.registryId);
        serviceParam.messageType = msgTypeFounded;
      } catch (error) {
        this.logger.error(error);
        return null;
      }

    }


    return this.translatedPipe.transform(serviceParam.messageType.name, this.stateFn.getLanguage());

  }

}


