import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Partition } from '../interfaces/partition';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartitionManagement } from "./management-partitions.component";

@Component({
  selector: 'app-delete-partition',
  templateUrl: './delete-partition.component.html'
})
export class DeletePartitionComponent implements OnInit {


  @Input() isMobile: boolean;

  deletePartitionForm: UntypedFormGroup;
  @Input() myPartitions: Partition[];
  @Input() partitionToDelete: PartitionManagement;
  @Input() nbMessageSelected: number;

  private static readonly DEFAULT: string = "default"

  constructor(public activeModal: NgbActiveModal) {


  }

  ngOnInit(): void {
    this.deletePartitionForm = new UntypedFormGroup({
      selectedPartition: new UntypedFormControl({ value: this.myPartitions.length == 1 ? this.myPartitions[0].id : DeletePartitionComponent.DEFAULT, disabled: this.myPartitions.length == 0 }, { validators: [Validators.required] }),
    })

  }

  public closeForms() {
    this.activeModal.dismiss();
  }

  deletePartitionSubmit() {
    if (DeletePartitionComponent.DEFAULT == this.deletePartitionForm.controls.selectedPartition.value) {
      this.deletePartitionForm.controls.selectedPartition.setErrors({ selectedPartitionError: true });
      this.deletePartitionForm.setErrors({ selectedPartitionError: true });
    } else {
      this.closeAndResetModal(this.deletePartitionForm.controls.selectedPartition.value);
    }
  }

  closeAndResetModal(partitionId) {
    if (partitionId != undefined) {
      this.activeModal.close({ targetPartitionId: partitionId });
    } else {
      this.activeModal.dismiss();
    }
  }

}
