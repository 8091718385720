import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../services';
import { HandleExceptionService } from '../../error/handle-exception-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OpenIdService } from '../../auth/openIdService';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styles: []
})
export class NotFoundComponent implements OnInit {

  constructor(private loadingService: LoadingService, private _handleExceptionService: HandleExceptionService, private _openIdService: OpenIdService) { }

  ngOnInit() {
    this._openIdService.isAuthenticated().subscribe(respProfile => {
      this.loadingService.stop('NotFoundComponent');
      let errorResponse: HttpErrorResponse = null;
      if (respProfile) {
        errorResponse = new HttpErrorResponse({ status: 404, statusText: 'Error not Found', url: '' });


      } else {
        errorResponse = new HttpErrorResponse({ status: 401, statusText: 'User Not Connected', url: '' });
        //localStorage.setItem("attemptLastConnection", "1");
      }
      this._handleExceptionService.handlerError(errorResponse);
    },error => {
      localStorage.setItem("attemptLastConnection", "not found");
      this._handleExceptionService.handlerError(new HttpErrorResponse({ status: 401, statusText: 'User Not Connected', url: '' }));
    })





  }

}
