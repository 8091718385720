import { Injectable, ElementRef } from '@angular/core';

export interface PanelPositionFixed {
  idPanelToFixed: string;
  elemntRef: ElementRef<HTMLElement>;
  positionInit: number;
  positionChanged?:number|null;
}

@Injectable({
  providedIn: 'root'
})
export class SetPositionFixedService {

  private _elementsFixed: PanelPositionFixed[] = [];

  private _hightest: number;

  public get totalClientTop(): number {
    return this._hightest;
  }

  public set totalClientTop(value: number) {
    this._hightest = value;
  }

  public get elementsFixed(): PanelPositionFixed[] {
    return this._elementsFixed;
  }

  public set elementsFixed(value: PanelPositionFixed[]) {

    this._elementsFixed = value;
  }

    public addElement(value: PanelPositionFixed) {
      let alReadyAdded = false;
      this._elementsFixed.forEach ((elm: PanelPositionFixed) => {
        if (elm.idPanelToFixed == value.idPanelToFixed){
          alReadyAdded = true;
          
          elm.positionChanged = value.positionInit;
          elm.elemntRef = value.elemntRef;
          
        }
      })
      if(!alReadyAdded){
    this.totalClientTop = this.totalClientTop + value.elemntRef.nativeElement.clientHeight;
    this._elementsFixed.push(value);
      }else{
      }
  }
  
  public updateElement(idPanel: string, changePosition: number) {
    this._elementsFixed.forEach((elm: PanelPositionFixed) => {
      if (elm.idPanelToFixed == idPanel) {
        elm.positionChanged = elm.positionInit + changePosition;
       // logger.warn('position init %s, position changed %s', elm.positionInit, elm.positionChanged);
        return;
      }
    });
  }

  public removeElement(idPanel: string){
    
    this._elementsFixed.forEach((elm: PanelPositionFixed) => {
      if (elm.idPanelToFixed == idPanel) {
        this._elementsFixed.splice(this._elementsFixed.indexOf(elm), 1);
        return;
      }
    });
    this.refreshPositioning();
  }

  public resetElement(idPanel: string) {
    this._elementsFixed.forEach((elm: PanelPositionFixed) => {
      
      if (elm.idPanelToFixed == idPanel) {
        elm.positionChanged = elm.positionInit;
        return;
      }
    });
  }
  public hasElement(idPanel: string): PanelPositionFixed{
    let hasElement: PanelPositionFixed = null;
    this._elementsFixed.forEach((elm: PanelPositionFixed) => {
      if (elm.idPanelToFixed == idPanel) {
        hasElement = elm;
        return;}
    })
    return hasElement;
  }

  public refreshPositioning(){
    const paddingTop: number = this.totalClientTop - window.pageYOffset;
    this.elementsFixed.forEach(panelPosition => {
      const elmnt: ElementRef<HTMLElement> = panelPosition.elemntRef;
      if (!elmnt.nativeElement.classList.contains('firstFixedHeader')) {
       //logger.warn("refresh elmt %s", JSON.stringify(panelPosition));
        elmnt.nativeElement.style.setProperty('top', (panelPosition.positionChanged != undefined && panelPosition.positionChanged != null ? panelPosition.positionChanged : panelPosition.positionInit) + 'px');
      }


      if (window.pageYOffset > 1) {
        if (paddingTop > 0) {
          document.body.style.paddingTop = String(paddingTop) + 'px';
        }
        elmnt.nativeElement.classList.add('fixed');
      } else {
        elmnt.nativeElement.classList.remove('fixed');
        document.body.style.removeProperty('padding-top');
        if (!elmnt.nativeElement.classList.contains('firstFixedHeader')) {
          // elmnt.nativeElement.style.removeProperty('top');
        }
      }
    });

  }

  constructor() { this.totalClientTop = 0; }
}


