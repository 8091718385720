import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageData, MessagesToUpdateWithRegistryConfig } from "@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData";
import { ActivatedRoute, Router } from "@angular/router";
import { ActionsService, LoadingService, ResponsiveService } from "../../services";
import { ActionsStore } from "../../store/actions-store";
import { StoreService } from "../../store/store.service";
import { StateFn } from "../../store/StateFn";
import { Subscription } from "rxjs";
import { ApplicationConstants } from '../../app.constants';
import { EboxId, MessagesMoveToOtherPartition } from '@smals/ebox-enterprise-client/dist/src/external-lib/client_api/api';
import { Partition } from '../../pacman/interfaces/partition';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MovePartitionComponent } from '../actions/movePartition/move-partition.component';
import { Folder, StatusForms } from '../../store/store-data-interface';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styles: []
})
export class DetailsComponent implements OnInit, OnDestroy {

  private _message: MessageData;
  public isMobile: boolean;
  private _language: string;
  private _bodyContent: string;
  isModeReply: boolean;
  public folder: string;
  private _originalMessage: MessageData;
  private _originalBodyContent: string;
  allPartitions: Partition[];
  private modalPartitionChoice: NgbModalRef;


  constructor(private modalService: NgbModal,
    private _router: Router, private _route: ActivatedRoute, private responsiveService: ResponsiveService,
    private _actionsService: ActionsService, private _actionsStore: ActionsStore,
    private _storeService: StoreService, private stateFn: StateFn, public appConstant: ApplicationConstants, private _loadingService: LoadingService) {
    this._language = this.stateFn.getLanguage();
    this.bodyContent = "";
    this.isModeReply = false;
    this.folder = this.stateFn.convertFolderToStr(this.stateFn.getCurrentFolder());
    //if (stateFn.getAllReferencesData() == null) this._actionsService.initAllReferenceData();

  }
  private subscriptions: Subscription[] = []

  ngOnInit() {
    this.subscriptions.push(this.responsiveService.registerToChange(this));
    this.subscriptions.push(this._storeService.store.subscribe(store => {
      this.folder = this.stateFn.convertFolderToStr(this.stateFn.getCurrentFolder());
      this.isModeReply = store.userActions.dataForms.isModeReply;
      if (this.message != null && this._language !== store.userSession.localeParameter) {
        this._language = store.userSession.localeParameter;
        this.getCurrentDetails(this.message.messageId, this.message.registryId).then(result => {
          this.message = result;
        });
      }
    }
    ));
    this.allPartitions = this.stateFn.getAllPartitions();
    this._route.queryParams.subscribe(params => {
      if (params.idMessage !== undefined && params.idProviderRegistry) {
        this.getCurrentDetails(params.idMessage, params.idProviderRegistry).then(result => {
          this.message = result;
          if (params.modeReply != undefined) {
            this._actionsStore.updateModeReply(params.modeReply, this.message.sender);
          }
        })
      } else {
        this._router.navigate(['/']);
      }

    })
  }

  private getCurrentDetails(idMessage: string, idProviderRegistry: string): Promise<MessageData> {

    return this._actionsService.detailMessage(idMessage, idProviderRegistry).then((result: MessageData) => {
      if (result != undefined) {
        if (this.appConstant.isNullOrUndefined(result.recipient) && this.folder == 'in') {
          const organizationName = this.stateFn.getOrganizationNameById(this.stateFn.getUser().cbeNumber);

          result.recipient = {
            applicationId: null,
            applicationName: null,
            companyId: this.stateFn.getUser().cbeNumber,
            companyName: organizationName != null ? organizationName[this.stateFn.getLanguage()] : this.stateFn.getUser().enterpriseName,
            type: EboxId.EboxTypeEnum.enterprise,
            value: this.stateFn.getUser().cbeNumber,
            logoHref: null,
          }
        }

        if (!this.appConstant.isNullOrUndefined(result.recipient) && this.appConstant.isNullOrUndefined(result.recipient.companyName)) {
          const recipient = this.stateFn.getOrganizationNameById(result.recipient.value);
          result.recipient.companyName = (recipient != null) ? recipient[this._language] : null;
        }

        if (this.folder == 'in' && result.parentMessageId !== undefined) {
          this.getPreviousMessage(result);
        }

        if (result.hasBody != undefined && result.hasBody) this._actionsService.bodyContentOfMessage(idMessage, idProviderRegistry).then(
          content => {
            this._bodyContent = content
          }
        );
        return result;
      }
    }
    );
  }

  public getPreviousMessage(message: MessageData) {
    this._actionsService.detailMessage(message.parentMessageId, message.registryId).then(result => {

      this._originalMessage = result;

      if (this._originalMessage.hasBody) this._actionsService.bodyContentOfMessage(message.parentMessageId, message.registryId).then(
        content => {
          this.originalBodyContent = content

        });

    }
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  public get message(): MessageData {
    return this._message;
  }

  public set message(value: MessageData) {
    this._message = value;
  }
  public get bodyContent(): string {
    return this._bodyContent;
  }

  public set bodyContent(value: string) {
    this._bodyContent = value;
  }

  public get originalMessage(): MessageData {
    return this._originalMessage;
  }

  public set originalMessage(value: MessageData) {
    this._originalMessage = value;
  }

  public get originalBodyContent(): string {
    return this._originalBodyContent;
  }

  public set originalBodyContent(value: string) {
    this._originalBodyContent = value;
  }

  public isLoggedUser(ssin: String) {
    return this.stateFn.isOpenIdActive() ? (ssin === this._storeService.store.getValue().userSession.user.ssin) : false;
  }

  backResultMessage() {
    this._actionsService.backSearchAndMessageFiltersAndPage();
  }

  public showReplyForms() {
    this._actionsStore.updateStatusReplyForms(null);
    window.history.pushState(null, "", location.pathname + "?idMessage=" + this.message.messageId + "&idProviderRegistry=" + this.message.registryId + "&modeReply=" + !this.isModeReply);
    this._actionsStore.updateModeReply(!this.isModeReply, this.message.sender);
  }

  changeStateOfMessage() {
    const listMessageForChangeState: MessagesToUpdateWithRegistryConfig = { messagesToUpdateWithRegistryConfig: {} };
    listMessageForChangeState.messagesToUpdateWithRegistryConfig[this.message.registryId] = { messageIds: [] };
    listMessageForChangeState.messagesToUpdateWithRegistryConfig[this.message.registryId].messageIds.push(this.message.messageId);
    listMessageForChangeState.messagesToUpdateWithRegistryConfig[this.message.registryId].isVisible = !this.message.statusMessageData.visible;
    this._actionsService.updateVisibility(listMessageForChangeState).then(resp => {
      this._actionsService.backSearchAndMessageFiltersAndPage();
    });
  }
  moveCurrentMessageToOtherPartition() {
    this._actionsStore.clearStatusForms();
    this._actionsStore.updateMoveMessage(true);
    this.openMovePartitionChoice();
    this.modalPartitionChoice.result.then((statusFormModal) => {
      const toPartitionId = statusFormModal["partitionId"];

      const messagesToMoveByRegistry: Record<string, MessagesMoveToOtherPartition> = {};
      const messagesToMove: MessagesMoveToOtherPartition = {}
      messagesToMove.messageIds = [this.message.messageId];
      //messagesToMove.sourcePartitionId
      if (Folder.out == this.stateFn.getCurrentFolder()) {
        messagesToMove.targetSenderPartitionId = toPartitionId;
      } else {
        messagesToMove.targetRecipientPartitionId = toPartitionId;
      }

      messagesToMoveByRegistry[this.message.registryId] = messagesToMove;

      this._actionsService.moveMessagesIdToPartition(messagesToMoveByRegistry)
        .then(result => {

          const myPartition = this.stateFn.getAllPartitions().find((myPartitionWithAccess: Partition) => myPartitionWithAccess.id == toPartitionId);
          if (myPartition != undefined) {

            this.message.recipient.partition = toPartitionId;
            this._actionsStore.updateStatusMoveMessage(StatusForms.success, toPartitionId);
          }
          this._router.navigate(['/']);


        })
        .finally(() => { this._loadingService.stop("moveCurrentMessageToOtherPartition"); });

    });
  }

  openMovePartitionChoice() {
    const options: NgbModalOptions = {
      keyboard: true,
      backdrop: 'static',
      centered: true,
      windowClass: 'movePartitionModal',

    };
    options.keyboard = false;
    this.modalPartitionChoice = this.modalService.open(MovePartitionComponent, options);
    this.modalPartitionChoice.shown.subscribe(() => this._loadingService.stop("start open Move Partition choice"));
    this.modalPartitionChoice.componentInstance.isMobile = true;
    this.modalPartitionChoice.componentInstance.myPartitions = this.allPartitions.filter(p => this.message.recipient.partition != p.id);
    this.modalPartitionChoice.componentInstance.nbMessageSelected = 1;

  }

}
