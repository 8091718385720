
import { EboxClient, EboxClientImpl } from "@smals/ebox-enterprise-client/dist/src/external-lib/ServiceClient/EboxClient";
import { StateFn } from "../store/StateFn";
import { NoCache } from "../store/NoCache";
import { Injectable } from "@angular/core";
import { ConfigurationService } from "@smals/ngx-configuration-service";
import { EnterpriseUser, uniformizeCbeNumber } from "../models/enterprise-user";
import { SenderOrganization } from '@smals/ebox-enterprise-client/dist/src/external-lib/client_api';
import { LocalizedString } from "@angular/compiler";


@Injectable()
export class EboxClientProvider {
  private searchClient: EboxClient;
  private _currentLanguage = null;

  constructor(private stateFn: StateFn, private _configService: ConfigurationService) {
  }

  getSearchClient(hasConsultationRightGeneral: boolean): EboxClient {

    //test for language change
    if (this.searchClient == null || (this.searchClient != null && this._currentLanguage != null && this._currentLanguage != this.stateFn.getLanguage())) {

      const parameters = this.stateFn.getParametersClient();
      // Have to do it here because the StateFn.getClientConfig is not inited at startup

      if (parameters.search.folder == 'out') {
        parameters.search.messageType = this._configService.getEnvironmentVariable('sentboxConfig');
      }
      this.searchClient = new EboxClientImpl(hasConsultationRightGeneral == undefined ? false : hasConsultationRightGeneral, parameters, NoCache.inst, this.stateFn.getConfigClient(), this.resolveRecipient(this.stateFn.getUser()));
    }
    this._currentLanguage = this.stateFn.getLanguage();
    return this.searchClient;
  }

  setSearchClient(client: EboxClient) {
    this.searchClient = client;
  }

  resolveRecipient(user: EnterpriseUser) {
    const cbeNumber = user == null ? "0" : uniformizeCbeNumber(user.cbeNumber);
    const language = this.stateFn.getLanguage();
    return (recipient?: string) => {
      const recipientNumber = uniformizeCbeNumber(recipient);
      if (cbeNumber == recipientNumber) {
        //        console.error('No Resolve Recipient for this recipient %s', recipientNumber);
        const enterpriseName = {};
        enterpriseName[language] = user.enterpriseName;
        return { enterpriseNumber: cbeNumber, name: enterpriseName as LocalizedString } as SenderOrganization;
      }
      return null;

    }

    //return(cbeNumber == recipient) ? false : true;
  }




}
