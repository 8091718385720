<div *ngIf="sizeAlerts !==0" #fixedAlert
    class="{{isMobile ? ( isConsultMessagePage ?'bg-white ' :'bg-gray ').concat('panelToFixed') :''}}">
    <div
        *ngIf="getTypeAlertVisible().indexOf('warning')!==-1  && alerts['warning']!==undefined"
        class="alert alert-warning container-fluid ">
        <div class="row justify-content-end g-0" #alertCollapse>

            <h2 #toggleCollapse>
                <a href="javascript:void(0)"
                    (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="alerts"
                    class="d-block">
                    <i class="far fa-bell"></i> {{'communications.title.'.concat(isMobile?'mobile.':'desktop.').concat((alerts['warning'].length===1)?'one':'many')
                    | translate:{value1:alerts['warning'].length} }}
                    <i
                        class="fas {{isCollapsed ? 'fa-caret-down' :'fa-caret-up'}} {{isMobile ? 'pull-right' :''}}"></i>
                </a>
            </h2>
            <div id="alerts" [ngbCollapse]="isCollapsed" #contentCollapse>
                <div class="card">
                    <div class="card-body">
                        <ul class="list-unstyled">
                            <li *ngFor="let com of alerts['warning']">
                                <a *ngIf="com.nameOfResult !== 'optin'"
                                    class="g-0 d-flex" href="javascript:void(0)"
                                    (click)="navigateToFilter(com.nameOfResult)">
                                    <i class="fas fa-info-circle"
                                        aria-hidden="true"
                                        [attr.aria-describedby]="'#srOnlyWarning_'+com.nameOfResult"></i>
                                    <span class="visually-hidden"
                                        id="srOnlyWarning_{{com.nameOfResult}}">{{'communications.info.title'
                                        | translate}}</span>
                                    <span
                                        *ngIf="com.nameOfResult ==='recommend'"
                                        [innerHTML]="'communications.recommend.'.concat(com.count  === 1 ? 'one':'many') | translate:{value1:com.count}"></span>
                                    <span *ngIf="com.nameOfResult ==='expired'"
                                        [innerHTML]="'communications.expired.'.concat(com.count  === 1 ? 'one':'many') | translate:{value1:com.count}"></span>
                                </a>
                                <a *ngIf="com.nameOfResult === 'optin'"
                                    routerLink="/management" class="g-0 d-flex"
                                    (click)="isCollapsed=true"
                                    href="javascript:void(0)">
                                    <i class="fas fa-info-circle"
                                        aria-hidden="true"></i>
                                    <span><strong>{{'communications.optin' |
                                            translate}}</strong></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        *ngIf="getTypeAlertVisible().indexOf('info')!==-1  && alerts['info']!==undefined"
        class="alert alert-info">
        <div class=" container-fluid" *ngIf="sizeAlerts !==0">
            <div class="row">
                <p class="g-0 d-flex">
                    <i class="fas fa-info-circle col-auto" aria-hidden="true"
                        aria-describedby="#srOnlyInfo"></i>
                    <span class="visually-hidden" id="srOnlyInfo">{{'communications.info.title'
                        | translate}}</span>
                    <ng-container
                        *ngIf="alerts['info'][0].nameOfResult=='publicationOnly'">
                        <span class="col-auto w-100"
                            [innerHTML]="'communications.info.'.concat(alerts['info'][0].nameOfResult) | translate:{href1:webSiteInfo + ('infoPath' | translate), a1:webSiteInfo + ('infoPath' | translate)}">
                        </span>
                    </ng-container>
                    <ng-container
                        *ngIf="alerts['info'][0].nameOfResult=='notice'">
                        <span class="col-auto w-100"
                            [innerHTML]="'communications.info.notice' | translate">
                        </span>
                    </ng-container>

                </p>
            </div>
        </div>
    </div>

    <div *ngIf="hasError" class="alert alert-danger mb-0"
        data-testid='communicationError'>
        <div class="container-fluid" *ngIf="sizeAlerts !==0">
            <div class="row">
                <p class="g-0 d-flex w-100"
                    *ngIf="getCommonError().length !=0 && getProviderError().length==0">
                    <i class="fas fa-exclamation-circle col-auto"
                        aria-hidden="true" aria-describedby="#srOnlyerror"></i>
                    <span class="visually-hidden" id="srOnlyerror"
                        data-testid='srOnlyerror'>{{'communications.error.title'
                        | translate}}</span>
                    <span class="col-auto w-100"
                        data-testid='communicationErrorContent'>
                        <ng-container
                            *ngIf="getCommonError()[0]['type'] !=undefined && includeErrorWithProvider">
                            {{'communications.error.'.concat(getCommonError()[0]['type']
                            == 'GET_MESSAGE' ? 'message':getCommonError()[0]['message'])
                            | translate }}
                        </ng-container>
                        <ng-container
                            *ngIf="getCommonError()[0]['type'] == undefined || !includeErrorWithProvider">
                            {{'communications.error.'.concat(getCommonError()[0]['error'])
                            | translate }}
                        </ng-container>
                        <ng-container
                            *ngIf="getCommonError()[0]['providerRegistryId'] !==undefined && getCommonError()[0]['providerRegistryId'].length == 1">
                            {{'communications.error.providerError.one' |
                            translate:{dp:this.stateFn.getTranslatedProvider(getCommonError()[0]['providerRegistryId'][0])}
                            }}
                        </ng-container>
                    </span>

                    <a href='javascript:void(0)' (click)="removeError()"
                        class="removeAlert text-end"><i class="fas fa-times"
                            aria-hidden="true"></i><span class="visually-hidden">{{'communications.error.remove'
                            | translate }}</span></a>

                </p>
                <div
                    *ngIf="getProviderError().length==0 && getCommonError()[0]['providerRegistryId'] !==undefined && getCommonError()[0]['providerRegistryId'].length > 1"
                    class="g-0 d-flex w-100">
                    <div class="g-0 flex-fill ">
                        <p class="g-0 mb-0">

                            <span class="col-auto w-100">
                                {{'communications.error.providerError.many' |
                                translate }}
                                {{'communications.error.providerError.subText' |
                                translate }}
                            </span>
                        </p>
                        <p class="g-0 mb-0" #toggleDpCollapse>
                            <a href="javascript:void(0)"
                                (click)="isDpCollapsed = !isDpCollapsed"
                                [attr.aria-expanded]="!isDpCollapsed"
                                aria-controls="alerts" class="d-block">
                                {{'communications.error.providerError.showList'
                                | translate }}

                                <i
                                    class="fas {{isDpCollapsed ? 'fa-caret-down' :'fa-caret-up'}} {{isMobile ? 'pull-right' :''}}"></i>
                            </a>
                        </p>
                        <ul [ngbCollapse]="isDpCollapsed" #contentDpCollapse
                            class="m-0 py-0"
                            *ngIf="getCommonError()[0]['providerRegistryId'] !=undefined">
                            <li class=" p-0 m-0 "
                                *ngFor="let provider of getCommonError()[0][ 'providerRegistryId'] ">
                                {{this.stateFn.getTranslatedProvider(provider)}}
                            </li>
                        </ul>
                    </div>
                </div>
                <div
                    *ngIf="getProviderError().length!=0 && (getCommonError().length ==0  || getCommonError()[0]['providerRegistryId'] == undefined) "
                    class="g-0 d-flex w-100 ">
                    <ng-container
                        *ngTemplateOutlet="tmplError; context: {$implicit: getProviderError()} ">
                    </ng-container>
                </div>

            </div>
        </div>
    </div>

</div>

<ng-template #tmplError let-providerErrors>

    <ng-container *ngIf="providerErrors.length==1 ">
        <p class="g-0 d-flex w-100 ">
            <i class="fas fa-exclamation-circle col-auto " aria-hidden="true "
                aria-describedby="#srOnlyerror "></i>
            <span class="visually-hidden " id="srOnlyerror " data-testid='srOnlyerror'>{{'communications.error.title' | translate}}</span>
            <span class="col-auto w-100 "
                data-testid='communicationErrorContent'>
                {{'communications.error.'+(providerErrors[0]['error'])+'.general' | translate }}
                {{'communications.error.'+(providerErrors[0]['error'])+'.one' | translate:{dp: providerErrors[0]['temporarilyDisabled'] ? (providerErrors[0]['dpName'] | translatedString) : (this.stateFn.getTranslatedProvider(providerErrors[0]['providerId']))} }}
                {{'communications.error.providerError.subText'  | translate }}
            </span>
            <a href='javascript:void(0)' (click)="removeError() " class="removeAlert text-end ">
                <i class="fas fa-times " aria-hidden="true "></i>
                <span class="visually-hidden ">{{'communications.error.remove' | translate }}</span>
            </a>
        </p>
    </ng-container>
    <ng-container *ngIf="providerErrors.length> 1">
        <i class="fas fa-exclamation-circle col-auto" aria-hidden="true" aria-describedby="#srOnlyerror"></i>
        <span class="visually-hidden" id="srOnlyerror" data-testid='srOnlyerror'>{{'communications.error.title' | translate}}</span>
        <div class="g-0 flex-fill ">
            <p class="g-0 mb-0">
                <span class="col-auto w-100" data-testid='communicationErrorContent'>
                    {{'communications.error.'+(providerErrors[0]['error'])+'.general' | translate }} {{'communications.error.'+(providerErrors[0]['error'])+'.many' | translate }}
                    {{'communications.error.providerError.subText' | translate}}
                </span>
            </p>
            <p class="g-0 mb-0" #toggleDpCollapse>
                <a href="javascript:void(0)" (click)="isDpCollapsed = !isDpCollapsed" [attr.aria-expanded]="!isDpCollapsed" aria-controls="alerts" class="d-block">
                    {{'communications.error.providerError.showList' | translate}}
                    <i class="fas {{isDpCollapsed ? 'fa-caret-down' :'fa-caret-up'}} {{isMobile ? 'pull-right' :''}}"></i>
                </a>
            </p>
            <ul [ngbCollapse]="isDpCollapsed" #contentDpCollapse class="m-0 py-0">
                <li class="p-0 m-0" *ngFor="let provider of providerErrors">
                    {{this.stateFn.getTranslatedProvider(provider['providerId'])}}
                </li>
            </ul>
        </div>
        <a href='javascript:void(0)' (click)="removeError()" class="removeAlert text-end">
            <i class="fas fa-times" aria-hidden="true"></i>
            <span class="visually-hidden">{{'communications.error.remove' | translate }}</span>
        </a>
    </ng-container>

</ng-template>
