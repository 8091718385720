const TECHNICALDATAKEYS: string[] = ["DOC_TYPE", "EBOX_REST_SEQUENCE", "EBOX_REST_TICKET"];
const BUSINESSDATAKEYSIGNORE: string[] = ["FOR_ACTION", "FOR_ACTION_DUE_DATE"];

export class BusinessDataHelper {

  public static isTechnicalData(key: string): boolean {
    return TECHNICALDATAKEYS.includes(key);
  }

  public static isBusinessData(key: string): boolean {
    return TECHNICALDATAKEYS.includes(key);
  }

  public static isExceptionKeyBusinessData(key: string): boolean {
    return BUSINESSDATAKEYSIGNORE.includes(key);
  }
}
