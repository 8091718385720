import { ObjectHelper } from "../../helper/ObjectHelper";
import { SearchAndFilterRequest, defaultPageSize, ActionsStore } from "../../store/actions-store";
import moment from "moment";
import { VisibilityStatus, MessageFilters, StoreShape, Folder } from "../../store/store-data-interface";

interface SerializedFilters {
  q: string
  v: VisibilityStatus
  reg: boolean
  exp: boolean
  r: boolean
  ur: boolean
  o: string | null
  a: string | null
  pdt: Date
  pdf: Date,
  f:Folder,
  rp: string | null,
  sp:string | null,
  act: boolean
}


function serializedFiltersFromState(store: StoreShape, actionsStore: ActionsStore) {
  const filters = store.messageFilters;
  const filtersMap: SerializedFilters = {
    q: filters.q,
    v: filters.visibility,
    a: filters.senderApplicationSelection,
    o: filters.senderOrganizationSelection,
    exp: filters.includeExpiringMessages,
    reg: filters.includeRegisteredMessages,
    r: filters.includeReadMessages,
    ur: filters.includeUnreadMessages,
    pdf: filters.publicationDateFrom,
    pdt: filters.publicationDateTo,
    f: filters.folder,
    rp: filters.recipientPartition,
    sp: filters.senderPartition,
    act: filters.includeRequiredAction
  };

  if (filtersMap.v == actionsStore.clearedFilters().visibility)
    delete filtersMap.v;
  Object.getOwnPropertyNames(filtersMap).forEach(name => {
    if (filtersMap[name] == null || filtersMap[name] === false) {
      delete filtersMap[name];
    }
  })
  if (Object.getOwnPropertyNames(filtersMap).length == 0)
    return null;
  else
    return filtersMap;
}


function messageFiltersFromSerializedFilters(serializedFilters: SerializedFilters): Partial<MessageFilters> {
  const ret: MessageFilters = {
    q: serializedFilters.q,
    visibility: serializedFilters.v,
    publicationDateTo: serializedFilters.pdt,
    publicationDateFrom: serializedFilters.pdf,
    includeUnreadMessages: serializedFilters.ur,
    includeReadMessages: serializedFilters.r,
    includeRegisteredMessages: serializedFilters.reg,
    includeExpiringMessages: serializedFilters.exp,
    senderOrganizationSelection: serializedFilters.o,
    senderApplicationSelection: serializedFilters.a,
    folder : serializedFilters.f,
    recipientPartition: serializedFilters.rp,
    senderPartition : serializedFilters.sp,
    includeRequiredAction : serializedFilters.act
  };
  Object.keys(ret).forEach(k => {
    if (ret[k] == null) {
      delete ret[k];
    }
  })
  return ret;
}

export class MessageListUrlParameters {
  p: string
  f: string
  folder:string

  static fromState(store: StoreShape, actionsStore: ActionsStore): MessageListUrlParameters {
    const params = new MessageListUrlParameters;
    const searchState = store.searchState;
    if (searchState.pageSize != defaultPageSize) {
      params.p = "" + store.searchState.pageSize;
    }

    const filterMap = serializedFiltersFromState(store, actionsStore);
    if (filterMap != null) {
      params.f = ObjectHelper.objectEncode(filterMap);
    }


    return params;
  }

  static toStateAndSearch(queryParams: URLSearchParams): SearchAndFilterRequest {
    const request: SearchAndFilterRequest = {};
    if (queryParams.has('p')) request.pageSize = parseInt(queryParams.get('p'), 10);
    if (queryParams.has('f') ) {
      const filtersDecoded = ObjectHelper.objectDecode<SerializedFilters>(queryParams.get('f'));
      request.filters = messageFiltersFromSerializedFilters(filtersDecoded);
      if (request.filters.publicationDateFrom) {
        request.filters.publicationDateFrom = moment(request.filters.publicationDateFrom, "YYYY-MM-DD").toDate()
      }
      if (request.filters.publicationDateTo) {
        request.filters.publicationDateTo = moment(request.filters.publicationDateTo, "YYYY-MM-DD").toDate()
      }
    }

    return request;
  }

}
