import { Injectable } from "@angular/core";
import { INGXLoggerConfig, NGXLoggerMetadataService } from "ngx-logger";

@Injectable()
export class NGXLoggerMetadataServiceCustom extends NGXLoggerMetadataService {

        computeTimestamp(config: INGXLoggerConfig):string{
        const defaultTimestamp = () => new Date().toString();
        if (config.timestampFormat) {
            if (!this.datePipe) {

                return defaultTimestamp();
            }
            else {
                return this.datePipe.transform(new Date(), config.timestampFormat);
            }
        }
        return defaultTimestamp();
        
    };
}
