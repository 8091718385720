import { Injectable } from '@angular/core';

@Injectable()
export class PacmanConstants {

    static readonly partitionsUsers :string ="ebox/{eboxId}/userPartitionRights";
    static readonly userPartitionRights :string ="ebox/{eboxId}/partitions/{partitionId}/users";
    static readonly partitions :string ="ebox/{eboxId}/partitions";
    static readonly partitionById :string ="ebox/{eboxId}/partitions/{partitionId}";

}
