<div class='managementPartition' data-testid="testIdManagementPartition">
    <ng-container *ngIf="storePartitionisLoaded || !hasAdminRightPartitition">
        <p class="mb-1 me-sm-0 ms-sm-0 mt-0 mb-sm-0 w-100 d-block">
            <a href="javascript:void(0)" (click)="backResultToManagePage()"
                data-testid="returnLink2" class="returnLink">{{"pacman.managementPartition.back"
                | translate}}</a>
        </p>
        <div
            class="{{isMobile ? 'd-block': 'd-flex align-items-center'}} border-bottom">

            <h2 class="ps-0 pt-0 pb-0  m-0 "
                [innerHTML]='"pacman.managementPartition.title" | translate'></h2>
        </div>
        <div *ngIf="!hasAdminRightPartitition"
            class="alert alert-danger mb-0 mt-3 hasAdminRightPartitition">
            <div class="container-fluid">
                <div class="row"
                    [innerHTML]="'pacman.pacmanProblem.noAccesInAdminPartition' | translate">
                </div>
            </div>
        </div>
        <div role="alert"
            class="alert alert-success w-100 mt-2  text-body ms-0 me-sm-0 pt-2 pb-2 border-0"
            *ngIf="successMessage">
            <p> {{successMessage | translate: {'partitionId' :
                this._stateFn.getPacmanFormsPartitionId()} }} </p>
        </div>
        <div role="alert"
            class="alert alert-danger w-100 mt-2  text-body ms-0 me-sm-0 pt-2 pb-2 border-0"
            *ngIf="errorMessage">
            <p> {{errorMessage | translate: {'partitionId' :
                this._stateFn.getPacmanFormsPartitionId()} }} </p>
        </div>
        <h3 class="mt-5">{{(this.showOnlyMyPartitions ?
            'pacman.managementPartition.mypartitions' :
            'pacman.managementPartition.allpartitions') | translate}}</h3>
        <p class="mb-1 me-sm-0 ms-sm-0 mt-0 mb-sm-0 w-100 d-block text-end">
            <a href="javascript:void(0)"
                (click)="this.showOnlyMyPartitions=!this.showOnlyMyPartitions"
                class="d-block text-primary text-underline">
                <i class="fa-solid fa-angle-right"></i>
                {{(!this.showOnlyMyPartitions ?
                'pacman.managementPartition.mypartitions' :
                'pacman.managementPartition.allpartitions') | translate}}
            </a>
        </p>

        <table class="table mt-2" *ngIf=" hasAdminRightPartitition">

            <thead class="desktop-only">
                <tr>
                    <th scope="col">{{"pacman.managementPartition.name" |
                        translate}}</th>
                    <th scope="col">{{"pacman.managementPartition.numberOfUsers"
                        | translate}}</th>
                    <th scope="col">{{"pacman.managementPartition.action" |
                        translate}}</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let part of partitions">
                    <tr scope="row">
                        <td class=" d-xs-flex  p-xs-reset">{{part.id |
                            partitionNamePipe}}</td>
                        <td
                            class="nbHumanUsers d-xs-inline-block col-xs-6 col-sm-auto">
                            {{(part.nbHumanUsers > 1 ?
                            "pacman.managementPartition.users" :
                            "pacman.managementPartition.user") | translate
                            :{value1 : part.nbHumanUsers } }}
                        </td>

                        <td
                            class="actions  d-xs-flex  p-xs-reset {{isMobile ?' justify-content-end' :'' }}">
                            <a (click)="$event.stopPropagation()"
                                href="javascript:void(0)" placement="left"
                                triggers="click hover focus"
                                [disableTooltip]="isMobile ? 'true' : null"
                                [autoClose]="'outside'"
                                ngbTooltip="{{'pacman.managementPartition.manage.user' | translate}}"
                                class="  {{isMobile ? 'btn btn-secondary d-flex  align-items-center ' :'link-primary'}}"
                                routerLink='../partitions/partitionUsers'
                                [queryParams]="{partitionId: part.id, userType:userTypeEnum.HUMAN}">
                                <i class="fas fa-users-gear"
                                    [attr.aria-label]="'pacman.managementPartition.manage.user' | translate"
                                    aria-hidden="true"></i>
                                <span
                                    [class]="isMobile ?'d-inline-block ms-2 text-start':'visually-hidden'">{{'pacman.managementPartition.manage.user'
                                    | translate}}</span>

                            </a>
                            <a
                                (click)="$event.stopPropagation(); addPartition(part);"
                                href="javascript:void(0)"
                                [disableTooltip]="isMobile ? 'true' : null"
                                triggers="click hover focus"
                                [autoClose]="'outside'"
                                ngbTooltip="{{'pacman.managementPartition.editPartition' | translate}}"
                                class=" {{isMobile ? 'd-flex btn btn-secondary align-items-center' :'link-primary me-1'}}">
                                <i class="fas fa-gear"
                                    [attr.aria-label]="'pacman.managementPartition.editPartition' | translate"
                                    aria-hidden="true"></i>
                                <span
                                    [class]="isMobile ?'d-inline-block ms-2 text-start':'visually-hidden'">{{'pacman.managementPartition.editPartition'
                                    | translate}}</span>
                            </a>
                            <a (click)="$event.stopPropagation(); delete(part);"
                                href="javascript:void(0)"
                                *ngIf="part.id != 'GENERAL' && part.id != 'NSSO_LEGACY_MANDATARY'"
                                [disableTooltip]="isMobile ? 'true' : null"
                                triggers="click hover focus"
                                [autoClose]="'outside'"
                                ngbTooltip="{{'pacman.managementPartition.deletePartition' | translate}}"
                                class=" {{isMobile ? 'd-flex btn btn-secondary align-items-center' :'link-primary me-1'}}">
                                <i class="fas fa-trash"
                                    [attr.aria-label]="'pacman.managementPartition.deletePartition' | translate"
                                    aria-hidden="true"></i>
                                <span
                                    [class]="isMobile ?'d-inline-block ms-2 text-start':'visually-hidden'">{{'pacman.managementPartition.deletePartition'
                                    | translate}}</span>
                            </a>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>

        <button class="btn btn-lg btn-primary  float-end"
            (click)="addPartition();">{{ 'pacman.addPartition.btnAdd' |
            translate }}</button>
    </ng-container>

</div>
