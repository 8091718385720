import { Component, OnInit } from '@angular/core';
import { HandleExceptionService } from './handle-exception-service.service';
import { ConfigurationService } from '@smals/ngx-configuration-service';
import { CommonException } from './CommonException';
import { LoadingService } from '../services/loading.service';
import { NavHelper } from '../helper/NavHelper';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {StateFn} from '../store/StateFn';


@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styles: []
})
export class ErrorPageComponent implements OnInit {

  private _currentException: CommonException;
  private webSiteInfo: string;
  public oldEbox: string;

  constructor(private exceptionService: HandleExceptionService, private _loadingService: LoadingService, private configService: ConfigurationService, private navHelper: NavHelper,private modalService: NgbModal, private _stateFn:StateFn) { 

  }

  ngOnInit() {

    this.currentException =     this._stateFn.getError();

    this._loadingService.stop('ErrorPageComponent init');
    this.webSiteInfo = this.configService.getEnvironmentVariable('urlWebsiteInfo');
    this.oldEbox = this.configService.getEnvironmentVariable('old_ebox');
    this.modalService.dismissAll("error modal");
  }


  public get currentException(): CommonException {
    return this._currentException;
  }


  public set currentException(value: CommonException) {
    this._currentException = value;
  }

  resetLogin() {
    this.exceptionService.resetLogin();
    this.navHelper.changeWindowLocationTo(this.webSiteInfo);
  }

}
