import { Component, OnInit} from '@angular/core';
import {ActionsService} from "../../../store/actions.service";
import {StateFn} from "../../../store/StateFn";

@Component({
  selector: 'app-select-items-by-page',
  templateUrl: './select-items-by-page.component.html',
  styles: []
})
export class SelectItemsByPageComponent implements OnInit {

  numberResults: number;
  currentPage: number;
  numberOfMessageByPage: Array<string> = ['10', '20', '50', '100'];
  //private _defaultNumberOfMessageByPage: Array<string> = ['10', '20', '50', '100'];
  numberOfPages: number;
  public pageSize:number;
  public dataLoaded:boolean;

  get arrayOfItems(){
    return Array(this.numberOfPages).fill(1);
  };

  constructor(private actionsService:ActionsService, private stateFn: StateFn) {
    this.dataLoaded = false;
   }

  ngOnInit() {
    this.stateFn.register(state=>{
      this.pageSize=this.stateFn.getSearchResultPageSize();
      this.numberResults=this.stateFn.getNumberOfSearchResults();
      this.currentPage=this.stateFn.getCurrentPageNumber();
      this.numberOfPages=this.stateFn.getNumberOfSearchResultPages();
      if( state.searchState.searchResults !=null) this.dataLoaded = true;
    });
  }


  public selected(value: string): void {
    this.actionsService.changePageSize(Number(value));
  }
/*
  public defaultPageSize() {
    if (this.numberOfMessageByPage.indexOf(this.pageSize.toString()) === -1) {
      return [this.numberOfMessageByPage[0]];
    } else {
      return [this.numberOfMessageByPage[this.numberOfMessageByPage.indexOf(this.pageSize.toString())]];
    }

  }*/

}
