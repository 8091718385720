import { Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NGXLogger} from "ngx-logger";

import {ConfigurationService} from "@smals/ngx-configuration-service";
import {StateFn} from "../../store/StateFn";
import {UserPartitionForm} from "../interfaces/userPartitionForm";
import {PacmanConstants} from "../pacman.constants";
import {uniformizeCbeNumber} from "../../models/enterprise-user";
import {PacmanActionService} from "../services/pacmanAction.service";
import {PacmanActionStore} from "../store/pacmanActionStore";
import { StatusForms} from "../../store/store-data-interface";
import {FormsHelper} from "../../helper/Forms-helper";
import { LoadingService } from '../../services';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styles: [
  ]
})
export class DeleteUserComponent implements OnInit {


  statusSendingForm: StatusForms;
  StatusFormsEnum: any;

  @Input() userPartitionForm:UserPartitionForm  ;
  @Input() isMobile: boolean;


  constructor(public activeModal: NgbActiveModal, private logger:NGXLogger, private _pacmanActionService:PacmanActionService, private _pacmanActionsStore:PacmanActionStore, private _configService:ConfigurationService, private _stateFn:StateFn, private _loadingService:LoadingService) {


    this.StatusFormsEnum = StatusForms;
  }
  ngOnInit(): void {

  }

  deleteUser(){
    this._loadingService.start({serviceRunStart:"delete user from partition",delayedStart: false});
    this.logger.debug("delete " + JSON.stringify(this.userPartitionForm));
    const url = this._configService.getEnvironmentVariable("urlPacmanAdmin") + "/" +
      PacmanConstants.userPartitionRights
        .replace("{eboxId}", uniformizeCbeNumber(this._stateFn.getUser().cbeNumber))
        .replace("{partitionId}", this.userPartitionForm.partitionId) +
      "/"+this.userPartitionForm.user.id;
    this._pacmanActionService.deleteUserToPacman(url).then( () => {
      this._pacmanActionsStore.updateStatusDeleteUserForms(StatusForms.success, this.userPartitionForm);


      this.closeAndResetModal(StatusForms.success);
      }

    ).catch(error => {
      const handlingsError = FormsHelper.handlePacmanErrorForms(error, this.statusSendingForm);

      if (handlingsError.status == StatusForms.failed) {
        this._pacmanActionsStore.updateStatusDeleteUserForms(StatusForms.failed);
        this.closeAndResetModal(StatusForms.failed);

      } else {
        this._pacmanActionsStore.updateStatusDeleteUserForms(StatusForms.error);
      }
    }).finally(()=>this._loadingService.stop("Stop delete"));

  }

  closeAndResetModal(statusForm?:StatusForms){
    this._pacmanActionsStore.clearPacmanStatusForms();
    if(statusForm !=undefined){
      this.activeModal.close({"statusForm" : statusForm});
    }else{
      this.activeModal.dismiss();
    }


  }

}
