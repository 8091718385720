import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '@smals/ngx-configuration-service';
import { CacheStorageService } from '../services/cacheStorage.service';
import { ActionsStore } from '../store/actions-store';
import { StateFn } from '../store/StateFn';

@Component({
  selector: 'app-mock-login',
  templateUrl: './mock-login.component.html',
  styles: []
})
export class MockLoginComponent implements OnInit {

  constructor(private _configService: ConfigurationService, private _actionsStore: ActionsStore, private stateFn: StateFn, private _cacheStorage_service: CacheStorageService) {
  }

  ngOnInit() {
    if (this._configService.getEnvironment() !== 'local' && !this._configService.getEnvironment().startsWith('test')) {
      sessionStorage.clear();

      this._cacheStorage_service.clearLocalStorageAndKeepOptin(this.stateFn.getUser()?.cbeNumber);
      this._actionsStore.removeAlert('error');
      window.location.href = this._configService.getEnvironmentVariable('end_session_endpoint') + '?target=' + this._configService.getEnvironmentVariable('urlWebsiteInfo') + '/' + this.stateFn.getLanguage() + '/index.html';
    }
  }

}
