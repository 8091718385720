import { Component, ElementRef, OnDestroy, OnInit,  ViewChildren, QueryList } from '@angular/core';

import { Router } from '@angular/router';
import { MessageData } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';
import { ConfigurationService } from '@smals/ngx-configuration-service';
import {  Subscription } from 'rxjs';
import { ActionsService    } from '../../services';
import { ActionsStore } from '../../store/actions-store';
import { StateFn } from '../../store/StateFn';
import { StoreService } from '../../store/store.service';
import { MessageListUrlParameters } from '../messages-from-ebox/MessageListUrlManagement';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styles: []
})
export class MessagesComponent implements OnInit, OnDestroy {

  public isMobile: boolean;
  private _messagesSelectedDownload: string[] = [];

  private subscriptions: Subscription[] = [];
  public allMessages: MessageData[] = null;
  public loadingMessage: boolean;




  @ViewChildren('checkboxOperation') messageCheckboxes: QueryList<ElementRef>;

  numberOfPage: number;
  public pageSize: number;
  public folder: string;



  get actionService() {
    return this._actionsService;
  }


  constructor(
    private _router: Router,
    private _configService: ConfigurationService,
    private stateFn: StateFn,
    private storeService: StoreService,
    private _actionsService: ActionsService, private _actionsStore: ActionsStore

  ) {
    this.folder = this.stateFn.convertFolderToStr(this.stateFn.getCurrentFolder());
   // if (stateFn.getAllReferencesData() == null) this._actionsService.initAllReferenceData();
    this.loadingMessage = false;
  }

  ngOnInit() {


 this.storeService.store.subscribe(state => {
      this.folder = this.stateFn.convertFolderToStr(this.stateFn.getCurrentFolder());
      this.isMobile = state.isMobile;
      this.pageSize = this.stateFn.getSearchResultPageSize();

      this.messagesSelectedDownload = state.userActions.messagesSelected;
      if (this.messageCheckboxes != undefined) {
        this.messageCheckboxes.forEach((checkboxElmt) => {
          const checkbox: HTMLInputElement = checkboxElmt.nativeElement;
          checkbox.checked = this.isMessageSelected(checkbox.value);

        }
        )
      }

      /**
       * Listen to search results
       */

      if (state.searchState.searchResults != null) {
        this.allMessages = this.stateFn.getSearchResult().items;
        //logger.log(this.allMessages);
        this.loadingMessage = true;
      }
      else {
        this.allMessages = [];
        this.loadingMessage = false;
      }
    });


  }


  isMessageSelected(refMessage:string){
    return (this.messagesSelectedDownload.indexOf(refMessage) !=-1)

  }
  selectMessages(checkboxMessage: HTMLInputElement, type: string, allDefaultValue?: boolean) {
    if (type === 'one') {
      const index: number = this.messagesSelectedDownload.indexOf(checkboxMessage.value);
      if (index !== -1) {
        this.messagesSelectedDownload.splice(index, 1);
//        checkboxMessage.checked = false;
      } else {
        this.messagesSelectedDownload.push(checkboxMessage.value);
  //      checkboxMessage.checked = true;
      }
    }
    this.actionService.filterSelectedMessages([{value:checkboxMessage.value,status:checkboxMessage.checked}]);
  }

  public getMessage(message: MessageData): void {
    const queryParamsFromState: MessageListUrlParameters = MessageListUrlParameters.fromState(this.storeService.store.getValue(), this._actionsStore);
    let navigationParams = { idMessage: message.messageId, idProviderRegistry: message.registryId, f:queryParamsFromState.f };

    if ( message.recipient != null ){
      this._router.navigate(['/detail'], { queryParams: { idMessage: message.messageId, idProviderRegistry: message.registryId, } });
      navigationParams = Object.assign(navigationParams, { idRecipient: message.recipient.value, idSender: message.sender.companyId});

  }

    this._router.navigate(['/detail'], {queryParams: {...navigationParams}});

    //queryParams.f
    }


  ngOnDestroy() {
    this.subscriptions.forEach(s => {
    //  s.unsubscribe();
    });
  }


  public get language() {
    return this.stateFn.getLanguage();
  }

  public get numberOfSearchResult(): number {
    return this.storeService.store.getValue().searchState.searchResults.totalItems;
  }

  public get messagesSelectedDownload(): string[] {
    return this._messagesSelectedDownload;
  }

  public set messagesSelectedDownload(value: string[]) {
    this._messagesSelectedDownload = value;
  }

  public get allReferencesData() {
    return this.stateFn.getAllReferencesData();
  }

  public get backendUrl() {
    return this._configService.getEnvironmentVariable('urlBackendEnterpriseRest');
  }

}
