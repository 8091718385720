<div class="searchElements" data-testid="search-element">
    <header class="componentList " *ngIf='searchIsActive'>
        <h2 class="visually-hidden">{{'search.title' | translate }}</h2>
        <p><a [routerLink]="'/messages'" class="returnLink">{{"search.return" |
                translate }}</a></p>
    </header>
    <main *ngIf=" !isMobile">
        <div class="container-fluid">
            <div class="row">
                <app-search-bar class="ps-0 col-10 col-md-6" *ngIf="!isMobile"
                    [isMobile]="isMobile"
                    (searchTermsChanged)="searchTerms=$event"
                    (openSearchFilters)="filterSelectorIsActive=$event"
                    (submitSearchWithFilters)="searchWithFilters()">
                </app-search-bar>
            </div>
            <div class="row" *ngIf='filterSelectorIsActive'>
                <div class="searchResult w-100" data-testid="search-result">
                    <p *ngIf='isMobile' role="status"><strong>{{
                            (numberOfSearchResult === 0 ?'search.paginator.resultFound.none':(
                            numberOfSearchResult ==1 ?
                            'search.paginator.resultFound.one' :'search.paginator.resultFound.many'))
                            | translate:{value1:numberOfSearchResult} }}</strong></p>
                    <button
                        *ngIf="isMobile && !hasFiltersSelected && numberOfSearchResult > 1"
                        class="btn btn-primary"
                        (click)="modifyParamFromFilter()">{{'header.search-bar.forms.filterForms.action.submit'
                        | translate}}</button>
                    <div *ngIf="!isMobile" class="{{!filterSelectorIsActive && getFilterParamsLength()>0 ? 'col' :''}} p-0 filterElements d-inline-block w-100">
                        <div id="filterElementsAvailable">
                            <app-search-filter-bar
                                [searchTerms]="searchTerms"
                            (filtersReset)="searchBar.clickCloseSearchMobile()">
                            </app-search-filter-bar>
                        </div>
                    </div>

                    <div *ngIf="getFilterParamsLength()>0"
                        class="filterResult {{isMobile?'':'col-auto d-inline-block'}}"
                        data-testid="filter-params">
                        <h3 *ngIf="isMobile">{{'search.filter.title' | translate
                            }} <span class="badge badge-pill bg-primary">{{getFilterParamsLength()}}</span>
                            <a href="javascript:void(0)"
                                (click)="modifyParamFromFilter()">{{'search.filter.modify'
                                | translate }}</a>
                        </h3>

                    </div>
                </div>
            </div>
        </div>

    </main>
</div>
