import { Directive,  OnInit, ElementRef,  Renderer2 } from '@angular/core';


@Directive({
  selector: '[appFocusInputGroup]'
})
export class FocusInputGroupDirective implements OnInit {



  constructor(private el: ElementRef, private render: Renderer2) { }

  ngOnInit(): void {


    this.render.listen(this.el.nativeElement, 'focus', (event) => { this.setShadow(event); });
    this.render.listen(this.el.nativeElement, 'blur', (event) => { this.setShadow(event); });

  }

  setShadow(event: Event) {

    const element: HTMLElement = (event.target || event.srcElement) as HTMLElement;
    let parentNode: HTMLElement = <HTMLElement>element.parentNode;
    if (element.attributes.getNamedItem('data-parent-class')) {
      const className: string = element.attributes.getNamedItem('data-parent-class').nodeValue;
      while (!parentNode.classList.contains(className)) {
        parentNode = <HTMLElement>parentNode.parentNode;
      }
    }

    parentNode.classList.toggle('focused');

  }

}
