<ng-container *ngIf="message !=null">
  <app-summary class="card-body pb-1" [message]="message"></app-summary>

  <app-attachments class="card-footer me-0 me-sm-3 me-md-0"
    *ngIf="(message.attachments !=null && message.attachments.length > 0) || message.hasBody"
    [attachments]="message.attachments" [registryId]="message.registryId"
    [onlyMaincontent]="true" [hasBody]="message.hasBody"
    [bodyMainContent]="message.bodyMainContent"
    [numberOfMainContent]="numbersOfMainContent()" [message]="message"></app-attachments>

  <ng-container *ngIf="messageWithActionRequired !== undefined">
    <strong
      [class]="messageWithActionRequired.executed ? 'text-primary' : 'text-blue'">
      <ng-container *ngIf="messageWithActionRequired.executed">
        {{('messages.actionRequired.executed' | translate: {value:
        (messageWithActionRequired.lastExecutionDate | date:'dd/MM/yyyy')})}}
      </ng-container>
      <ng-container *ngIf="! (messageWithActionRequired.executed)">
        {{messageWithActionRequired.executionDueDate === null ? ('messages.actionRequired.notExecutedNoDueDate'
        | translate) : ('messages.actionRequired.notExecuted' | translate:
        {value: (messageWithActionRequired.executionDueDate | date:'dd/MM/yyyy')})}}
      </ng-container>

    </strong>
  </ng-container>
</ng-container>
