import { Directive, Input, OnInit, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ApplicationConstants } from '../app.constants';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from "ngx-logger";
import {DateHelper} from '../helper/DateHelper';



@Directive({
  selector: '[appExpiredDateFC]',
})

export class ExpiredDateFCDirective implements OnInit {

private _appExpiredDateFC: { 'publicationDate': Date, 'expirationDate': Date, 'messageId': string, 'mobile': boolean };

  //tslint:disable-next-line:no-input-rename
  @Input() 
  set appExpiredDateFC (value:any){
    this._appExpiredDateFC = JSON.parse(value);
  }

  get appExpiredDateFC():any{
    return this._appExpiredDateFC;
  }
  //private _language: string;

  @Input()
  set refresh(value: string) {
    //this._language = value;
    this.createExpirateTooltip();
  }


  constructor(private logger: NGXLogger,private el: ElementRef, private datePipe: DatePipe,
    private _translateService: TranslateService, private _dateHelper:DateHelper ) {


  }

  createExpirateTooltip() {
    if(this.appExpiredDateFC != undefined){
      const htmlElement: HTMLElement = <HTMLElement>this.el.nativeElement;
      const parentElement: HTMLElement = (<HTMLElement>htmlElement.parentNode);
  
      htmlElement.id = 'dateElmtMsg_' + this.appExpiredDateFC.messageId;
      const expirationDate = this.datePipe.transform(
        this.appExpiredDateFC.expirationDate, ApplicationConstants.DATEPATTERN);
        const publicationDate = this.datePipe.transform(
        this.appExpiredDateFC.publicationDate, ApplicationConstants.DATEPATTERN);
      const appExpirationString: string[] = expirationDate.split('/');
      const appPublicationString: string[] = publicationDate.split('/');
  
      if (this._dateHelper.dateIsExpired(
        new Date(Number(appExpirationString[2]), Number(appExpirationString[1]) - 1, Number(appExpirationString[0])))
      ) {
  
        parentElement.className += ' expiredDate';
  
        const srSpanElementTranslation: string = this._translateService.instant('messages.expiredDate.name') +
          appExpirationString[0] + '/' + appExpirationString[1] + '/' + appExpirationString[2];
        const iElement: HTMLElement = document.createElement('i');
        iElement.className = 'far fa-clock';
        iElement.setAttribute('aria-hidden', 'true');
        htmlElement.innerHTML ="<span class='visually-hidden'>"+( (this.appExpiredDateFC.mobile) ?
          (appExpirationString[0] + '/' + appExpirationString[1] + '/' + appExpirationString[2].substr(2, 2)) :
          (appPublicationString[0] + '/' + appPublicationString[1] + '/' + appPublicationString[2].substr(2, 2) + ' ')) +"</span>";
        htmlElement.setAttribute('aria-label', srSpanElementTranslation);
        // htmlElement.setAttribute('title', srSpanElementTranslation);
        /*if (this.expiredDateFC.mobile) {
          parentElement.insertBefore(iElement, parentElement.firstChild);
          //parentElement.appendChild(iElement);
        } else {*/
          //htmlElement.appendChild(iElement);
          htmlElement.insertBefore(iElement, htmlElement.firstChild);
       // }
        this.logger.debug('srSpanElementTranslation', srSpanElementTranslation);
      }
      parentElement.setAttribute('aria-describedby', 'dateElmtMsg_' + this.appExpiredDateFC.messageId);
  
    }
    
  }

  ngOnInit(): void {
    // this.createExpirateTooltip();
  }

}

