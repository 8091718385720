import { Injectable } from "@angular/core";
import { AuthResult,  } from "angular-auth-oidc-client/lib/flows/callback-context";

export interface OpenIdEnvironmentConfig {
	autoUserInfo: boolean;
	post_logout_redirect_uri: string;
	stsServer: string;
	log_console_debug_active: boolean;
	wellKnownEndPoints: WellKnownEndpointsConfig;
	responseType: string;
}

export interface WellKnownEndpointsConfig {
	/**
	 * An optional moose endpoint. This endpoint will be chosen if it exists and if WALI is not forced.
	 */
	authorization_mock_endpoint?: string;
	introspection_endpoint: string;
	issuer: string;
	jwks_uri: string;
	authorization_endpoint: string;
	token_endpoint: string;
	userinfo_endpoint: string;
}

export interface LoginParameterService {
	"login.language"?: string,
	"login.type"?: string,
	"prior.localisation"?: string,
	"login.context"?: string
}

export interface AuthResultSocSec extends AuthResult {
	scope?: string,
	expires_in: number

}

@Injectable()
export class AuthorizationEndpointParameterService {
	private _authorizationEndpoint: LoginParameterService;

	constructor() {

	}

	set authorizationEndpoint(authorizationEndpoint: LoginParameterService) {
		this._authorizationEndpoint = authorizationEndpoint;
	}
	get authorizationEndpoint(): {} {
		return this._authorizationEndpoint;
	}

}