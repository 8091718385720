import { Translated } from "../../interfaces/translated";
import { UserType } from "./enum/userType";
import { UserPartition } from "./userPartition";


export interface Partition {
  id: string,
  name: Translated,
  users?: UserPartition[],
  nbHumanUsers?: number,
  nbApplicationUsers?: number,
  hasAdminRight: boolean;
  hasAccess: boolean

}


export function calculateUsers(partitions: Partition[]): Partition[] {

  partitions.forEach(partition => {
    let humanCount = 0;
    if (partition.users != undefined) {
      partition.users.forEach(
        item => {
          if (item.type == UserType.HUMAN) {
            humanCount++;
          }
        }
      );
      partition.nbHumanUsers = humanCount;
      partition.nbApplicationUsers = partition.users.length - humanCount;
    } else {
      partition.nbHumanUsers = 0;
      partition.nbApplicationUsers = 0;

    }



  });
  return partitions;

}

