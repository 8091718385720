<!--<pre class="col-sm-10 offset-sm-2 pt-2 pb-2"> is user loaded {{isUserLoaded}} is isConsultMessage {{isConsultMessage}} resultform != null {{resultForm!=null}} replyForm is success {{resultForm == StatusFormsEnum.success}} </pre>-->
<!-- *ngIf="isUserLoaded && isConsultMessage && resultForm!=null && resultForm.statusForms !=null && resultForm.statusForms==StatusFormsEnum.success" -->
<div
    *ngIf="isUserLoaded && isConsultMessage && resultForm!=null && resultForm.statusForms !=null && resultForm.statusForms==StatusFormsEnum.success"
    role="alert"
    class="alert alert-success {{isMobile ?'': 'w-100'}} text-body ms-sm-0 me-sm-0 pt-2 pb-2 border-0">
    <p *ngIf="resultForm.sender != null"
        class="mb-0 d-flex align-items-baseline"
        [innerHTML]="'form.status.success' | translate:{value1:resultForm.sender?.companyName}"></p>
    <p *ngIf="resultForm.partitionId != null"
        class="mb-0 d-flex align-items-baseline"
        [innerHTML]="'messages.actionsMessage.move.response.success.one' | translate:{value: (resultForm.partitionId | partitionNamePipe ) }"></p>

</div>

<app-search *ngIf="isUserLoaded && isConsultMessage  && isEboxLoaded"></app-search>

<app-actions class="paginationNav top"
    *ngIf="isUserLoaded && isConsultMessage && !isMobile  && isEboxLoaded"></app-actions>

<div data-testid='content'>

    <ng-container
        *ngIf="(configRegistriesLoaded!=null && configRegistriesLoaded.length !=0 && isEboxLoaded) || isInfoPage || errorLogin || isManagementPage">
        <router-outlet></router-outlet>

    </ng-container>
    <p
        *ngIf="configRegistriesLoaded!=null && configRegistriesLoaded.length ==0 && !isInfoPage && isConsultMessage"
        [innerHTML]="'search.paginator.resultFound.none' | translate"
        class="font-weight-bold text-center mt-4"></p>
</div>