import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HandleExceptionService } from '../error/handle-exception-service.service';
import { NGXLogger } from "ngx-logger";
import { StoreService } from '../store/store.service';
import { Observable, throwError } from "rxjs";
import {catchError} from "rxjs/operators";

export enum HttpMethod{
  GET,POST,OPTIONS,PUT,DELETE,PATCH
}

@Injectable()
export class WSHelper {



  private _exceptionService: HandleExceptionService;

	public get exceptionService(): HandleExceptionService {
		return this._exceptionService;
	}

	public set exceptionService(value: HandleExceptionService) {
		this._exceptionService = value;
  }

  constructor(private logger: NGXLogger,private http: HttpClient, private _handleException: HandleExceptionService, private storeService: StoreService) {
    this.exceptionService = this._handleException;
  }

  private handleHttpError(httpError: HttpErrorResponse) {
	     this.logger.warn('httpError', JSON.stringify(httpError));
    if (httpError.status !== 0) {
      this.exceptionService.handlerError(httpError);
    }

    return Promise.reject(httpError);
  }

  private checkTokenExpired(clientResponse: any): any {
    if (this.storeService.store.getValue().tokenIsExpired) {
      this._handleException.invalidatedToken();
      return Promise.reject("Token Expired");
    }
    return clientResponse;
  }

  private get<T>(url: string, params?: { [param: string]: string }):Promise<T>{
	  this.logger.debug("ws helper url " + url);
	  this.logger.debug("ws helper params "+params);
	  return this.http.get<T>(url, {
      headers: {
        'Authorization': 'Bearer ' + this.storeService.store.getValue().userSession.accessToken,
      },
      params: params,
    }).pipe(
      catchError((e) => throwError(e))
    ).toPromise()
      .catch(e => Promise.reject(e));
  }

  private patch<T>(url: string, params?: { [param: string]: string }, body?: any): Promise<T> {
    return this.http.patch<T>(url, body, {
      headers: {
        'Authorization': 'Bearer ' + this.storeService.store.getValue().userSession.accessToken,
        'Content-Type': 'application/merge-patch+json'
      },
      params: params
    }).pipe(catchError((e) => {
      const httpError: HttpErrorResponse = e as HttpErrorResponse;
      switch (httpError.status) {
        case 401 || 403 || 404: return this.handleHttpError(e);
        default: throw e;
      }


    })).toPromise().catch(e => Promise.reject(e));
  }

  getArrayBuffer(url: string, params?: { [param: string]: string }): Promise<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(url, {
      responseType: 'arraybuffer' as 'json',
      headers: {
        'Authorization': 'Bearer ' + this.storeService.store.getValue().userSession.accessToken,
      },
      params,
    }).toPromise()
      .catch(e => this.handleHttpError(e)).catch(e => Promise.reject(e));
  }

  /*post<T>(url: string, params?: Record<string, any>, body?: any): Promise<T> {
    return this.http.post<T>(url, body, {
      headers: {
        'Authorization': 'Bearer ' + this.storeService.store.getValue().userSession.accessToken,
        'Content-Type': 'application/json'
      },
      params: params
    }).toPromise()
      .catch(e => this.handleHttpError(e));
  }*/

  private post<T>(url: string, params?: Record<string, any>, body?: any, headers?:any): Promise<T> {
    let headersFix:{}= {
      'Authorization': 'Bearer ' + this.storeService.store.getValue().userSession.accessToken,
     // 'Content-Type':'application/json'
    }
    if(headers !=undefined){
      headersFix = {...headersFix, ...headers};
    }
    return this.http.post<T>(url, body, {
      headers: headersFix,
      params: params
    }).pipe(catchError((e) => {
      const httpError: HttpErrorResponse = e as HttpErrorResponse;
      switch (httpError.status) {
        case 401 || 403 || 404: return this.handleHttpError(e);
        default: throw e;
      }


    })).toPromise().catch(e => Promise.reject(e));
  }

  private delete<T>(url: string, params?: { [param: string]: string }, body?: any): Promise<T> {

    return this.http.delete<T>(url,{
      headers: {
        'Authorization': 'Bearer ' + this.storeService.store.getValue().userSession.accessToken,
        'Content-Type':'application/json'
      },
      params: params
    }).pipe(catchError((e) => {
      const httpError: HttpErrorResponse = e as HttpErrorResponse;
      switch (httpError.status) {
        case 401 || 403 || 404: return this.handleHttpError(e);
        default: throw e;
      }


    })).toPromise().catch(e => Promise.reject(e));
  }

  public httpGet<T>(url: string, params?: Record<string, any>): Observable<HttpEvent<T>> {
    return this.http.get<T>(url,{
      observe: "response",
      headers: {
        'Authorization': 'Bearer ' + this.storeService.store.getValue().userSession.accessToken,
      },
      params: params,
    });
  }

  callWs<T>(url: string, httpMethod:HttpMethod, params?: Record<string, any>, body?: any, headers?:{}): Promise<T> {
    let response:Promise<T> ;
    try {

        switch (httpMethod) {
          case HttpMethod.GET: response= this.get(url,params); break;

          case HttpMethod.POST: response= this.post(url,params,body,headers);break;
          case HttpMethod.PATCH: response= this.patch(url,params,body);break;
          case HttpMethod.DELETE: response= this.delete(url,params,body);break;
          default: response = Promise.reject('HttpMethod not implemented.');
        }
        response = response.then(results => this.checkTokenExpired(results));

  }
  catch(error){
    this.logger.error("Error Call ws "+ error);
    Promise.reject('callWs: ' + error);
  }finally{
    this.logger.debug(" response ", response);
    return response;
  }
}}
