import { Injectable } from '@angular/core';
import { NGXLogger } from "ngx-logger";
import { OptinStorage } from "../store/store-data-interface";
import { DatePipe } from "@angular/common";
import { ConfigurationService } from "@smals/ngx-configuration-service";


@Injectable({
  providedIn: 'root'
})
export class CacheStorageService {

  private STORAGE_KEY = "ebox_";

  constructor(private logger: NGXLogger, public datepipe: DatePipe, private _configService: ConfigurationService) {

  }

  setStorage(key: string, value: string) {
    if (localStorage.getItem(key) != null) {
      localStorage.removeItem(key);
    }
    localStorage.setItem(key, value);
  }

  getStorage<T>(key: string) {
    return localStorage.getItem(key) != null ? JSON.parse(localStorage.getItem(key)) as T : undefined;
  }

  setToLocaleStorage(cbeNumber: string, value: {}) {
    this.logger.info("inserting to the cache");
    this.setStorage(this.STORAGE_KEY + cbeNumber, JSON.stringify(value));
  }


  isDueDateReminder(date: Date): boolean {
    if (date != null) {
      date.setDate(date.getDate() + this._configService.getEnvironmentVariable('remindDate'));
      return date.getTime() < new Date().getTime();
    } else {
      return true;
    }

  }

  clearLocalStorageAndKeepOptin(cbeNumber: string) {
    const savedLocalStorage = this.getStorage<OptinStorage>(this.STORAGE_KEY + cbeNumber);
    localStorage.clear();
    if (savedLocalStorage != undefined) {
      this.setToLocaleStorage(cbeNumber, savedLocalStorage);
    }
  }


}
