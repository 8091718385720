import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MessageData, Sender } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';
import { Subscription } from 'rxjs';
import { ResponsiveService } from '../../../services';
import { StateFn } from '../../../store/StateFn';
import { EnterpriseUser } from '../../../models/enterprise-user';
import { ApplicationConstants } from '../../../app.constants';
import { DateHelper } from '../../../helper/DateHelper';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styles: []
})
export class SummaryComponent implements OnInit, OnDestroy {

  dateIsExpired;

  @Input()
  message: MessageData;

  isMobile: boolean;
  folder: String;

  private subscriptions: Subscription[] = [];
  private _enterpriseUser: any;

  public senderTitle: string;
  public senderLabel: string;
  public recipientTitle: string;
  public recipientLabel: string;



  constructor(private responsiveService: ResponsiveService, private stateFn: StateFn, public appConstant: ApplicationConstants, private _dateHelper: DateHelper, private translateService: TranslateService) {
    this.dateIsExpired = this._dateHelper.dateIsExpired;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this.responsiveService.checkWidth();
    this.subscriptions.push(this.stateFn.register(s => {
      this.isMobile = this.stateFn.isMobile();
      this._enterpriseUser = s.userSession.user;
    }));
    this.senderLabel = this.getLabel(this.message.sender);
    this.senderTitle = this.getLabel(this.message.sender);
    this.recipientLabel = this.getLabel(this.message.recipient);
    this.recipientTitle = this.getLabel(this.message.recipient);
    this.folder = this.stateFn.convertFolderToStr(this.stateFn.getCurrentFolder());
  }

  public get windowWidth(): number {
    return this.responsiveService.innerWidth;
  }

  public get enterpriseUser(): EnterpriseUser {
    return this._enterpriseUser;
  }

  public set enterpriseUser(value: EnterpriseUser) {
    this._enterpriseUser = value;
  }

  public get showPartitionName(): boolean {
    return this.stateFn.getMyPartitionsWithAccess().length > 1 && this.appConstant.isNullOrUndefined(this.stateFn.getMessageFilters().recipientPartition);
  }

  getLabel(sender: Sender): string {
    if (sender.type == 'citizen') {
      return sender.value;
    } else if (sender.value != this.enterpriseUser.cbeNumber) {
      if (this.appConstant.isNullOrUndefined(sender.companyName)) {
        return this.translateService.instant('detail-of-message.enterpriseNumber',{value1: sender.companyId});
      }
      return sender.companyName;
    }
    return this.translateService.instant('detail-of-message.myEnterprise');
  }

}
