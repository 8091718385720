<footer [hidden]="true">
    <div class="container-fluid container-xxxxxl">
        <div class="row">
            <ul class="list-inline col-xs-12">
                <li class="col-xs-3 pull-right text-end">&copy; 2020
                    Enterprise-Rest</li>
                <li class>{{version}} -> {{channel}}</li>
                <li class>{{releaseTime | date:'dd/MM/yyyy HH:mm:ss'}}</li>
            </ul>
        </div>
    </div>
</footer>
