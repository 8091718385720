import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ActionsService, SetPositionFixedService } from '../../services';
import { Alert } from '../../interfaces';
import { ConfigurationService } from '@smals/ngx-configuration-service';

import { Subscription } from 'rxjs';

import { StateFn } from "../../store/StateFn";
import { CommonException, ProviderException } from '../../error/CommonException';
import { ActionsStore } from '../../store/actions-store';
import { StoreService } from '../../store/store.service';
import { NGXLogger } from 'ngx-logger';
import { Operation } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData/Operation';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styles: []
})
export class CommunicationComponent implements OnInit, OnDestroy {

  @Input()
  isMobile: boolean;

  private _isCollapsed = true;

  get isCollapsed() {
    return this._isCollapsed;
  }
  set isCollapsed(param: boolean) {

    this._isCollapsed = param;
    this.adjustSizeMobile(param);

  }

  private _isDpCollapsed = true;
  get isDpCollapsed() {
    return this._isDpCollapsed;
  }
  set isDpCollapsed(param: boolean) {
    this._isDpCollapsed = param;
    this.adjustSizeMobile(param);
  }

  private adjustSizeMobile(param) {
    if (this.fixedAlert != undefined && this.isMobile) {
      const heightBefore = this.fixedAlert.nativeElement.getBoundingClientRect().height;
      setTimeout(() => {
        const heightAfter = this.fixedAlert.nativeElement.getBoundingClientRect().height;
        //logger.warn("height Before %s, height after %s", heightBefore, heightAfter);
        const changePosition = heightAfter - heightBefore;
        //if(heightBefore > heightAfter)
        this.positionFixedService.updateElement("fixedLeftnav", (param ? 0 : changePosition));
        this.positionFixedService.refreshPositioning();
        //logger.warn("collapse %s value "+(!param? 'add':'remove')+" %s", param, changePosition);

        //this.updatePositionLeftnav();
      }, 10);
    }
  }

  private _typeAlertVisible: string[] = [];
  private subscriptions: Subscription[];
  private _alerts: Record<string, string | Alert[] | CommonException | CommonException[]>;
  public webSiteInfo: string;
  public isConsultMessagePage: boolean;
  public hasError: boolean;
  public hasInfo: boolean;

  @Input()
  set typeAlertVisible(paramsAlert: string) {
    this._typeAlertVisible = paramsAlert.split(',');
  }

  @ViewChild('fixedAlert') fixedAlert: ElementRef;

  @ViewChild('toggleCollapse') toggleCollapse: ElementRef;
  @ViewChild('alertCollapse') alertCollapse: ElementRef;
  @ViewChild('contentCollapse') contentCollapse: ElementRef;

  @ViewChild('toggleDpCollapse') toggleDpCollapse: ElementRef;
  @ViewChild('contentDpCollapse') contentDpCollapse: ElementRef;


  @HostListener('document:click', ['$event'])
  closeCollapse(event) {

    if (this.stateFn.isMobile()) {
      if (this.toggleCollapse !== undefined && this.alertCollapse !== undefined && this.toggleCollapse.nativeElement !== undefined && this.alertCollapse.nativeElement !== undefined) {

        if (!this.toggleCollapse.nativeElement.contains(event.target) || !this.alertCollapse.nativeElement.contains(event.target)) {
          this.isCollapsed = true;
        }
      }
    } else {
      if (this.alertCollapse !== undefined && this.alertCollapse.nativeElement !== undefined) {

        if (!this.alertCollapse.nativeElement.contains(event.target)) {
          this.isCollapsed = true;
        }
      }
    }

  }

  @HostListener('document:keyup', ['$event']) handleKeyUp(event) {
    if (event.keyCode === 27) {
      if (this.toggleCollapse !== undefined && this.alertCollapse !== undefined && !this.isCollapsed) {
        this.isCollapsed = true;

      }
    }
  }


  constructor(private _changeDetector: ChangeDetectorRef, private router: Router, private _configService: ConfigurationService,
    private actionsService: ActionsService, private _actionsStore: ActionsStore,
    private storeService: StoreService, private _router: Router, private stateFn: StateFn, private positionFixedService: SetPositionFixedService, private logger: NGXLogger) {
    this.isCollapsed = true;
    this.alerts = {};
    this.subscriptions = [];
    this.webSiteInfo = this._configService.getEnvironmentVariable('urlWebsiteInfo');
    this.isConsultMessagePage = false;
    this.hasError = false;
    this.hasInfo = false;
  }



  ngOnInit() {
    let resetInitializeAlertIsAllCompleted = false;

    this.subscriptions.push(this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.url.substr(0, '/reset'.length) === '/reset') {
        if (!resetInitializeAlertIsAllCompleted) {
          this.actionsService.initializeAlertMessage();
        } else {
          resetInitializeAlertIsAllCompleted = false;
        }
      }
    }));
    if (!resetInitializeAlertIsAllCompleted) {
      this.actionsService.initializeAlertMessage();
      resetInitializeAlertIsAllCompleted = true;
    }


    this.storeService.store.subscribe(s => {

      this.isConsultMessagePage = this.stateFn.isCurrentPage("messages");
      // logger.warn("nbAlerts %s ", this.sizeAlerts);
      /*
      move to actionStore when exclusivelyEbox is changed
      if (this.stateFn.isAccessTokenValid() && s.userSession.user !== null && s.userSession.user.exclusivelyEbox !== null) {
         if (s.userSession.user.exclusivelyEbox) {
           this._actionsStore.removeAlert('warning', 'management');
         } else {
           this._actionsStore.addAlert('warning', { nameOfResult: 'management' });
         }
       }*/
      this.alerts = s.alerts;
      // console.error("TypeAlerVisible %s, alertError %s, list alerts %s", this.getTypeAlertVisible().indexOf('error') !== -1, this.alerts['error'] !== undefined, JSON.stringify(this.alerts))
      this.hasError = this.getTypeAlertVisible().indexOf('error') !== -1 && this.alerts['error'] !== undefined;
      if (this.hasError) {
        this.logger.warn('alertError %s', JSON.stringify(this.alerts['error']));
      }
      if (this.sizeAlerts > 0) {

        this.addPanelToFixed(this.isMobile);
      } else if (this.sizeAlerts == 0 && this.positionFixedService.hasElement('fixedAlert') != null) {
        this.positionFixedService.resetElement("fixedLeftnav");
        this.positionFixedService.removeElement('fixedAlert');
      }



    });



  }
  addPanelToFixed(isMobile) {
    //logger.warn('fixedAlert %s, isMobile %s, isAlert %s, isTrue %s', (this.fixedAlert != undefined), this.isMobile, (this.sizeAlerts !== 0), (this.fixedAlert != undefined && this.isMobile && this.sizeAlerts !== 0));

    if (this.fixedAlert == undefined && this.sizeAlerts !== 0) { setTimeout(() => { this.addPanelToFixed(isMobile) }, 1000); }

    if (this.fixedAlert != undefined && isMobile && this.sizeAlerts !== 0) {
      if (this.positionFixedService.hasElement('fixedAlert') == null) {
        this.positionFixedService.addElement(
          { idPanelToFixed: 'fixedAlert', elemntRef: this.fixedAlert, positionInit: this.fixedAlert.nativeElement.getBoundingClientRect().top }
        );
      }
      const height = this.fixedAlert.nativeElement.getBoundingClientRect().height;
      //logger.warn('height %s, leftNav %s', height, JSON.stringify(this.positionFixedService.hasElement("fixedLeftnav")));
      if (height != null) {
        setTimeout(() => { this.updatePositionLeftnav(); }, 10);
      }
    }
  }

  private updatePositionLeftnav() {

    const hasElement = this.positionFixedService.hasElement("fixedLeftnav");
    //logger.warn('position %s', hasElement.elemntRef.nativeElement.getBoundingClientRect().top)
    if (hasElement != null) {
      //logger.warn('position %s', hasElement.elemntRef.nativeElement.getBoundingClientRect().top)
      if (hasElement.positionInit != hasElement.elemntRef.nativeElement.getBoundingClientRect().top) {
        hasElement.positionInit = hasElement.elemntRef.nativeElement.getBoundingClientRect().top;
        this.positionFixedService.addElement(hasElement);
      }
      this.positionFixedService.refreshPositioning();
    }
  }

  getTypeAlertVisible() {
    return this._typeAlertVisible;
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  public navigateToFilter(filterAlert: string) {
    this.isCollapsed = true;
    // TODO make this simpler. One method for each kind of allert instead of this string based approach
    if (filterAlert == "expired") {
      this.actionsService.showExpiringMessages();
    }
    else if (filterAlert == "recommend") {
      this.actionsService.showRegisteredMessages();
    }
  }

  public navigateToOptin() {
    this.isCollapsed = true;
    this.router.navigate(['/management']);
  }

  public getErrors(): CommonException[] {
    if (this.hasError) {
      if (Array.isArray(this.alerts['error'])) {
        return this.alerts['error'] as CommonException[];
      } else {
        return new Array(this.alerts['error']) as CommonException[];
      }
    }
    return []
  }

  public getCommonError(): CommonException[] {
    return this.getErrors().filter((error: CommonException) => !(error instanceof ProviderException));
  }

  public getProviderError(): CommonException[] {
    return this.getErrors()
      .filter((error: CommonException) => error instanceof ProviderException);
  }

  public removeError() {
    const heightBefore = this.fixedAlert.nativeElement.getBoundingClientRect().height;
    this.hasError = false;
    this._changeDetector.detectChanges();
    if (this.isMobile) {

      setTimeout(() => {
        const heightAfter = this.fixedAlert.nativeElement.getBoundingClientRect().height;
        //logger.warn("height Before %s, height after %s", heightBefore, heightAfter);
        const changePosition = heightAfter - heightBefore;
        //if(heightBefore > heightAfter)
        this.positionFixedService.updateElement("fixedLeftnav", changePosition);
        this.positionFixedService.refreshPositioning();
        //logger.warn("collapse %s value  %s",changePosition);

        //this.updatePositionLeftnav();
      }, 10);
    }

    this._actionsStore.removeAlert('error');
  }
  /**
     * Getter alerts
     * @return {Map<string, string }
     */
  public get alerts(): Record<string, string | Alert[] | CommonException | CommonException[]> {
    return this._alerts;
  }

  public set alerts(value: Record<string, string | Alert[] | CommonException | CommonException[]>) {
    this._alerts = value;
  }

  public get sizeAlerts(): number {

    return Object.keys(this.alerts).length;
  }

  public get includeErrorWithProvider(): boolean {
    const errorType = this.getCommonError()[0].type;
    return errorType.includes(Operation.GET_MESSAGE) || errorType.includes(Operation.GET_DOCUMENT_CONTENT) || errorType.includes(Operation.MOVE_MESSAGES)  || errorType.includes("MOVE_BEFORE_DELETE");

  }



}
