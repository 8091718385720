import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {  VersionControlModule, VersionControlRefreshMode } from "@smals/ngx-version-control";
import { APP_VERSION } from "./app-version";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";


/**
 * Basically this is only exported because Angular7 AoT does not work with lambda or unexported
 * function. This should never be used outside of this class!!!!!
 * @param http
 */
export function becaueAngularAOTSucks_translateFN1(http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + APP_VERSION.version)
}

export function createTranslateLoader() {
  return [TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: becaueAngularAOTSucks_translateFN1,
      deps: [HttpClient]
    }
  })]
}


export function becaueAngularAOTSucks_versionControlFN1(){
  return {
    version: APP_VERSION,
    refreshMode: VersionControlRefreshMode.SNOOZE,
    maxTime: 10000,
    maxRequests: 10,
  }
}

export function versionControlConfigFactory() {
  return [VersionControlModule.forRoot(becaueAngularAOTSucks_versionControlFN1)];
}


