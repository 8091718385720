<p *ngIf="dataLoaded" class=" {{numberResults > 0 ? 'd-inline-block' : 'text-center'}}">
    <span role="status">
        <ng-container *ngIf="numberResults > 0">
        {{'search.paginator.resultByPage' | translate:{value1:(currentPage * pageSize - pageSize + 1 ), value2:( (currentPage * pageSize) > numberResults ? numberResults :(currentPage * pageSize)  ) } }}</ng-container>

        <strong>
            <ng-container *ngIf="numberResults !=0">
            {{ (numberResults ==1 ? 'search.paginator.resultFound.one' :'search.paginator.resultFound.many') | translate:{value1:numberResults} }}
            </ng-container>
        </strong>
    
    </span>
</p>

<div ngbDropdown class="d-inline-block" *ngIf="numberResults > 0">

    <button class="btn btn-outline-dropdown" id="numberElementsByPage" ngbDropdownToggle [disabled]="(numberOfMessageByPage.length === 1) ? true : null" data-testid="dropdown-current-pageSize">{{pageSize}}</button>
    <div ngbDropdownMenu aria-labelledby="numberElementsByPage" data-testid="dropdown-change-pageSize">
        <ng-container *ngFor="let numberPage of numberOfMessageByPage">
            <button class="dropdown-item" *ngIf="numberPage != pageSize" (click)="selected($event.target.innerText)">{{numberPage}}</button>
        </ng-container>

    </div>
</div>