import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import {
  SenderApplicationWithRegistryConfig,
  SenderOrganizationWithRegistryConfig
} from "@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData";
import { NgbActiveModal, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import { ActionsService } from "../../store/actions.service";
import { StateFn } from "../../store/StateFn";

import { MessageFilters } from "../../store/store-data-interface";

function toNgbDate(date: Date): NgbDate {
  return date == null ? null : new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
}

@Component({
  selector: 'app-search-filter-bar',
  templateUrl: './search-filter-bar.component.html',
  styles: []
})
export class SearchFilterBarComponent implements OnInit, OnDestroy {

  @Output() filtersReset = new EventEmitter<boolean>;
  @Input()
  filterIsCollapsed = false;

  @Input()
  searchTerms: string;

  publicationDateFrom: NgbDate = null;

  publicationDateTo: NgbDate = null;
  publicationDateFromInvalid = false;
  publicationDateToInvalid = false

  senderOrganizations: SenderOrganizationWithRegistryConfig[] = null;
  senderApplications: SenderApplicationWithRegistryConfig[] = null;

  isMobile: boolean;
  isDateConsecutive: boolean;

  includeRegisteredMessages = false
  includeExpiringMessages = false
  includeReadMessages = false
  includeUnreadMessages = false
  senderOrganizationSelection: string = null;
  senderApplicationSelection: string = null;

  searchBarValue: string;

  constructor(private actionService: ActionsService,
    private activeModal: NgbActiveModal,
    private stateFn: StateFn) { }

  ngOnInit(): void {
    this.stateFn.register(state => {
      const filters = state.messageFilters;
      this.includeExpiringMessages = filters.includeExpiringMessages;
      this.includeReadMessages = filters.includeReadMessages;
      this.includeUnreadMessages = filters.includeUnreadMessages;
      this.includeRegisteredMessages = filters.includeRegisteredMessages;
      this.publicationDateFrom = toNgbDate(filters.publicationDateFrom);
      this.publicationDateTo = toNgbDate(filters.publicationDateTo);
      this.senderOrganizationSelection = filters.senderOrganizationSelection;
      this.senderApplicationSelection = filters.senderApplicationSelection;
      this.isMobile = state.isMobile;
      this.isDateConsecutive = true;
      this.senderOrganizations = state.referencesDataState.senderOrganizationsState.sort(sortReferenceData(state.userSession.localeParameter));
      this.senderApplications = state.referencesDataState.senderApplicationsState.sort(sortReferenceData(state.userSession.localeParameter));
      this.searchTerms = this.stateFn.getMessageFilters().q;
    });
  }

  ngOnDestroy(): void {
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // TODO
    /*  if (!this.dpFromInput.isOpen() && !this.dpToInput.isOpen()) {
      this.escapeModal.emit(true);
    }*/
  }


  get showError(): boolean {
    return true;
  }

  get allReferencesData(): boolean {
    return this.senderOrganizations != null && this.senderApplications != null;
  };

  get formInvalid(): boolean {
    return this.publicationDateToInvalid || this.publicationDateFromInvalid;
  }



  private toDate(ngbDate: NgbDate) {
    if (ngbDate == null)
      return null
    else {
      const date = new Date();
      date.setFullYear(ngbDate.year, ngbDate.month - 1, ngbDate.day);
      return date;
    }
  }

  private toMessageFilters(): Partial<MessageFilters> {
    return {
      includeUnreadMessages: this.includeUnreadMessages,
      includeRegisteredMessages: this.includeRegisteredMessages,
      includeReadMessages: this.includeReadMessages,
      includeExpiringMessages: this.includeExpiringMessages,
      senderApplicationSelection: this.senderApplicationSelection == 'default' ? null : this.senderApplicationSelection,
      senderOrganizationSelection: this.senderOrganizationSelection == 'default' ? null : this.senderOrganizationSelection,
      publicationDateTo: this.toDate(this.publicationDateTo),
      publicationDateFrom: this.toDate(this.publicationDateFrom),
      recipientPartition: this.stateFn.getMessageFilters().recipientPartition,
      senderPartition: this.stateFn.getMessageFilters().senderPartition,
      q: this.searchTerms != "" ? this.searchTerms : null
    }
  }

  resetFilters() {
    this.includeReadMessages = false;
    this.includeExpiringMessages = false;
    this.includeRegisteredMessages = false;
    this.includeUnreadMessages = false;
    this.senderApplicationSelection = null;
    this.senderOrganizationSelection = null;
    this.publicationDateTo = null;
    this.publicationDateFrom = null;
    this.filterIsCollapsed = true;
    this.filtersReset.emit(true);
  }

  submitSearch() {
    if (!this.formInvalid) {
      const toMessageFilters = this.toMessageFilters();
      this.actionService.filterMessages(toMessageFilters);
    }

  }

  orgaId(org: SenderOrganizationWithRegistryConfig): string {
    return org.enterpriseNumber + "_rid_" + org.registryConfig.configRegistry.id;
  }

  appId(app: SenderApplicationWithRegistryConfig): string {
    return app.id + "_rid_" + app.registryConfig.configRegistry.id;
  }

  onChange_senderOrganizationSelection(event) {
    this.senderOrganizationSelection = event.target.value;
  }

  onChange_senderApplicationSelection(event) {
    this.senderApplicationSelection = event.target.value;
  }

  onChange_includeRegisteredMessages(event) {
    this.includeRegisteredMessages = event.target.checked;
  }

  onChange_includeExpiringMessages(event) {
    this.includeExpiringMessages = event.target.checked;
  }
  onChange_includeReadMessages(event) {
    this.includeReadMessages = event.target.checked;
  }
  onChange_includeUnreadMessages(event) {
    this.includeUnreadMessages = event.target.checked;
  }

  handleDateFieldChange(value: string): NgbDate {

    const date = moment(value, "DD/MM/YYYY");
    if (date.isValid()) {
      return new NgbDate(date.year(), date.month() + 1, date.date());
    }
    else {
      return null;
    }
  }

  dateSelect_publicationDateFrom(date: NgbDate) {
    this.publicationDateFromInvalid = false;

    if (this.publicationDateToInvalid && this.handleDateFieldChange(this.publicationDateToAsString) !== null) {
      this.publicationDateToInvalid = false;
    }

    this.publicationDateFrom = date;
    this.checkConsecutiveDates(date, Identifier.FROM);
  }

  onChange_publicationDateFrom(event) {
    const value = event.target.value;
    const v = this.handleDateFieldChange(value);
    if (v == null && value != "") {
      this.publicationDateFromInvalid = true;
    }
    else if (value == "") {
      this.publicationDateFromInvalid = false;
      this.publicationDateFrom = null;
    } else {
      this.publicationDateFrom = v;
      this.checkConsecutiveDates(v, Identifier.FROM);
    }
  }

  get publicationDateFromAsString() {
    if (this.publicationDateFrom == null)
      return null;
    else {
      const date = this.toDate(this.publicationDateFrom);
      return moment(date).format("DD/MM/YYYY");
    }
  }

  dateSelect_publicationDateTo(date: NgbDate) {
    this.publicationDateToInvalid = false;

    if (this.publicationDateFromInvalid && this.handleDateFieldChange(this.publicationDateFromAsString) !== null) {
      this.publicationDateFromInvalid = false;
    }

    this.publicationDateTo = date;
    this.checkConsecutiveDates(date, Identifier.TO);
  }

  onChange_publicationDateTo(event) {
    const value = event.target.value;
    const v = this.handleDateFieldChange(value);
    if (v == null && value != "") {
      this.publicationDateToInvalid = true;
    }
    else if (value == "") {
      this.publicationDateTo = null;
      this.publicationDateToInvalid = false;
    } else {
      this.publicationDateToInvalid = false;
      this.publicationDateTo = v;
      this.checkConsecutiveDates(v, Identifier.TO);
    }

  }

  get publicationDateToAsString() {
    if (this.publicationDateTo == null)
      return null;
    else {
      const date = this.toDate(this.publicationDateTo);
      return moment(date).format("DD/MM/YYYY");
    }
  }

  checkConsecutiveDates(date: NgbDate, identifier: Identifier): void {

    if (!date || !this.publicationDateFrom || !this.publicationDateTo) { return; }

    const dateFrom = this.toDate(this.publicationDateFrom).getTime();
    const dateTo = this.toDate(this.publicationDateTo).getTime();

    this.isDateConsecutive = dateFrom <= dateTo;

    if (identifier === Identifier.FROM) {
      this.publicationDateFromInvalid = !this.isDateConsecutive;
    } else if (identifier === Identifier.TO) {
      this.publicationDateToInvalid = !this.isDateConsecutive;
    }
  }


  closeModal() {
    // Note: This is a bit dirty but basically if this method is closed it means that the FilterBarModal is ok.
    this.activeModal.close();
  }

}

const enum Identifier {
  FROM = 1,
  TO = 2
}

export function sortReferenceData(language) {
  //const language: LocaleParameter = Object.values(LocaleParameter)[StateFn.getParametersClient().localeParameter];
  return function (a, b) {
    let aTranslation = '';
    let bTranslation = '';
    try {
      aTranslation = a.name[language];
      if(aTranslation == undefined) throw Error("No language Defined");
    } catch (error) {
      aTranslation= a['enterpriseNumber'] ?? a['id'];

    }

    try {
      bTranslation = b.name[language];
      if(bTranslation == undefined) throw Error("No language Defined");

    } catch (error) {
      bTranslation = b['enterpriseNumber'] ?? b['id'];


    }


    return aTranslation.localeCompare(bTranslation);
  };
}
