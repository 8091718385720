<form (ngSubmit)="onSearchSubmit('simple')" class="form-horizontal"
    role="search">
    <fieldset>
        <div class="row">
            <div class="col me-1 input-group">
                <input #searchTerms appFocusInputGroup class="form-control"
                    id="searchTerms" type="search"
                    placeholder="{{'header.search-bar.forms.searchTerms.placeholder'.concat(isMobile ?'.mobile':'.desktop') | translate}}"
                    [attr.aria-label]="'header.search-bar.forms.searchTerms.placeholder.desktop' | translate"
                    [attr.aria-describedby]="'button-search'"
                    data-testid="search-bar.input" [value]="currentValue"
                    autocomplete="off" (change)="onSearchTermsChange($event)"
                    (keyup)="onSearchTermsChange($event)" (keydown)="listenForSubmit($event);" />
                <label for="searchTerms" class="visually-hidden">{{'header.search-bar.forms.searchTerms.label'
                    | translate}} </label>
                <button *ngIf="!filtersOpen"  appFocusInputGroup data-parent-class="input-group"
                    data-testid="search-bar.submit"
                    class="btn  {{searchTerms.value.trim().length > 0 && !isMobile ? 'rounded-0' :'' }}"
                    type="submit" id="button-search"><i class="fas fa-search"
                        aria-hidden="true"
                        [attr.aria-label]="'header.search-bar.label' | translate"></i>
                    <span class="visually-hidden">{{'header.search-bar.label' |
                        translate}} </span>
                </button>
                <button type="reset" *ngIf="showResetButton || isMobile "
                    class="reset btn {{isMobile ? 'border-start': 'border-top border-end border-bottom'}}"
                    data-testid="search-bar.reset"
                    (click)="clickCloseSearchMobile()"> <i class="fas fa-times"
                        aria-hidden="true"
                        [attr.aria-label]=" 'header.search-bar.forms.searchTerms.remove' | translate"></i>
                    <span class="visually-hidden">{{'header.search-bar.forms.searchTerms.remove'
                        | translate}} </span>
                </button>
            </div>
            <div class="col ms-1"  *ngIf="!isMobile" >
                <a (click)="clickAdvanced(); $event.preventDefault();" class="text-underline" href="javascript:void(0)">{{filtersOpen ? ('header.search-bar.forms.searchTerms.simple' | translate) : ('header.search-bar.forms.searchTerms.advanced' | translate)}}</a>
            </div>
        </div>
    </fieldset>
</form>