export class HashHelper {

  static createHash(str: string): number {
    let hash = 0,
      chr;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr; //eslint-disable-line no-bitwise
      hash |= 0; //eslint-disable-line no-bitwise
    }
    return hash;
  }

}
