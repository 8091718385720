import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LoadingService, } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';


@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  animations: [
    // the fade-in/fade-out animation.
    trigger('fadeInOut', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate('2s')
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(1000, style({ opacity: 0 })))
    ])
  ]
})
export class LoadingComponent implements OnInit, OnDestroy {

  visible: boolean;
  firstLoading: boolean;
  elementDestroy;
  private _value: number;
  private interval: any;
  config: NgbProgressbarConfig;
  type: string;



  constructor(private _loadingService: LoadingService,
    public _translateService: TranslateService, _config: NgbProgressbarConfig, private cdRef: ChangeDetectorRef) {
    this.value = 100;
    this.config = _config;
    this.config.max = 100;
    this.config.striped = true;
    this.config.animated = true;
    this.type = 'info';
    this.visible = false;

  }

  ngOnInit() {
    // this.addTimer();
    //  let subscribe;
    this.firstLoading = this._loadingService.firstLoading;
    this.elementDestroy = this._loadingService.loadingEvent.subscribe(bool => {
      if (bool == this.visible) return;
      this.type = "info";
      this.config.showValue = false;
      this.visible = bool;
      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
      // logger.warn('loading must be visible realy %s', bool);
      if (bool) {
        const valuePercent = Math.floor(this._loadingService.loadingPercentProgress);
        if (valuePercent !== 100) {
          this.type = 'success';
          this.config.showValue = true;
          this.value = valuePercent;
          this.interval = setInterval(() => {
            this.value = Math.floor(this._loadingService.loadingPercentProgress);
          }, 1);
        }
      } else {
        if (this.interval) {
          clearInterval(this.interval);
          this._loadingService.loadingPercentProgress = 100;
          this.value = Math.floor(this._loadingService.loadingPercentProgress);

        }

      }

      this.firstLoading = this._loadingService.firstLoading;

    });
  }
  ngOnDestroy() {

    this.elementDestroy.unsubscribe();
  }

  public get value(): number {
    return this._value;
  }

  public set value(value: number) {
    this._value = value;
  }


}
