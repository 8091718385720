import { Injectable } from '@angular/core';
import { ActionsService } from "../store/actions.service";
import { StoreService } from '../store/store.service';


export interface ResponsiveListener {
  isMobile: boolean;
}

@Injectable()
export class ResponsiveService {

  public screenWidth: string;
  public innerWidth: number;
  UA = navigator.userAgent;

  constructor(
    private actionService: ActionsService,
    private storeService: StoreService
  ) {
    // this.checkWidth();
  }

  onMobileChange(status: boolean) {
    this.actionService.setMobileDevice(status);
  }

  isIosDevice() {
    return !!(this.UA.match(/iPad|iPhone/i));
  }

  public checkWidth() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 575) {
      this.screenWidth = 'xs';
      this.onMobileChange(true);
    } else if (this.innerWidth > 575 && this.innerWidth < 768) {
      this.screenWidth = 'sm';
      this.onMobileChange(false);
    } else if (this.innerWidth >= 768 && this.innerWidth < 992) {
      this.screenWidth = 'md';
      this.onMobileChange(false);
    } else {
      this.screenWidth = 'lg';
      this.onMobileChange(false);
    }
  }
  public getScreenWidth(){
    return this.screenWidth;
  }

  // TODO: This one is deprecated
  registerToChange(listener: ResponsiveListener) {
    listener.isMobile = this.storeService.store.getValue().isMobile;
    return this.storeService.store.subscribe(state => listener.isMobile = state.isMobile)
  }
  
}


