import { Component, Input, OnInit } from '@angular/core';
import {AttachmentData, MessageData} from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';
import {MessageDocumentManagement} from "../messages-from-ebox/DocumentManagement";
import {ActionsService} from "../../store/actions.service";
import { ActionsStore } from '../../store/actions-store';
import { StateFn } from '../../store/StateFn';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styles: []
})
export class AttachmentsComponent implements OnInit {

  @Input()
  attachments: AttachmentData[];

  @Input()
  onlyMaincontent: boolean;

  @Input()
  registryId: string;

  @Input()
  hasBody: boolean;

  @Input()
  bodyMainContent: boolean;

  @Input()
  numberOfMainContent : number;

  @Input()
  message: MessageData;

  numberofAnnex : number;



  constructor(private actionService: ActionsService,private _actionsStore: ActionsStore, private _stateFn:StateFn) {  }

  ngOnInit() {

        if(this.numberOfMainContent > 0){
          this.numberofAnnex = this.attachments.length - this.numberOfMainContent ;
        }else{

          this.numberofAnnex = this.attachments.length ;
        }

  }

  public getLinkToDocument(event: Event, attachment: AttachmentData): void {

    MessageDocumentManagement.getLinkToDocument(event, attachment, this.registryId, this.actionService);
    if (this._stateFn.isCurrentPage("messages")){
    this.message = this._actionsStore.updateStoreReadStatus(this.message);

    let parentNodeHtml: HTMLElement = <HTMLElement>(event.target || event.srcElement || event.currentTarget);
    while (parentNodeHtml.nodeName !== 'BODY' ) {
      
      parentNodeHtml = <HTMLElement>parentNodeHtml.parentNode;
      if (parentNodeHtml.classList.contains('unread')) {
        parentNodeHtml.classList.remove('unread');
     //   parentNodeHtml.classList.add('read');
      }
    }
    }


    event.stopPropagation();

  }

}
