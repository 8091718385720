import { Component, OnInit } from '@angular/core';
import { ActionsService, ResponsiveService } from "../../../services";
import { StoreService } from '../../../store/store.service';
import { StateFn } from '../../../store/StateFn';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styles: []
})
export class PaginationComponent implements OnInit {

  isMobile: boolean;
  pageSize: number;
  currentPage: number;
  positionPagination = "bottom";
  maxPage: number;

  constructor(private responsiveService: ResponsiveService,
    private actionsService: ActionsService, private storeService: StoreService, private stateFn: StateFn) { }

  ngOnInit() {
    this.responsiveService.registerToChange(this);
    this.storeService.store.subscribe(state => {
      this.pageSize = this.stateFn.getSearchResultPageSize();
      this.currentPage = this.stateFn.getCurrentPageNumber();
      this.maxPage = this.stateFn.getNumberOfSearchResultPages();
    });
  }

  nextPageClick(): void {
    this.actionsService.nextSearchResultPage();
  }

  prevPageClick(): void {
    this.actionsService.prevSearchResultPage();
  }

}
