<ng-container *ngIf="onlyMaincontent !=null && onlyMaincontent">
    <ul class="list-unstyled g-0">
        <ng-container *ngFor="let attachment of attachments">
            <li class="list-inline-item d-inline-flex"
                *ngIf="attachment.isPrincipalContent">
                <a href="javascript:void(0)"
                    (click)="getLinkToDocument($event,  attachment)"
                    class=" bg-white" [title]="attachment.fileName">
                    <app-attachment [attachment]="attachment"></app-attachment>
                </a>
            </li>
        </ng-container>
        <li class="list-inline-item d-inline-flex text-attachment"
            *ngIf="hasBody ||  numberofAnnex !== 0">
            <span class="ps-0 btn btn-link">
                <ng-container *ngIf="bodyMainContent">
                    + 1 {{'attachment.message' | translate}}
                    <ng-container *ngIf="numberofAnnex !== 0">
                        {{'attachment.and' | translate}} {{'attachment.'.concat(numberofAnnex
                        > 1 ? 'annexes' : 'annex') | translate : {value:
                        numberofAnnex} }}
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!bodyMainContent">
                    <ng-container *ngIf="numberofAnnex !== 0">
                        + {{'attachment.'.concat(numberofAnnex > 1 ? 'annexes' :
                        'annex') | translate : {value: numberofAnnex} }}
                    </ng-container>
                    <ng-container *ngIf="hasBody">
                        {{'attachment.and' | translate}} 1 {{'attachment.note' |
                        translate}}
                    </ng-container>
                </ng-container>
            </span>

        </ul>
    </ng-container>
    <ng-container *ngIf="onlyMaincontent ==null || !onlyMaincontent">
        <ul class="list-unstyled g-0">
            <li class="list-inline-item" *ngFor="let attachment of attachments">
                <a href="javascript:void(0)"
                    (click)="getLinkToDocument($event,  attachment)"
                    class=" bg-white" [title]="attachment.fileName">
                    <app-attachment [attachment]="attachment"></app-attachment>
                </a>
            </li>
        </ul>
    </ng-container>