import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Registry } from '@smals/ebox-enterprise-client/dist/src/external-lib/ServiceClient/RegistriesConfig';
import { Subscription } from 'rxjs';
import { StateFn } from '../../store/StateFn';
import { DataForms, StatusForms } from '../../store/store-data-interface';
import { StoreService } from '../../store/store.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styles: []
})
export class MainComponent implements OnInit, OnDestroy {

  @Input()
  isUserLoaded: boolean;
  @Input()
  isConsultMessage: boolean;
  @Input()
  public configRegistriesLoaded: Registry[];
  @Input()
  public isInfoPage: boolean;
  @Input()
  public errorLogin: boolean;
  @Input()
  isEboxLoaded: boolean;


  isMobile: boolean;

  StatusFormsEnum: any;

  resultForm: DataForms;

  private storeSubscriptions: Subscription;

  private _messagesSelectedDownload: string[];

  constructor(private storeService: StoreService, private _stateFn: StateFn, private _router: Router) {
    this._messagesSelectedDownload = [];
    this.StatusFormsEnum = StatusForms;
    this.resultForm = null;

  }

  ngOnInit() {
    this.storeSubscriptions = this.storeService.store.subscribe(state => {
      this.messagesSelectedDownload = state.userActions.messagesSelected;
      this.resultForm = this._stateFn.getDataForms();
      this.isMobile = this._stateFn.isMobile();

    });
  }

  ngOnDestroy() {
    this.storeSubscriptions.unsubscribe();
  }

  public get messagesSelectedDownload(): string[] {
    return this._messagesSelectedDownload;
  }

  public set messagesSelectedDownload(value: string[]) {
    this._messagesSelectedDownload = value;
  }
  public get isManagementPage(): boolean {

    return this._router.url === '/management';

  }


}
