import { Pipe, PipeTransform } from '@angular/core';
import { TranslatedStringPipe } from './translated-string.pipe';
import { SenderOrganizationWithRegistryConfig } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';
import { NGXLogger } from "ngx-logger";
import { StateFn } from '../store/StateFn';
import { throwError } from "rxjs";

interface ServiceParam { 'id': string, 'dataType': string, 'senderOrganization': SenderOrganizationWithRegistryConfig, 'registryId': string }


@Pipe({
  name: 'senderOrganization',
  pure: false
})
export class SenderOrganizationPipe implements PipeTransform {

  constructor(private logger: NGXLogger, private translatedPipe: TranslatedStringPipe, private stateFn: StateFn) {
  }

  transform(value: SenderOrganizationWithRegistryConfig, args?: { 'id': string, 'dataType': string, 'registryId': string }): any {
    const serviceParam: ServiceParam = { id: null, dataType: null, senderOrganization: null, registryId: null };
    serviceParam['id'] = args.id;
    serviceParam['dataType'] = args.dataType;
    serviceParam.registryId = args.registryId;
    serviceParam.senderOrganization = value;
    const result = this.getSenderOrganization(serviceParam);
    if (result === null) {
      this.logger.info('senderOrganization %s no valid for id= %s', JSON.stringify(value), serviceParam.id)
      return "";
    }
    return result

  }

  public getSenderOrganization(serviceParam: ServiceParam) {
    if (serviceParam.senderOrganization == null) {
      try {
        let instFounded = null;
        const isReferenceDataLoaded = this.stateFn.getAllReferencesData() != null;
        if (isReferenceDataLoaded) {
          instFounded = this.stateFn.getAllReferencesData().senderOrganizationsState.find(inst => (inst.enterpriseNumber == serviceParam.id && inst.registryConfig.configRegistry.id == serviceParam.registryId));
        }
        if (instFounded == null) throw Error("senderOrganization notFounded id: " + serviceParam.id + ", registryId: " + serviceParam.registryId);
        serviceParam.senderOrganization = instFounded;
      } catch (error) {

        this.logger.error(error);
        return throwError(error);
      }

    }

    switch (serviceParam['dataType']) {
      case 'logo':
        return serviceParam.senderOrganization.logo[this.stateFn.getLanguage()];
      default: return ((serviceParam.senderOrganization.name != undefined) ?  this.translatedPipe.transform(serviceParam.senderOrganization.name, this.stateFn.getLanguage()) :  serviceParam.senderOrganization.enterpriseNumber );

    }
  }

}

