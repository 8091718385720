import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Registry } from '@smals/ebox-enterprise-client/dist/src/external-lib/ServiceClient/RegistriesConfig';
import { ConfigurationService } from '@smals/ngx-configuration-service';
import { NGXLogger } from 'ngx-logger';
import { CommonException, ProviderException } from '../error/CommonException';
import { HandleExceptionService } from '../error/handle-exception-service.service';
import { ObjectHelper } from '../helper/ObjectHelper';
import { HttpMethod, WSHelper } from '../helper/WSHelper';
import { Problem } from '../interfaces';
import { TranslatedStringPipe } from '../pipes/translated-string.pipe';
import { ConfigurationRegistries } from '../registries/configuration-registries';
import { MessageRegistry } from '../registries/interface/Message-Registry';
import { getLastVersionEndPoint } from '../registries/interface/Message-Registry-Endpoint';
import { ActionsStore } from '../store/actions-store';
import { StateFn } from '../store/StateFn';

@Injectable({
  providedIn: 'root'
})
export class MessageRegistryService {


  constructor(private _wsHelper: WSHelper,
    private _configService: ConfigurationService,
    private _handleErrorService: HandleExceptionService,
    private logger: NGXLogger,
    private translatedPipe: TranslatedStringPipe,
    private _actionsStore: ActionsStore,
    private _stateFn: StateFn) { }

  initProviderRegistry(providerRegistryURL: string, maxAPIVersionSupported: string): Promise<boolean> {

    const serviceRegistry = new ConfigurationRegistries();
    const registries: Registry[] = [];
    if (this._stateFn.getConfigRegistriesLoaded() != null && this._stateFn.getConfigRegistriesLoaded().length != 0) {
      return Promise.resolve(true);
    } else {
      return this._wsHelper.callWs<{ "items": MessageRegistry[] }>(providerRegistryURL, HttpMethod.GET, null, null, { 'Content-Type': 'application/json' }).then(result => {
        this._actionsStore.saveAllMessageRegistries(result.items);
        result.items.forEach((messageRegistry: MessageRegistry) => {
          const lastVersionOfEndpoint = getLastVersionEndPoint(messageRegistry.endpoints, maxAPIVersionSupported);
          if (lastVersionOfEndpoint != null) {
            if (!lastVersionOfEndpoint.isTemporarilyDeactivated) {
              const registry: Registry = { id: messageRegistry.id, name: this.translatedPipe.transform(messageRegistry.name), version: lastVersionOfEndpoint.apiVersion, url: lastVersionOfEndpoint.url };
              registries.push(registry);
            } else {
              const providerException = new ProviderException(messageRegistry.id, null);
              providerException.dpName = messageRegistry.name;
              providerException.temporarilyDisabled = true;
              this._handleErrorService.handlerError(providerException);
            }
          }
        });
        return Promise.resolve(true);

      }).catch(e => {
        this.logger.error("Error call providerRegistry %s", e);
        if (e instanceof HttpErrorResponse || e instanceof Problem || (!ObjectHelper.isEmptyObject(e) && e["status"] != 403)) {
          const registriesStatic: Registry[] = serviceRegistry.getDefaultRegistryConfig(this._configService.getEnvironmentVariable("env_registry"));
          registries.length = 0;
          registries.push(...registriesStatic);
          const exception = new CommonException();
          exception.error = 'providerRegistryError';
          exception.message = e['message'];

          exception.code = e["status"];
          this._handleErrorService.handlerError(exception);
          return Promise.resolve(true);
        } else {
          throw ("Error call providerRegistry " + e);
        }
      }).finally(() => {
        if (registries.length == 0) {
          const exception = new CommonException();
          exception.error = 'providerRegistryError';
          exception.message = 'no provider found';

          exception.code = 404;
          this._handleErrorService.handlerError(exception);
        }
        this._actionsStore.saveRegistries(serviceRegistry.mergeRegistriesConfigWithLocalStorageHasNeeded(registries));

      })
    }
  }
}
