import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { EnterprisePreferencesService, LoadingService } from "../../services";
import { StateFn } from "../../store/StateFn";
import { StoreService } from "../../store/store.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { AddUserComponent } from "./add-user.component";
import { NGXLogger } from 'ngx-logger';
import { Partition } from '../interfaces/partition';
import { UserPartition } from '../interfaces/userPartition';
import { UserPartitionForm } from '../interfaces/userPartitionForm';
import { PacmanActionStore } from '../store/pacmanActionStore';
import { StatusForms } from '../../store/store-data-interface';
import { EnterpriseUser } from '../../models/enterprise-user';
import { UserType } from '../interfaces/enum/userType';
import { DeleteUserComponent } from "./delete-user.component";
import { HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';


@Component({
  selector: 'app-manage-partition-users',
  templateUrl: './manage-partition-users.component.html',
})
export class ManagePartitionUsersComponent implements OnInit {

  //public partition: Partition;
  public partitionUsers: UserPartition[];
  public currentPartition: Partition;
  private usersFromUman: Map<string, UserPartition>;
  StatusFormsEnum: any;
  UserTypeEnum: any;
  resultForm: StatusForms;
  storeIsLoaded: boolean;
  dataIsLoaded: boolean;
  public currentUserType: UserType;
  modeForms: string;
  isMobile: boolean;
  modalOpened: boolean;
  hasAdminRightPartitition: boolean;


  constructor(private _router: Router, private loadingService: LoadingService, private _stateFn: StateFn, private _storeService: StoreService, private _pacmanActionsStore: PacmanActionStore
    , private modalService: NgbModal, private logger: NGXLogger, private _route: ActivatedRoute, private _location: Location, private _enterpriseServices: EnterprisePreferencesService) {
    this.StatusFormsEnum = StatusForms;
    this.UserTypeEnum = UserType;
    this.resultForm = null;
    this.currentUserType = UserType.HUMAN;
    this.storeIsLoaded = false;
    this.isMobile = false;
    this.modeForms = undefined;
    this.modalOpened = false;
    this.loadingService.start({ serviceRunStart: "Manage Partition User", delayedStart: false });
  }

  ngOnInit(): void {
    this.hasAdminRightPartitition = this._stateFn.isAdmin();


    this._route.queryParams.subscribe(params => {
      if (params.partitionId !== undefined) {
        if (this.hasAdminRightPartitition) {
          if (params.userType !== undefined) {
            if (params.userType == 'null') {
              this.currentUserType = UserType.HUMAN;
              this.changeBrowserState({
                partitionId: params.partitionId,
                userType: this.currentUserType
              });
            } else {
              this.currentUserType = params.userType as UserType;
            }
          }
          if (params.isModeForms !== undefined) this.modeForms = params.isModeForms;

          this._storeService.store.subscribe(state => {
            this.isMobile = state.isMobile;
            this.dataIsLoaded = (this._stateFn.getAllPartitions().length != 0);

            if (this.dataIsLoaded && !this.storeIsLoaded) {
              const partitionFound = this._stateFn.getAllPartitions().find(item => item.id == params.partitionId);
              if (partitionFound != undefined) {
                const userIsDefined = this.usersFromUman != undefined ? Promise.resolve(this.usersFromUman) : this._enterpriseServices.getUsersInfoForEnterprise(params.partitionId);
                userIsDefined.then(getUsers => {
                  this.logger.info("UsersLoaded");
                  this.usersFromUman = getUsers;
                }).finally(() => {
                  this.currentPartition = this.getUsersFromCurrentPartition(this._stateFn.getAllPartitions(), params.partitionId);
                  this.storeIsLoaded = true;
                  this.resultForm = state.pacmanSession.dataForms.statusForms;
                  this.loadingService.stop("Management partition users");
                  if (this.modeForms == 'addUserPartition') this.addUser();
                });
              }
            }
          });
          this.resultForm = null;
        } else {
          this.loadingService.stop("Management partition users: No access to one adminPartition");

        }
      } else {
        this.logger.error("No partition Selected");
        this._router.navigate(['management/partitions']);
      }
    });

  }

  private getUsersFromCurrentPartition(partitions: Partition[], partitionId: String): Partition {
    const partitionFound = partitions.find(item => item.id == partitionId);

    if (partitionFound != undefined && this.usersFromUman != null) {
      if (partitionFound.users == undefined) {
        partitionFound.users = new Array();
      }
      const users = partitionFound.users.map(user => {
        if (this.usersFromUman.has(user.id)) {
          user = { ...user, ...this.usersFromUman.get(user.id) };
        }
        /*const ssinPipe = new SsinPipe();
        user.id = ssinPipe.transform(user.id, "xx.xx.xx-xxx.xx")*/
        return user;
      })
      partitionFound.users = users;
      return partitionFound;
    }
  }

  stringify(value: any): string {
    return JSON.stringify(value);
  }

  addUser(user?: UserPartition) {
    const options: NgbModalOptions = { modalDialogClass: 'addEditUser', keyboard: true, backdrop: 'static', centered: true };
    if (this.isMobile) {
      options.container = ".managementPartitionUsers";
      options.centered = false;
      options.backdropClass = "bg-white"
    }
    const partition: UserPartitionForm = {
      partitionId: this.currentPartition.id,
      isLastAdmin: this.isLastAdmin(),
      user: user
    }
    const listUsersAvailable = Array.from(this.usersFromUman.values()).filter(userInList => {
      if (this.currentPartition.users.find(userPartition => userPartition.id == userInList.id) == undefined)
        return userInList;
    });

    const modalAddUserRef = this.modalService.open(AddUserComponent, options);
    modalAddUserRef.componentInstance.userPartitionForm = partition;

    modalAddUserRef.componentInstance.isMobile = this.isMobile;

    modalAddUserRef.componentInstance.listUsersAvailable = listUsersAvailable;

    this.modalOpened = true;

    this._pacmanActionsStore.clearPacmanStatusForms();
    this._pacmanActionsStore.updateModeAddUserForms(true);

    this.modeForms = this._stateFn.getPacmanModeForms();
    this.changeBrowserState({
      partitionId: this.currentPartition.id,
      userType: UserType.HUMAN,
      isModeForms: this.modeForms
    });

    modalAddUserRef.result.then((statusFormModal) => {
      this.logger.info("statusForm %s", statusFormModal);
      this.resultForm = statusFormModal.statusForm;
      this.modalOpened = false;
      if (StatusForms.success == this.resultForm) {
        this.currentPartition = this.getUsersFromCurrentPartition(this._stateFn.getAllPartitions(), this.currentPartition.id);
      }
    }, (rejected => {
      this.modalOpened = false;
      //default NGMODAL modal-dismiss-reasons need a object for our custom error
      if (rejected != undefined && rejected.statusForm != undefined) this.resultForm = rejected.statusForm as StatusForms;
      this.logger.info('close modal');
    })).finally(() => this.changeBrowserState({
      partitionId: this.currentPartition.id,
      userType: UserType.HUMAN
    }))
  }

  backResultTopartitionlist() {
    this._router.navigateByUrl("/management/partitions");
  }

  changeView(userType: UserType) {
    this.currentUserType = userType;
    this.changeBrowserState({
      partitionId: this.currentPartition.id,
      userType: userType
    });

  }

  private isLastAdmin(): boolean {
    const currentUserValue: EnterpriseUser = this._stateFn.getUser();
    for (const user of this.currentPartition.users) {
      if (user.rights.partitionAdmin && user.id != currentUserValue.ssin && user.type == UserType.HUMAN) { return false; }
    }
    return true;
  }

  private changeBrowserState(params: {}) {
    const httpParams = new HttpParams().appendAll(params);
    this._location.replaceState(
      location.pathname,
      httpParams.toString()
    );
  }

  delete(user?: UserPartition) {

    this.modalOpened = true;
    const options: NgbModalOptions = {
      modalDialogClass: 'deleteUser',
      keyboard: true,
      backdrop: 'static',
      centered: true
    };
    if (this.isMobile) {
      options.container = ".managementPartitionUsers";
      options.centered = false;
      options.backdropClass = "bg-white"
    }

    const modalDeleteUserRef = this.modalService.open(DeleteUserComponent, options);
    modalDeleteUserRef.componentInstance.isMobile = this.isMobile;
    const partition: UserPartitionForm = {
      partitionId: this.currentPartition.id,
      isLastAdmin: this.isLastAdmin() && user.rights.partitionAdmin,
      user: user
    };
    this._pacmanActionsStore.clearPacmanStatusForms();
    this._pacmanActionsStore.updateModeDeleteForms(false);

    this.changeBrowserState({
      partitionId: this.currentPartition.id,
      userType: user.type,
      isModeForms: this._stateFn.getPacmanModeForms()
    });
    modalDeleteUserRef.componentInstance.userPartitionForm = partition;
    modalDeleteUserRef.result.then((statusFormModal) => {

      this.logger.info("statusForm %s", JSON.stringify(statusFormModal));
      this.resultForm = statusFormModal.statusForm;
      this.modalOpened = false;
      if (StatusForms.success == this.resultForm) {
        this.currentPartition = this.getUsersFromCurrentPartition(this._stateFn.getAllPartitions(), this.currentPartition.id);
        this.currentPartition.users = this.currentPartition.users.filter(u => u.id != user.id);
      }

    }, (rejected => {
      this.modalOpened = false;
      //default NGMODAL modal-dismiss-reasons need a object for our custom error
      if (rejected != undefined && rejected.statusForm != undefined) this.resultForm = rejected.statusForm as StatusForms;
      this.logger.info('close modal');
    })).finally(() => this.changeBrowserState({
      partitionId: this.currentPartition.id,
      userType: user !== undefined ? user.type : UserType.HUMAN
    }))
  }

}




