import { Component, OnInit } from '@angular/core';
import { EnterprisePreferencesService } from "../../../services";
import { Router } from "@angular/router";
import { Policy } from "../../../pacman/interfaces/policy";

@Component({
  selector: 'app-partition-policy',
  templateUrl: './partition-policy.component.html',
  styles: [
  ]
})
export class PartitionPolicyComponent implements OnInit {

  acceptCheckboxEnabled = false;
  acceptBtnEnabled = false;
  policy: Policy | null;

  constructor(private eboxPrefServive: EnterprisePreferencesService, private router: Router) {
  }

  ngOnInit() {
    this.eboxPrefServive.getPartitionPolicy()
      .then(policy => {
        this.policy = policy
      })
  }

  accept() {
    this.eboxPrefServive.acceptPartitionPolicy().then(() => {
      this.router.navigateByUrl("/management/partitions");
    });
  }

  scrollEnds(event : any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.acceptCheckboxEnabled = true;
    }
  }
}
