import { Translated } from "../interfaces/translated";

export class CommonException {
    // for instanceof
    type: string;
    error: string;
    message: string;
    code: number;
    redirect: boolean;
    providerRegistryId?: string[];
    originalException?: any;
    uri?:string;

}

export class TechnicalException implements CommonException {

    readonly type = 'technical';
    error: string;
    code: number;
    message: string;
    redirect = false;

}
export class BusinessException implements CommonException {

    readonly type = 'business';
    error: string;
    code: number;
    message: string;
    redirect = false;
    constructor({ error, message, redirect, code }) {
        this.code = code;
        this.message = message;
        this.redirect = redirect;
        this.error = error;
    }
}

export class AuthenticateException implements CommonException {

    readonly type = 'authentication';
    error: string;
    code: number;
    message: string;
    redirect = false;
    constructor(_error?: string, _code?: number, _message?: string, _redirect = false) {
        this.error = _error;
        this.message = _message;
        this.code = _code;
        this.redirect = _redirect;
    }
}

export class ProviderException implements CommonException {

    readonly type = 'providerException';
    error: string;
    code: number;
    message: string;
    redirect = false;
    providerId: string;
    temporarilyDisabled: boolean;
    dpName: Translated;
    constructor(_providerId: string, _code?: number, _message?: string, _redirect = false) {
        this.error = "providerError";
        this.message = _message;
        this.code = _code;
        this.redirect = _redirect;
        this.providerId = _providerId;
    }

}
