import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LoadingService, MailService } from '../../services';
import { Subscription } from 'rxjs';
import { ApplicationConstants } from '../../app.constants';

import { MessageData } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';
import { StateFn } from "../../store/StateFn";
import { StoreService } from '../../store/store.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Attachment } from '../../interfaces/legacy-reply-to-message';
import { FormsHelper } from '../../helper/Forms-helper';
import { StatusForms, StoreShape } from '../../store/store-data-interface';
import { EnterpriseUser, uniformizeCbeNumber } from '../../models/enterprise-user';
import { ActionsStore } from '../../store/actions-store';
import { NGXLogger } from "ngx-logger";
import { MessageToReply } from '../../interfaces/message-to-reply';
import { StructuredData } from "../../interfaces/structured-data";


@Component({
  selector: 'app-forms-bidirectional',
  templateUrl: './forms-bidirectional.component.html',
  styles: []
})
export class FormsBidirectionalComponent implements OnInit, OnDestroy {

  private _message: MessageData;

  @Input()
  public set message(message: MessageData) {
    this._message = message;

  }

  public isMobile: boolean;
  private _enterpriseUser: EnterpriseUser;
  private _userLanguage: string;

  private subscriptions: Subscription[] = [];
  // private reader: FileReader = new FileReader();
  public infoDefaultFileSelectedElement: string;
  //private infoFileSelectedElement: HTMLElement;

  statusSendingForm: StatusForms;
  StatusFormsEnum: any;
  errorsForms: boolean;
  private _bidirectionalForm: UntypedFormGroup;
  mimeTypeAuthorized: string[] = ApplicationConstants.mimeTypeAuthorized;
  private _attachments: Attachment[];

  get maxNumberFile() {
    return ApplicationConstants.maxNumberFile;
  }


  get maxSizeFile() {
    return ApplicationConstants.maxSizeFile;
  }

  isUploaded: boolean;
  private _messageToReply: MessageToReply;
  private _structuredData: StructuredData;

  constructor(private logger: NGXLogger, private _actionsStore: ActionsStore, private _loadingService: LoadingService, private _stateFn: StateFn,
    private storeService: StoreService,
    private cdRef: ChangeDetectorRef,
    private _mailService: MailService,
    private sanitizer: DomSanitizer, private _router: Router) {
    this.bidirectionalForm = new UntypedFormGroup({
      attachmentMessage: new UntypedFormControl(),
      phoneNumberInput: new FormControl<string | null>('', { validators: [FormsHelper.phoneNumberValidator()], updateOn: 'blur' }),
      messageTextarea: new FormControl<string | null>('', [FormsHelper.requiredAndTrimValueValidator()])
    });
    this.statusSendingForm = null;
    this.errorsForms = true;
    this.StatusFormsEnum = StatusForms;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
  }

  ngOnInit() {
    this.bidirectionalForm.statusChanges.subscribe(status => {
      if (status === 'INVALID') {
        if (this.bidirectionalForm.controls['messageTextarea'].valid && this.bidirectionalForm.controls['phoneNumberInput'].valid) {
          if (this.bidirectionalForm.controls['attachmentMessage'].invalid && this.bidirectionalForm.controls['attachmentMessage'].getError('attachmentMessageError') != null) {
            this.errorsForms = false;
          } else if (this.bidirectionalForm.controls['attachmentMessage'].invalid) {
            this.errorsForms = true;
          } else {
            this.errorsForms = false
          }
        } else {
          this.errorsForms = true;
        }
      } else {
        this.errorsForms = false
      }

    })
    this.subscriptions.push(this.storeService.store.subscribe((resultStore: StoreShape) => {
      this._userLanguage = resultStore.userSession.localeParameter;
      this._enterpriseUser = resultStore.userSession.user;
      this.isMobile = resultStore.isMobile
      this.statusSendingForm = resultStore.userActions.dataForms.statusForms;
    }));
    if (this.message != undefined) {
      this._structuredData = {
        userInfo: { firstName: "", lastName: "", nrn: "" }
      }
      this._messageToReply = {
        bodyContent: null,
        sender: {
          eboxType: "enterprise", enterpriseNumber: uniformizeCbeNumber(this._stateFn.getUser().cbeNumber)
        }
      }
    }

    this.isUploaded = true;

  }


  // generate javascript:void(0)
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  removeAttachment(index: number) {

    // logger.log(this._messageToReply.attachments);
    this._attachments.splice(index, 1);
    /*if(this._attachments != undefined && this._attachments.length > 0){
      this._translateService.stream("form.file.attachment".concat((this._attachments.length ==1 ?".one":".many")),{value1:this._attachments.length}).subscribe((res:string)=>{
        this.infoFileSelectedElement.innerText = res;
      });
    } else {
      this.infoFileSelectedElement.innerText = this.infoDefaultFileSelectedElement;
    }*/
  }

  getAttachments() {
    return this._attachments;
  }

  closeForms() {
    this._actionsStore.clearStatusForms();
    window.history.pushState(null, "", location.pathname + "?idMessage=" + this.message.messageId + "&idProviderRegistry=" + this.message.registryId);

    //this._router.navigate(['.'], { relativeTo: this._route, queryParams: { modeReply: null }, queryParamsHandling: "merge" });
  }

  onMailFormDataSubmit() {
    if (this.bidirectionalForm.get('phoneNumberInput').value !== null && this.bidirectionalForm.get('phoneNumberInput').value !== '') {
      this._structuredData.businessDataList = [{ key: "SENDER_PHONE_NUMBER", values: [{}] }];
      this._structuredData.businessDataList[0].values[0][this._userLanguage] = this.bidirectionalForm.get('phoneNumberInput').value;
    }

    this._messageToReply.bodyContent = {};
    this._messageToReply.bodyContent[this._userLanguage] = this.bidirectionalForm.get('messageTextarea').value;
    this.logger.info('result', JSON.stringify(this._messageToReply));
    this._loadingService.start({ serviceRunStart: "submit", delayedStart: false });;
    const registry = this._stateFn.getConfigRegistriesLoaded().find((reg) => reg.id == this.message.registryId);
    this._mailService.sendMailPoc(registry.url + "/ebox/messages/" + this.message.messageId + "/reply", FormsHelper.toFormReplyData(this._messageToReply, this._structuredData, this._attachments, this._enterpriseUser, this._stateFn)).then(
      resp => {
        this._actionsStore.updateStatusReplyForms(StatusForms.success);
        this._loadingService.stop("Success send Mail");
        this._router.navigate(['/']);
      })
      .catch(error => {

        this._loadingService.stop("Error send Mail");
        const handlingsError = FormsHelper.handleSendErrorForms(error, this.statusSendingForm);
        if (handlingsError.status == StatusForms.failed) {
          this._actionsStore.updateStatusReplyForms(StatusForms.failed);

        } else {
          this._actionsStore.updateStatusReplyForms(StatusForms.error);
        }

        if (handlingsError.errorOBj != null) {
          //this.bidirectionalForm.setErrors({ 'bidirectionalFormHttpError': handlingsError.errorOBj });
          this.bidirectionalForm.get('attachmentMessage').setErrors({ 'bidirectionalFormHttpError': handlingsError.errorOBj });
          this.bidirectionalForm.setErrors({ 'bidirectionalFormHttpError': handlingsError.errorOBj });

          this.cdRef.markForCheck();

        }

      });


  }

  onFileChange(event) {
    this.isUploaded = false;
    const inputFile: HTMLInputElement = (event.target || event.srcElement || event.currentTarget) as HTMLInputElement;

    if (inputFile.files && inputFile.files.length > 0) {
      this._loadingService.start({ serviceRunStart: "uploadFile", delayedStart: true });

      const attachmentsCount = this._attachments ? this._attachments.length : 0;
      let errors: [{ typeOfError: string, args?: [string | number] }] = null;
      if (attachmentsCount + inputFile.files.length > this.maxNumberFile) {
        this.bidirectionalForm.controls.attachmentMessage.setErrors({ 'attachmentMessageError': FormsHelper.fillErrors(errors, 'maxFile', null) });

        //this.bidirectionalForm.get('attachmentMessage').setErrors({ 'attachmentMessageError': FormsHelper.fillErrors(errors, 'maxFile', null) });

        inputFile.value = '';


        return;
      }
      const fileList: FileList = inputFile.files;
      //let counter = 0;
      const promiseForUploadFiles: Promise<any>[] = Array.from(fileList).map((file: File) => new Promise((resolve, reject) => {
        const reader: FileReader = new FileReader();
        reader.onload = () => {
          const fileName = file.name;
          const extension: string = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
          let errorTemp: [{ typeOfError: string, args?: [string | number] }] = null;
          // check extension
          if (this.mimeTypeAuthorized.indexOf(extension.toLowerCase()) == -1) {
            errorTemp = FormsHelper.fillErrors(errorTemp, 'mimetype', fileName);
            this.logger.error('mimeType is not correct', extension.toLowerCase())
          } else if (file.size == 0) {
            errorTemp = FormsHelper.fillErrors(errorTemp, 'emptyFile', fileName);
          } else if (fileName.length > this.maxLengthFilename) {
            errorTemp = FormsHelper.fillErrors(errorTemp, 'maxlengthFilename', this.maxLengthFilename);

          }
          resolve([{ content: file, fileName: file.name, mediaType: file.type, fileSize: file.size }, errorTemp]);
        };
        reader.readAsDataURL(file);
      })
      );

      Promise.all(promiseForUploadFiles).then(
        fileTmp => {

          fileTmp.forEach(fileResult => {
            const attachmentTotalSize = this.getCurrentTotalSize();
            const attachment: Attachment = fileResult[0];
            let errorAttachment: [{ typeOfError: string, args?: [string | number] }] = fileResult[1];
            if ((attachment.fileSize / (1024 * 1024 * this.maxSizeFile) > 1 || ((attachment.fileSize + attachmentTotalSize) / (1024 * 1024 * this.maxSizeFile) > 1))) {
              errorAttachment = FormsHelper.fillErrors(errorAttachment, 'maxSize', attachment.fileName);
            }

            if (errorAttachment == null) {
              if (this._attachments === undefined) {
                this._attachments = [];
              }
              this._attachments.push(attachment);
            } else {
              errorAttachment.forEach(error => {
                errors = FormsHelper.fillErrors(errors, error.typeOfError, error.args[0]);
              })
            }
          });
        }
      ).finally(
        () => {
          this.isUploaded = true;
          if (errors != null) {
            this.bidirectionalForm.controls.attachmentMessage.setErrors({ 'attachmentMessageError': errors });
            this._loadingService.stop('Error load file');
          } else {
            this._loadingService.stop("finish load file stopLoading");
          }
        }
      )
    }


  }



  public hasErrors(): boolean {

    // logger.log('validation messageTextarea %s, validationPhoneNumer %s', this.bidirectionalForm.controls['messageTextarea'].status, this.bidirectionalForm.controls['phoneNumberInput'].status)
    if (this.bidirectionalForm.controls['messageTextarea'].valid && this.bidirectionalForm.controls['phoneNumberInput'].valid) {
      if (this.bidirectionalForm.controls['attachmentMessage'].invalid && this.bidirectionalForm.getError('attachmentMessageError')[0] == 'maxFile') {
        return false;
      } else if (this.bidirectionalForm.controls['attachmentMessage'].invalid) {
        return true;
      }
      return false
    }

    return true;

  }


  private getCurrentTotalSize(): number {
    let count = 0;

    if (this._attachments != undefined) {
      this._attachments.forEach(attach => {

        count += attach.fileSize;
      });
    }


    return count;
  }



  public isFileOnlySizeError(): boolean {
    let leng = null;
    if (Object.keys(this.bidirectionalForm.errors).length == 1) {
      const errorFile = this.bidirectionalForm.getError('attachmentMessageError');
      leng = (errorFile[0] == 'maxFile') ? true : false;
      if (this.bidirectionalForm.get('messageTextarea').status == 'VALID') leng = false;

    }
    return leng;
  }

  public get message(): MessageData {
    return this._message;
  }


  public get bidirectionalForm(): UntypedFormGroup {
    return this._bidirectionalForm;
  }

  public set bidirectionalForm(value: UntypedFormGroup) {
    this._bidirectionalForm = value;
  }

  public get language(): string {
    return this._stateFn.getLanguage();
  }


  get f() {
    return this.bidirectionalForm.controls;
  }


  public get attachments(): Attachment[] {
    return this._attachments;
  }


  public set attachments(value: Attachment[]) {
    this._attachments = value;
  }

  get maxLengthFilename() { return ApplicationConstants.maxLengthFilename; }

  get maxLengthTitle() { return ApplicationConstants.maxLengthTitle; }
}
