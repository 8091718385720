import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appSelectToDropdown]'
})
export class SelectToDropdownDirective implements AfterViewInit {
  selectHTML: HTMLSelectElement;
  buttonToggle: HTMLButtonElement = document.createElement('button');
  ul: HTMLUListElement = document.createElement("ul");
  constructor(private el: ElementRef, private renderer: Renderer2, private _translate: TranslateService, private control: NgControl) {
    this.selectHTML = this.el.nativeElement;
    this.selectHTML.classList.add('d-none');


  }


  ngAfterViewInit() {

    this.buttonToggle.className = "col-12 btn btn-secondary dropdown-toggle text-start d-flex justify-content-between  align-items-center";
    this.buttonToggle.type = "button";
    this.buttonToggle.setAttribute("aria-expanded", "false");
    this.buttonToggle.setAttribute("data-bs-toggle", "dropdown");
    this.buttonToggle.innerText = this.getDefaultValue(this.selectHTML).innerText;

    const divDropDown: HTMLDivElement = document.createElement("div");
    divDropDown.className = "dropdownPartitionMove";
    divDropDown.append(this.buttonToggle);
    this.ul.classList.add("dropdown-menu");
    this.generateLiElement();

    divDropDown.append(this.ul);
    this.renderer.insertBefore(this.selectHTML.parentElement, divDropDown, this.selectHTML);

  }
  changeValueSelect(event: any) {
    const eventNodeHtml: HTMLElement = <HTMLElement>(event.target || event.srcElement || event.currentTarget);
    const selectedValue = eventNodeHtml.getAttribute("data-value");
    this.buttonToggle.innerHTML = eventNodeHtml.innerHTML;
    for (let index = 0; index < this.selectHTML.options.length; index++) {
      const element = this.selectHTML.options[index];
      element.selected = false;
      if (element.value == selectedValue) {
        element.selected = true;
        this.control.control.setValue(element.value);
      }

    }
    this.generateLiElement();

  }

  private generateLiElement() {
    this.ul.innerHTML = "";
    for (let index = 0; index < this.selectHTML.options.length; index++) {
      const element = this.selectHTML.options[index];
      if ("default" != element.value && !element.selected) {
        const liElement: HTMLLIElement = document.createElement("li");

        const aElement: HTMLAnchorElement = document.createElement("a");
        aElement.href = "javascript:void(0)";
        this.renderer.listen(aElement, 'click', event => this.changeValueSelect(event));
        aElement.className = "d-block text-body";
        aElement.innerHTML = element.innerHTML;
        aElement.setAttribute("data-value", element.value);

        liElement.appendChild(aElement);
        liElement.className = element.className;
        liElement.classList.add("dropdown-item");
        this.ul.append(liElement)
      }
    }
  }



  private getDefaultValue(select: HTMLSelectElement): HTMLOptionElement {

    const defaultOption: HTMLOptionElement = document.createElement("option").innerHTML = this._translate.instant("messages.actionsMessage.move.modal.body.select.default");
    for (let index = 0; index < this.selectHTML.options.length; index++) {
      if ("default" == this.selectHTML.options[index].value) {
        return this.selectHTML.options[index];
      }
    }

    return defaultOption;
  }

}
