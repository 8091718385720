import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MessageData } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';
import { Subscription } from 'rxjs';
import { ResponsiveService } from '../../../services';
import { StateFn } from '../../../store/StateFn';
import { StoreService } from '../../../store/store.service';
import {DateHelper} from '../../../helper/DateHelper';


@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styles: []
})
export class TagComponent implements OnInit, OnDestroy {

  dateIsExpired;

  constructor(private _stateFn:StateFn,private _responsiveService:ResponsiveService, private _storeService : StoreService, private _dateHelper:DateHelper) {

    this.dateIsExpired = this._dateHelper.dateIsExpired;
  }

  private _message: MessageData;

  public get message(): MessageData {
    return this._message;
  }

  @Input()
  public set message(value: MessageData) {
    this._message = value;
  }

  isMobile: boolean;
  folder: String;

  private subscriptions: Subscription[] = [];

  ngOnInit() {
    this._responsiveService.checkWidth();

    this.subscriptions.push(this._stateFn.register(s => {
      this.isMobile = this._stateFn.isMobile();
    }));


  }

  stringify(object) {
    return JSON.stringify(object);
  }


  public get windowWidth(): number {
    return this._responsiveService.innerWidth;
  }
  public isLoggedUser(ssin: String) {
    return this._stateFn.isOpenIdActive() ? (ssin === this._storeService.store.getValue().userSession.user.ssin) : false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  

}
