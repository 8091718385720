import { Component, OnInit } from '@angular/core';


@Component({ template: '../loading/loading.component.html' })
export class DirectAccessComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }

}
