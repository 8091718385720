export interface MessageRegistryEndpoint {
    url	:string,
    activationDate:Date,
    deactivationDate:Date,
    technicalContact:string,
    apiVersion:string,
    isTemporarilyDeactivated:boolean
}
export function getLastVersionEndPoint(endpoints:MessageRegistryEndpoint[],
    maxAPIVersionSupported:string):MessageRegistryEndpoint{

  const maxVersion = Number(maxAPIVersionSupported.replace(".", ""));
  let goodEndpoint: MessageRegistryEndpoint = null;

  for (let index = 0; index < endpoints.length; index++) {
    const versionOfEndpoint = Number(endpoints[index].apiVersion.replace(".", ""));
    const versionOfgoodEndpoint = (goodEndpoint == null) ? 0 : Number(goodEndpoint.apiVersion.replace(".", ""));
    if (versionOfEndpoint <= maxVersion && versionOfEndpoint > versionOfgoodEndpoint) {
      goodEndpoint = endpoints[index];
    }

  }
  return goodEndpoint;
}
