<ng-container *ngIf="visible">

    <div class="dialog loadingDialog {{firstLoading ? 'firstLoading' :''}} " [@fadeInOut]>

        <div id="logo" class="text-center">

            <h1>
                <img src="assets/images/e-Box.png" width="149" alt="logo eBox Enterprise" />
                <span class="visually-hidden">{{'header.title' | translate}}</span>
            </h1>

        </div>

        <ngb-progressbar value="{{value}}" type="{{type}}"></ngb-progressbar>

    </div>

    <div class="overlay  {{firstLoading ? 'firstLoading' :''}} "></div>

</ng-container>