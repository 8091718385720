
import { AttachmentData } from "@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData";

import { ActionsService } from "../../services";


export class MessageDocumentManagement {



  static getLinkToDocument(event: Event, attachment: AttachmentData, registryId: string, _actionsService: ActionsService): void {
    if (event !== null) {

      _actionsService.downloadMessage(attachment, registryId).then(result => {
        let parentNodeHtml: HTMLElement = <HTMLElement>(event.target || event.srcElement || event.currentTarget);
        while (parentNodeHtml.nodeName !== 'TR' && parentNodeHtml.nodeName !== 'BODY' && parentNodeHtml.nodeName !== 'A') {
          parentNodeHtml = <HTMLElement>parentNodeHtml.parentNode;
        }
        if (parentNodeHtml.classList.contains('unread')) {
          parentNodeHtml.classList.remove('unread');
          setTimeout(() => _actionsService.initializeAlertMessage(), 100);
        }
      });

    }

  }


}
