import {Rights} from "./rights";
import {UserType} from "./enum/userType";

export  interface UserPartition{
  id: string,
  type: UserType,
  businessId?: string,
  userValue?: { firstName: string, lastName: string },
  rights?: Rights
}

export function sortUserPartition(){
  return (a: UserPartition, b: UserPartition)=>{
    if(a.id==undefined ) return -1;
    if(b.id == undefined) return 1;
    const nissA = a.id.toUpperCase(); // ignore upper and lowercase
    const nissB = b.id.toUpperCase(); // ignore upper and lowercase
    if (nissA < nissB) {
      return -1;
    }
    if (nissA > nissB) {
      return 1;
    }


    // names must be equal
    return 0;
  }

}
