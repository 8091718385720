import { Component, OnInit } from '@angular/core';


@Component({ template: '' })
export class BlankComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {

  }

}
