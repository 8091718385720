import { Translated } from "./translated";

export interface EnterprisePreferences {
    enterpriseNumber: string;
    enterpriseName: Translated;
    emails: EboxProfileEmails[];
    exclusivelyEbox: boolean;
    firstConnectionDate: Date;
    hasAcceptedPartitionSelfServicePolicy: boolean;
    hasAcceptedDocConsumerPolicy: boolean;
}

export interface EboxProfileEmails {
    emailType?: EmailType;
    /**
     * The e-mail address of the enterprise for communications concerning the e-Box
     */
    email?: string;
    /**
     * Information indicating whether the e-Box has confirmed its mandatary email. By default it's false
     */
    isConfirmed?: boolean;
}


export enum EmailType {
    ENTERPRISE = 'enterprise',
    MANDATARY = 'mandatary'
};