import { Injectable } from '@angular/core';

@Injectable()
export class ApplicationConstants {

  static readonly DATEPATTERN = 'dd/MM/yyyy';
  static readonly TELNUMBERPATTERN: RegExp = new RegExp('([0-9]|[/]|[+]|[-]|[(]|[)]|[.])+');
  static readonly offDay = 15;
  static readonly LANGUAGE_SUPPORTED: string[] = ['fr', 'nl', 'de'];

  ssin = '79072300048';
  firstName = 'MockFirstName';
  lastName = 'MockLastName';
  language = 'fr';
  cbeNumber = '0457527422';
  enterpriseName = 'MockEnterpriseName';
  enterprisePreferences = 'enterprises/{}'; // {} => enterpriseNumber
  static readonly publishMessagePath = 'ebox/messages';
  static readonly mimeTypeAuthorized = 'csv,htm,html,odp,ods,odt,xml,doc,xls,docx,xlsx,ppt,pps,pptx,ppsx,jpg,jpeg,gif,png,pdf,txt,zip'.split(',');
  static readonly maxSizeFile = 18;
  static readonly maxNumberFile = 50;
  static readonly maxLengthTitle: number = 400;
  static readonly maxLengthFilename: number = 255;
  public isNullOrUndefined = function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
    return typeof obj === "undefined" || obj === null;
  };

}
