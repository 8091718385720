<form (ngSubmit)="submitSearch()"
    [class]="(isMobile ?'form-horizontal ':'form-inline  align-items-start ').concat((formInvalid) ? 'was-validated ng-invalid':'')"
    role="search">
    <fieldset class="flex-sm-fill flex-sm-grow-0  flex-md-grow-1">
        <legend [class]="!isMobile ? 'visually-hidden':null">{{'header.search-bar.forms.filterForms.date.label'
            | translate}}</legend>
        <div class="form-group d-sm-inline-block  mb-sm-2 mb-md-0">
            <label for="dateFromInput">{{'header.search-bar.forms.filterForms.date.dateFromInput'
                | translate}}:
                <span *ngIf="publicationDateFromInvalid  && isDateConsecutive"
                    class="invalid-feedback">
                    ({{'header.search-bar.forms.filterForms.date.error.format' |
                    translate}})
                </span>
                <span *ngIf="publicationDateFromInvalid && !isDateConsecutive"
                    class="invalid-feedback">
                    ({{'header.search-bar.forms.filterForms.date.error.consecutive'
                    | translate}})
                </span>
            </label>
            <div class="input-group mb-3  mb-sm-0">
                <input appFocusInputGroup type="text"
                    data-testid="filter-bar.from-date-input"
                    placeholder="dd/mm/yyyy"
                    class="form-control {{publicationDateFromInvalid?'is-invalid':''}}"
                    id="dateFromInput" value="{{publicationDateFromAsString}}"
                    (change)="onChange_publicationDateFrom($event)"
                    (dateSelect)="dateSelect_publicationDateFrom($event)"
                    autocomplete="true" aria-describedby="btnDateFromInput"
                    ngbDatepicker #dpFromInput="ngbDatepicker" />
                <button
                    class="btn btn-primary bg-primary text-white btn-outline-primary"
                    (click)="dpFromInput.toggle()" type="button"
                    id="btnDateFromInput"><i class="far fa-calendar-alt"
                        aria-hidden="true"></i>
                    <span class="visually-hidden">Open calendar</span>
                </button>
                <button type="reset" *ngIf="publicationDateFrom"
                        class="reset btn smallBtn {{isMobile ? 'border-start': 'border-top border-end border-bottom'}}"
                        data-testid="search-bar.reset"
                        (click)="publicationDateFrom = null">
                    <i class="fas fa-times" aria-hidden="true" [attr.aria-label]=" 'header.search-bar.forms.searchTerms.remove' | translate"></i>
                    <span class="visually-hidden">{{'header.search-bar.forms.searchTerms.remove'
                        | translate}} </span>
                </button>
            </div>

        </div>
        <div class="form-group d-sm-inline-block mb-sm-2 mb-md-0">
            <label for="dateToInput">{{'header.search-bar.forms.filterForms.date.dateToInput'
                | translate}}:
                <span *ngIf="publicationDateToInvalid && isDateConsecutive"
                    class="invalid-feedback">
                    ({{'header.search-bar.forms.filterForms.date.error.format' |
                    translate}})
                </span>
                <span *ngIf="publicationDateToInvalid && !isDateConsecutive"
                    class="invalid-feedback">
                    ({{'header.search-bar.forms.filterForms.date.error.consecutive'
                    | translate}})
                </span>
            </label>
            <div class="input-group mb-3 mb-sm-0">
                <input appFocusInputGroup type="text"
                    data-testid="filter-bar.to-date-input"
                    placeholder="dd/mm/yyyy"
                    class="form-control {{(publicationDateToInvalid)?'is-invalid':''}}"
                    id="dateToInput" value="{{publicationDateToAsString}}"
                    (change)="onChange_publicationDateTo($event)"
                    (dateSelect)="dateSelect_publicationDateTo($event)"
                    autocomplete="true" aria-describedby="btnDateToInput"
                    ngbDatepicker #dpToInput="ngbDatepicker" />
                <button
                    class="btn btn-primary bg-primary text-white btn-outline-primary"
                    (click)="dpToInput.toggle()" type="button"
                    id="btnDateToInput"><i class="far fa-calendar-alt"
                        aria-hidden="true"></i>
                    <span class="visually-hidden">Open calendar</span>
                </button>
                <button type="reset" *ngIf="publicationDateTo"
                        class="reset btn smallBtn {{isMobile ? 'border-start': 'border-top border-end border-bottom'}}"
                        data-testid="search-bar.reset"
                        (click)="publicationDateTo = null">
                    <i class="fas fa-times" aria-hidden="true" [attr.aria-label]=" 'header.search-bar.forms.searchTerms.remove' | translate"></i>
                    <span class="visually-hidden">{{'header.search-bar.forms.searchTerms.remove'
                        | translate}} </span>
                </button>
            </div>

        </div>
    </fieldset>
    <fieldset class="flex-sm-fill flex-sm-grow-0  flex-md-grow-1"
        *ngIf="allReferencesData">
        <legend [class]="!isMobile ? 'visually-hidden':''">{{'header.search-bar.forms.filterForms.origin.label'
            | translate}}</legend>
        <div [class]="!isMobile ? 'd-flex' :''">
            <div class="form-group mb-3 mb-sm-2 mb-lg-0">
                <label for="senderOrganizationSelect">{{'header.search-bar.forms.filterForms.origin.senderOrganizationSelect'
                    | translate}}: </label>
                <div class="input-group">
                    <select class="form-select" id="senderOrganizationSelect"
                        (change)="onChange_senderOrganizationSelection($event)">
                        <option value="default"
                            [selected]="senderOrganizationSelection == null ? true : null">{{'header.search-bar.forms.filterForms.origin.senderOrganizationDefaultOption'
                            | translate}}</option>
                        <option
                            *ngFor="let senderOrganization of senderOrganizations"
                            value="{{orgaId(senderOrganization)}}"
                            [selected]="senderOrganizationSelection === orgaId(senderOrganization) ? true : null"
                            [title]="senderOrganization | senderOrganization:({'id': senderOrganization.enterpriseNumber,'registryId':senderOrganization.registryConfig.configRegistry.id, 'dataType': 'name'})">{{senderOrganization
                            | senderOrganization:({'id':
                            senderOrganization.enterpriseNumber ,'registryId' :
                            senderOrganization.registryConfig.configRegistry.id,
                            'dataType': 'name'})}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group mb-3  mb-sm-2 mb-lg-0">
                <label for="senderApplicationSelect">{{'header.search-bar.forms.filterForms.origin.senderApplicationSelect'
                    | translate}}: </label>
                <div class="input-group">
                    <select class="form-select" id="senderApplicationSelect"
                        (change)="onChange_senderApplicationSelection($event)">
                        <option value="default"
                            [selected]="senderApplicationSelectDefault === null ? true : null">{{'header.search-bar.forms.filterForms.origin.senderApplicationDefaultOption'
                            | translate}}</option>
                        <option *ngFor="let senderApplication of senderApplications"
                            value="{{appId(senderApplication)}}"
                            [selected]="senderApplicationSelection === appId(senderApplication) ? true : null"
                            [title]="senderApplication | senderApplication:({'id': senderApplication.id +'_rid_' + senderApplication.registryConfig.configRegistry.id, 'dataType': 'name'})">{{senderApplication
                            | senderApplication:({'id': senderApplication.id ,'registryId'
                            : senderApplication.registryConfig.configRegistry.id,
                            'dataType': 'name'})}}</option>
                    </select>
                </div>
            </div>
        </div>
    </fieldset>

    <fieldset
        class="flex-sm-fill flex-sm-grow-0  flex-md-grow-1 w-auto  mt-sm-3 mt-lg-0">
        <legend class="mb-sm-0">{{'header.search-bar.forms.filterForms.type.label'
            | translate}}:</legend>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value
                id="recommendCheckbox" [checked]="includeRegisteredMessages"
                (change)="onChange_includeRegisteredMessages($event)">
            <label class="form-check-label" for="recommendCheckbox">
                {{'header.search-bar.forms.filterForms.type.recommend' |
                translate}}
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value
                id="expiredCheckbox" [checked]="includeExpiringMessages"
                (change)="onChange_includeExpiringMessages($event)">
            <label class="form-check-label" for="expiredCheckbox">
                {{'header.search-bar.forms.filterForms.type.expired' |
                translate}}
            </label>
        </div>
    </fieldset>
    <fieldset
        class="flex-sm-fill flex-sm-grow-0  flex-md-grow-1 w-auto  mt-sm-3 mt-lg-0">
        <legend class="mb-sm-0">{{'header.search-bar.forms.filterForms.status.label'
            | translate}}:</legend>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value
                id="readCheckbox" [checked]="includeReadMessages"
                (change)="onChange_includeReadMessages($event)">
            <label class="form-check-label" for="readCheckbox">
                {{'header.search-bar.forms.filterForms.status.read' |
                translate}}
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value
                id="unreadCheckbox" [checked]="includeUnreadMessages"
                (change)="onChange_includeUnreadMessages($event)">
            <label class="form-check-label" for="unreadCheckbox">
                {{'header.search-bar.forms.filterForms.status.unread' |
                translate}}
            </label>
        </div>
    </fieldset>
    <div
        [class]="(isMobile ? 'container-fluid container-xxxxxl' :'d-flex flex-row w-100 justify-content-start').concat(' mt-3')">
        <div [class]="isMobile ? 'row d-flex btn-group':' d-flex filter-btn'"
            [attr.role]="isMobile ? 'group' : null"
            attr.aria-label="{{'messages.actionsMessage.name' | translate }}">
            <button type="submit" [disabled]="formInvalid ? true : null"
                data-testid="filter-bar.submit"
                class="btn btn-primary me-2 me-sm-0">
                {{'header.search-bar.label' | translate}}
            </button>

            <button type="button" data-testid="filter-bar.reset" class="btn btn-primary ms-2 me-2 me-sm-0" (click)="resetFilters()">
                {{'header.search-bar.reset' | translate}}
            </button>

            <button *ngIf="isMobile" class="btn btn-link" type="button"
                (click)="closeModal()" data-target="#navbarsMobile-search"
                aria-controls="navbarsMobile-search">
                {{'header.search-bar.forms.filterForms.action.cancel' |
                translate}}
            </button>
        </div>
    </div>

</form>
