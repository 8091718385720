import {AfterViewChecked,  Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {PacmanActionService} from "../services/pacmanAction.service";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Translation} from "../../interfaces/translation";
import {PartitionManagement} from "./management-partitions.component";
import {Partition} from "../interfaces/partition";
import {FormsHelper} from "../../helper/Forms-helper";
import {LoadingService} from "../../services";
import {StatusForms} from "../../store/store-data-interface";
import {PacmanActionStore} from "../store/pacmanActionStore";
import { PacmanHandleExceptionService } from "../error/pacman-handle-exception.service";

@Component({
  selector: 'app-add-edit-partition',
  templateUrl: './add-edit-partition.component.html',
})
export class AddEditPartitionComponent implements OnInit, AfterViewChecked {

  @Input() partitionToEdit: PartitionManagement;
  @Input() isMobile: boolean;
  @ViewChild("formPartition") formPartitionElement : ElementRef<HTMLFormElement>;
  private heightMaxModalMobile : number

  hasError = false;
  partitionErrorType: string;
  newPartitionForm : UntypedFormGroup;

  constructor(private activeModal: NgbActiveModal, private pacmanActionService: PacmanActionService, private loadingService: LoadingService,
              private pacmnActionStore: PacmanActionStore, private pacmanExceptionService: PacmanHandleExceptionService) { }

  ngOnInit(): void {
    this.newPartitionForm = new UntypedFormGroup({
      partitionId: new UntypedFormControl('', {validators:[Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-zA-Z0-9._-]{1,50}$/)]}),
      nameFr: new UntypedFormControl('', Validators.maxLength(50)),
      nameNl: new UntypedFormControl('', Validators.maxLength(50)),
      nameDe: new UntypedFormControl('', Validators.maxLength(50))
    });
    this.initForm();
    this.heightMaxModalMobile = 500;

  }

  initForm() : void {
    this.hasError = false;
    this.partitionErrorType = null;
    if (this.partitionToEdit) {
      this.partitionIdInput.disable();
      this.partitionIdInput.setValue(this.partitionToEdit.id);
      this.nameFrInput.setValue(this.partitionToEdit.name.fr);
      this.nameNlInput.setValue(this.partitionToEdit.name.nl);
      this.nameDeInput.setValue(this.partitionToEdit.name.de);
    }
  }
  ngAfterViewChecked():void{
    const heightMax = window.innerHeight - this.formPartitionElement.nativeElement.getBoundingClientRect().top ;

    if(this.isMobile && heightMax != this.heightMaxModalMobile){
            this.heightMaxModalMobile = (heightMax < this.heightMaxModalMobile) ? heightMax : this.heightMaxModalMobile;

      this.formPartitionElement.nativeElement.style.height =  this.heightMaxModalMobile + "px";
      this.formPartitionElement.nativeElement.style.overflowY ="scroll";
    }
  }

  save(): void {
    this.hasError = false;
    this.partitionErrorType = null;
    const partitionToSave = this.formToDto();
    if (!this.newPartitionForm.invalid) {
      this.loadingService.start({
        serviceRunStart:(this.partitionToEdit != null ? 'Update partition' : 'create partition') + " sent",
        delayedStart: false
      });
      this.pacmanActionService.createOrEditPartition(partitionToSave, this.partitionToEdit != null).then(
          success => {
            this.handleSuccess(partitionToSave)
          }).catch(error => {
            this.handleError(error)
        });
    }
  }

  private handleError(errorReponse) {
    this.loadingService.stop("Error create/update partition");
    this.hasError = true;
    if (errorReponse.error && errorReponse.error.type) {
      this.partitionErrorType = errorReponse.error.type.split(":").pop();
      if (this.partitionErrorType !== 'invalidPartitionName' && this.partitionErrorType !== 'partitionAlreadyExists') {
        this.pacmanExceptionService.handlerError(errorReponse.error);
        this.activeModal.close(FormsHelper.handlePacmanErrorForms(errorReponse, StatusForms.error))
      }
    }
  }

  private handleSuccess(partition: Partition) {
    this.loadingService.stop("Success create/update partition");
    this.activeModal.close({
      "partition": partition,
      "edit": this.partitionToEdit != null
    });
    this.pacmnActionStore.addOrUpdatePartition(partition);
  }

  formToDto(): Partition {
    const partition = {id: this.partitionIdInput.value} as Partition;
    const translation = new Translation();
    if (this.nameFrInput.value) {
      translation.fr = this.nameFrInput.value;
    }
    if (this.nameNlInput.value) {
      translation.nl = this.nameNlInput.value;
    }
    if (this.nameDeInput.value) {
      translation.de = this.nameDeInput.value;
    }
    if (translation.fr || translation.nl || translation.de) {
      partition.name = translation;
    }
    return partition;
  }

  get partitionIdInput() {
    return this.newPartitionForm.get("partitionId");
  }
  get nameFrInput() {
    return this.newPartitionForm.get("nameFr");
  }
  get nameNlInput() {
    return this.newPartitionForm.get("nameNl");
  }
  get nameDeInput() {
    return this.newPartitionForm.get("nameDe");
  }

  

  close(): void {
    this.activeModal.dismiss("closed");
  }
}
