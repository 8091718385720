import { Injectable } from '@angular/core';
import { HttpMethod, WSHelper } from '../helper/WSHelper';
import { NGXLogger } from "ngx-logger";


@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private logger: NGXLogger,private ws: WSHelper) {
  }


  public sendMailPoc(restWSUrl: string, formData: FormData): Promise<boolean> {

    // TODO add + this._path
    const subscribe = this.ws.callWs<void>(restWSUrl, HttpMethod.POST
      , null, formData);
    return subscribe.then(v => {
      this.logger.info('success');
      return true;
    });

    
  }

}
