import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NGXLogger } from 'ngx-logger';
import { FormsHelper } from '../../helper/Forms-helper';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserPartitionForm } from '../interfaces/userPartitionForm';
import { StatusForms, StoreShape } from '../../store/store-data-interface';
import { DomSanitizer } from '@angular/platform-browser';
import { UserType } from '../interfaces/enum/userType';
import { LoadingService } from '../../services';
import { PacmanActionService } from '../../pacman/services/pacmanAction.service';
import { PacmanConstants } from '../pacman.constants';
import { ConfigurationService } from '@smals/ngx-configuration-service';
import { uniformizeCbeNumber } from '../../models/enterprise-user';
import { StateFn } from '../../store/StateFn';
import { PacmanActionStore } from '../../pacman/store/pacmanActionStore';
import { StoreService } from '../../store/store.service';
import { SsinPipe } from '@smals/ngx-smalsutils-validation';
import { Observable } from 'rxjs/internal/Observable';
import { OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UserPartition } from '../interfaces/userPartition';


@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html'
})
export class AddUserComponent implements OnInit {
  @Input() userPartitionForm: UserPartitionForm;
  @Input() isMobile: boolean;
  @Input() listUsersAvailable: UserPartition[];

  errorsForms: boolean;
  statusSendingForm: StatusForms;
  StatusFormsEnum: any;
  urlUman: string;
  private _addUserToPartition: UserPartitionForm;

  addUserToPartitionForm: UntypedFormGroup;

  constructor(private cdRef: ChangeDetectorRef, public activeModal: NgbActiveModal, private logger: NGXLogger, private sanitizer: DomSanitizer, private _loadingService: LoadingService, private _pacmanActionService: PacmanActionService, private _pacmanActionsStore: PacmanActionStore, private _configService: ConfigurationService, private _stateFn: StateFn, private _storeService: StoreService) {
    this.addUserToPartitionForm = new UntypedFormGroup({
      userSearchNiss: new UntypedFormControl('', { validators: [FormsHelper.NissNumberValidator(), Validators.required], updateOn: 'change' })
    });
    this.statusSendingForm = null;
    this.errorsForms = true;
    this.StatusFormsEnum = StatusForms;
    this.urlUman = this._configService.getEnvironmentVariable('urlUman');
  }

  formatterResultSearchNiss = (userPartition: UserPartition) => (userPartition.id != undefined) ? (new SsinPipe().transform(userPartition.id, "xx.xx.xx-xxx.xx")).concat(" ").concat(userPartition.userValue?.firstName).concat(" ").concat(userPartition.userValue?.lastName) : '';

  uniformizeTextCompare = (str: string) => str.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  search: OperatorFunction<string, readonly UserPartition[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.listUsersAvailable.filter(v =>
          v.id.replace(/[\.\s\-]*/g, '').indexOf(term.replace(/[\.\s\-]*/g, '')) > -1 ||
          this.uniformizeTextCompare(v.userValue?.firstName).indexOf(this.uniformizeTextCompare(term)) > -1 ||
          this.uniformizeTextCompare(v.userValue?.lastName).indexOf(this.uniformizeTextCompare(term)) > -1).slice(0, 10))
    );

  blurEvent(elemnt: HTMLInputElement) {
    const term = elemnt.value;
    const users = this.listUsersAvailable.filter(v =>
      v.id.replace(/[\.\s\-]*/g, '').indexOf(term.replace(/[\.\s\-]*/g, '')) > -1 ||
      this.uniformizeTextCompare(v.userValue?.firstName).indexOf(this.uniformizeTextCompare(term)) > -1 ||
      this.uniformizeTextCompare(v.userValue?.lastName).indexOf(this.uniformizeTextCompare(term)) > -1)
    if (users.length == 1) this.addUserToPartitionForm.controls["userSearchNiss"].setValue(users[0]);

    //      const user:UserPartition =this.listUsersAvailable.find(userUman=>userUman.id==elemnt.value);
  }
  ngOnInit(): void {
    this.logger.debug(this.userPartitionForm);
    this._storeService.store.subscribe((resultStore: StoreShape) => {
      this.statusSendingForm = resultStore.pacmanSession.dataForms.statusForms;
    });

    this.addUserToPartitionForm.statusChanges.subscribe(status => {
      if (status === 'INVALID') {
        this.errorsForms = !this.addUserToPartitionForm.controls['userSearchNiss'].valid;
      } else {
        this.errorsForms = false
      }
    })
  }

  // generate javascript:void(0)
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  onMailFormDataSubmit() {
    this._addUserToPartition = { partitionId: this.userPartitionForm.partitionId, rights: {}, user: { id: null, type: UserType.HUMAN } };
    this.logger.info('result', JSON.stringify(this._addUserToPartition));
    this._addUserToPartition.user.id = this.addUserToPartitionForm.get("userSearchNiss").value['id'].replace(FormsHelper.regexpSsinNumber, "");
    this._loadingService.start({ serviceRunStart: ('add') + " send", delayedStart: false });
    const data: string = FormsHelper.toRawData(this._addUserToPartition);

    const url = this._configService.getEnvironmentVariable("urlPacmanAdmin") + "/" +
      PacmanConstants.userPartitionRights
        .replace("{eboxId}", uniformizeCbeNumber(this._stateFn.getUser().cbeNumber))
        .replace("{partitionId}", this.userPartitionForm.partitionId);
    this._pacmanActionService.addUserToPacman(url, null, data).then(
      resp => {
        const userId: string = this._addUserToPartition.user.businessId;
        this._addUserToPartition.rights = { consult: true };
        this._addUserToPartition.user.businessId = userId;
        this._addUserToPartition.user.userValue = this.addUserToPartitionForm.get("userSearchNiss").value['userValue'];
        this._pacmanActionsStore.updateStatusAddUserForms(StatusForms.success, this._addUserToPartition);
        this._loadingService.stop("Success " + ("add user"));
        this.addUserToPartitionForm.setErrors(null);
        this.closeAndResetModal(StatusForms.success);
      })
      .catch(error => {
        const handlingsError = FormsHelper.handlePacmanErrorForms(error, this.statusSendingForm);
        if (handlingsError.status == StatusForms.failed) {
          this._pacmanActionsStore.updateStatusAddUserForms(StatusForms.failed);
          this.closeAndResetModal(StatusForms.failed);
        } else {
          this._pacmanActionsStore.updateStatusAddUserForms(StatusForms.error);
        }
        const errors = handlingsError.errorOBj;
        switch (errors.type) {
          case "userIsNotPartOfEbox":
            errors["args"] = { value1: this._addUserToPartition.user.id, value2: uniformizeCbeNumber(this._stateFn.getUser().cbeNumber) };
            break;
          case "userAlreadyAssigned":
            errors["args"] = { value1: this._addUserToPartition.user.id, value2: this.userPartitionForm.partitionId };
            break;
          default:
            break;
        }
        this.addUserToPartitionForm.setErrors({ 'addUserToPartitionFormHttpError': errors });

        this.cdRef.markForCheck();
        this._loadingService.stop("Error " + ("add send"));
        // }
      });
  }

  closeAndResetModal(statusForm?: StatusForms) {
    this._pacmanActionsStore.clearPacmanStatusForms();
    if (statusForm != undefined) {
      this.activeModal.close({ "statusForm": statusForm });
    } else {
      this.activeModal.dismiss();
    }
  }

}
