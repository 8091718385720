import { Injectable } from "@angular/core";
import { StateFn } from "../../store/StateFn";
import { NGXLogger } from "ngx-logger";
import { HttpMethod, WSHelper } from "../../helper/WSHelper";
import { Partition } from "../interfaces/partition";
import { sortUserPartition, UserPartition } from "../interfaces/userPartition";
import { Rights } from "../interfaces/rights";
import { PacmanActionStore } from "../store/pacmanActionStore";
import { PacmanHandleExceptionService } from "../error/pacman-handle-exception.service";
import { uniformizeCbeNumber } from "../../models/enterprise-user";
import { PacmanConstants } from "../pacman.constants";
import { ConfigurationService } from "@smals/ngx-configuration-service";
import { Translated } from "../../interfaces";
import { ApplicationConstants } from "../../app.constants";
import { StoreService } from "../../store/store.service";
import { HttpErrorResponse, HttpEvent, HttpResponse } from "@angular/common/http";
import { AuthenticateException } from "../../error/CommonException";

@Injectable()
export class PacmanActionService {


  private uriPacmanConsult: string;
  private uriPacmanAdmin: string;

  private readonly DEFAULT_ID_PARTITION = "GENERAL";

  constructor(private logger: NGXLogger,
    private _pacmanActionsStore: PacmanActionStore,
    private _handleErrorService: PacmanHandleExceptionService,
    private ws: WSHelper, private _stateFn: StateFn, private _configService: ConfigurationService, private _appConstant: ApplicationConstants, private _storeService: StoreService) {
    this.uriPacmanConsult = this._configService.getEnvironmentVariable("urlPacmanConsult");
    this.uriPacmanAdmin = this._configService.getEnvironmentVariable("urlPacmanAdmin");
  }

  public initializeAutoOnboardingMayBeAdminPartition(): Promise<{isAdmin:boolean, partitions: [] }> {

    return this.getPartitionsAdminRightWithHeaders()
      .then(resp => {
        const response = resp as HttpResponse<{ partitions: [] }>;
        return Promise.resolve({isAdmin:this._stateFn.isAccessAdmin(), partitions:response.body.partitions});
      })
      .catch(error => {
        this._handleErrorService.forbiddenAccess(new AuthenticateException('forbidden', 403, error));
        return Promise.reject("can't initialize " + JSON.stringify(error));
      })
  }


  public updatePartitionsWithUsers(allPartitions: Partition[]): Promise<Record<string, Partition>> {
    return this.getUsersFromPartition().then((userWithPartitions: Partition[]) => {
      const partitionsResult: Record<string, Partition> = {};
      userWithPartitions.filter(partR => {

        if (partR.hasAdminRight || partR?.users?.length != 0) {
          if (partR.hasAdminRight) {
            partR.hasAccess = true;
            return partR;
          }

          const usersP: UserPartition = partR.users.find(user => user.id == this._stateFn.getUser().ssin && user?.rights?.consult);
          if (usersP != undefined) {
            partR.hasAccess = true;
            return partR;
          }
        }
      });
      allPartitions.forEach(part => {
        const partitionWithUsers = userWithPartitions.find(userPartition => part.id == userPartition.id);
        if (partitionWithUsers) {
          partitionWithUsers.hasAdminRight = part.hasAdminRight;
          partitionWithUsers.users = partitionWithUsers.users.sort(sortUserPartition());
          partitionsResult[part.id] = { ...part, ...partitionWithUsers };
          partitionsResult[part.id].name = part?.name;
        } else {
          partitionsResult[part.id] = part;
        }
      });
      this._pacmanActionsStore.updateUserRightspartitions(Object.values(partitionsResult));
      return Promise.resolve(partitionsResult);

    }).then(resp => this.hasConsultationRightGeneral(Object.values(resp)).then(hasConsultationRightGeneral => {
      this._pacmanActionsStore.setConsultationRightGeneral(hasConsultationRightGeneral);
      return resp
    }))
      .catch(error => {
        // TODO PROPER ERROR HANDLING
        this.logger.error('Error updatePartitionsWithUsers()', error);
        this._handleErrorService.handlerError(error);
        return Promise.reject(error);
      });
  }

  addUserToPacman(url: string, queryParams: { [param: string]: string }, data: string): Promise<boolean> {
    return this.ws.callWs(url, HttpMethod.POST, queryParams, data, { 'Content-Type': 'application/json' });
  }

  deleteUserToPacman(url: string): Promise<boolean> {
    return this.ws.callWs(url, HttpMethod.DELETE);
  }



  getMyPartitions(hasAccessAdminPartition: Partition[]): Promise<Partition[]> {
    return this.getAllPartitionWithNames().then((partitionWithTranslated: Partition[]) => {
      hasAccessAdminPartition.forEach((partionWithAdminRight: Partition) => {
        partitionWithTranslated.find(partitionTranslated => {
          if (partitionTranslated.id == partionWithAdminRight.id) {
            partitionTranslated.hasAdminRight = true;
          }
        })
      });
      return partitionWithTranslated;
    }).then((partitionWithTranslated: Partition[]) => Promise.resolve(partitionWithTranslated)

    ).catch((error: HttpErrorResponse) => {
      // TODO PROPER ERROR HANDLING
      this.logger.error('Error getMyPartitions(%s)', JSON.stringify(error));
      if (this._storeService.store.getValue().subsystems.pacmanConsultationService && error.status == 404) {
        this._pacmanActionsStore.checkPacmanConsultationService(false);

        return Promise.reject([]);
      } else {
        if (error.status != 404) {
          this._handleErrorService.handlerError(error);

        }
        return Promise.reject(error);
      }
    });
  }

  private getUsersFromPartition(partitionId?: string): Promise<Partition[]> {
    const url = this.uriPacmanConsult + "/" + PacmanConstants.partitionsUsers.replace("{eboxId}", uniformizeCbeNumber(this._stateFn.getUser().cbeNumber));
    const params = { "userType": "human" };
    if (partitionId !== undefined) params["partitionId"] = partitionId
    return this.ws.callWs<{ items: [] }>(url, HttpMethod.GET, params)
      .then((e: { "items": [] }) => {
        const partitionsResult: Record<string, Partition> = {};
        e.items.forEach((item: { "user": UserPartition, id: string, partitionId: string, "rights": Rights }) => {
          const partitionUser: UserPartition = { ...item.user, rights: item.rights, businessId: item.id };

          if (partitionUser.id !== undefined) {
            if (partitionsResult[item.partitionId] == undefined) {
              partitionsResult[item.partitionId] = { id: item.partitionId, name: {}, users: [], nbApplicationUsers: 0, nbHumanUsers: 0, hasAdminRight: false, hasAccess: false };
            }
            partitionsResult[item.partitionId].users.push(partitionUser);
          }
        });

        return Promise.resolve(Object.values(partitionsResult));
      }).catch(error => {
        // TODO PROPER ERROR HANDLING
        this.logger.error('Error getUsersFromPartition()', error);
        this._handleErrorService.handlerError(error);
        return Promise.reject(error);
      });
  }


  //TODO reuse this one once new scopes available and delete getPartitionsAdminRightWithHeaders
  // private getPartitionsAdminRight(): Promise<Partition[]> {
  //   const url = this.uriPacmanAdmin + "/" + PacmanConstants.partitions.replace("{eboxId}", uniformizeCbeNumber(this._stateFn.getUser().cbeNumber));
  //
  //   return this.ws.callWs<{ partitions: [] }>(url, HttpMethod.GET, {})
  //     .then((e: { "partitions": [] }) => {
  //       const partitions: Partition[] = e.partitions.map((result: Partition) => { result.hasAdminRight = true; return result; });
  //       return Promise.resolve(partitions);
  //     }).catch(error => {
  //       // TODO PROPER ERROR HANDLING
  //       this.logger.error('Error getPartitionsAdminRight() : %s', error);
  //       return Promise.reject(error);
  //     });
  // }


  private getPartitionsAdminRightWithHeaders(): Promise<HttpEvent<{ partitions: [] }>> {
    const url = this.uriPacmanAdmin + "/" + PacmanConstants.partitions.replace("{eboxId}", uniformizeCbeNumber(this._stateFn.getUser().cbeNumber));
    return this.ws.httpGet<{ partitions: [] }>(url, {}).toPromise();
  }



  // get traductionName allPartitions
  private getAllPartitionWithNames(): Promise<Partition[]> {

    const url = this.uriPacmanConsult + "/" + PacmanConstants.partitions.replace("{eboxId}", uniformizeCbeNumber(this._stateFn.getUser().cbeNumber));
    return this.ws.callWs<{ items: [] }>(url, HttpMethod.GET, {})
      .then((e: { "items": [] }) => {
        const partitions: Partition[] = [];
        e.items.forEach((item: { id: string, "name": Translated }) => {
          const partition: Partition = { id: item.id, name: item.name, hasAdminRight: false, hasAccess: false };
          partitions.push(partition);
        });

        return Promise.resolve(partitions);

      }).catch(error => {
        // TODO PROPER ERROR HANDLING
        this.logger.error('Error getAllPartitions(%s)', JSON.stringify(error));
        throw error;
      });
  }

  private hasConsultationRightGeneral(rightPartition: Partition[]): Promise<boolean> {
    let hasConsultationRightGeneral = this._storeService.store.getValue().pacmanSession.hasConsultationRightGeneral;
    const statusPacmanConsultationService = this._storeService.store.getValue().subsystems.pacmanConsultationService;

    if (this._appConstant.isNullOrUndefined(hasConsultationRightGeneral)) {
      if (!statusPacmanConsultationService) return Promise.resolve(false);

      const partitionResult = rightPartition.find(partition => partition.id == this.DEFAULT_ID_PARTITION);
      hasConsultationRightGeneral = !this._appConstant.isNullOrUndefined(partitionResult);
      return Promise.resolve(hasConsultationRightGeneral);

    } else {
      return Promise.resolve(hasConsultationRightGeneral);
    }
  }


  createOrEditPartition(partition: Partition, edit: boolean): Promise<boolean> {
    if (edit) {
      const urlUpdate = this._configService.getEnvironmentVariable("urlPacmanAdmin") + "/" + PacmanConstants.partitionById
        .replace("{eboxId}", uniformizeCbeNumber(this._stateFn.getUser().cbeNumber))
        .replace("{partitionId}", partition.id);
      return this.ws.callWs(urlUpdate, HttpMethod.PATCH, {}, {name:partition.name});
    }
    const urlCreate = this._configService.getEnvironmentVariable("urlPacmanAdmin")+ "/" + PacmanConstants.partitions.replace("{eboxId}", uniformizeCbeNumber(this._stateFn.getUser().cbeNumber));
    return this.ws.callWs(urlCreate, HttpMethod.POST, {}, partition);
  }

  deletePartition(partitionId: string) : Promise<boolean> {
    const urlDelete = this._configService.getEnvironmentVariable("urlPacmanAdmin")+ "/" + PacmanConstants.partitionById
      .replace("{eboxId}", uniformizeCbeNumber(this._stateFn.getUser().cbeNumber))
      .replace("{partitionId}", partitionId);
    return this.ws.callWs(urlDelete, HttpMethod.DELETE, {});
  }
}
