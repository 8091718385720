import { Component, OnInit } from '@angular/core';
import { Policy } from "../../../pacman/interfaces/policy";
import { EnterprisePreferencesService } from "../../../services";
import { Router } from "@angular/router";

@Component({
  selector: 'app-docconsumer-policy',
  templateUrl: './docconsumer-policy.component.html',
  styles: [
  ]
})
export class DocconsumerPolicyComponent implements OnInit {

  error= false;
  acceptCheckboxEnabled = false;
  acceptBtnEnabled = false;
  policy: Policy;

  constructor(private eboxPrefServive: EnterprisePreferencesService, private router: Router) {
  }

  ngOnInit() {
    this.eboxPrefServive.getDocConsumerPolicy().then(policy => {
        this.policy = policy
      });
  }

  accept() {
    this.eboxPrefServive.acceptDocConsumerPolicy().then((created) => {
      if (created) {
        this.router.navigateByUrl("/management");
      } else {
        this.error = true;
      }
    });
  }

  scrollEnds(event : any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.acceptCheckboxEnabled = true;
    }
  }
}
