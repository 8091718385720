import { Injectable } from '@angular/core';

import { saveAs } from 'file-saver';

import JSZip from 'jszip';

import { LoadingService } from './loading.service';
import { ResponsiveService } from './responsive.service';
//import { NGXLogger } from "ngx-logger";
import { AttachmentData } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';
import { ActionsService } from '../store/actions.service';
import { ResultDownload } from '../interfaces/download/result-download';
import { ResponseZip } from '../interfaces/download/response-zip';
import { ResponseStatus } from "@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData";


@Injectable()
export class DownloadDocumentService {
  constructor(
    private _loadingService: LoadingService,
    private _responsiveService: ResponsiveService, private _actionsService: ActionsService) {
  }



  public zipFileDocument(listMessage: { messageId: string, registryId: string, listDocument: AttachmentData[], hasBody: boolean }[]): Promise<ResponseZip> {
    const response: ResponseZip = { status: ResponseStatus.fail };
    return new Promise<ResponseZip>((resolve, reject) => {
      this._loadingService.stop('DownloadDocumentService zipFile begin');
      this._loadingService.loadingPercentProgress = 0;
      this._loadingService.start({ serviceRunStart: "zipFileDocument", delayedStart: true, timeoutActive: false });
      const jszip: JSZip = JSZip();
      const listErrorMessage: ResultDownload[] = [];
      const allPromiseDownload: Promise<any>[] = listMessage.map(message => {
        let attachmentByMessage: Promise<any>[];
        if (message.hasBody) {
          const fileName: string = message.messageId.concat("_")
            .concat(message.registryId).concat('_')
            .concat("body.txt");
          const promiseFileName = Promise.resolve(fileName);
          const promiseAttachement = this._actionsService.bodyContentOfMessage(message.messageId, message.registryId, false).then(content => Promise.resolve({ fileName: fileName, providerId: message.registryId, content: content })).catch(error => Promise.resolve({ fileName: fileName, providerId: message.registryId, error: error } as ResultDownload));
          //TODO VERIFI

          attachmentByMessage = [Promise.all([promiseFileName, promiseAttachement])];
        }
        if (message.listDocument.length === 1) {
          const attachment = message.listDocument[0] as AttachmentData;
          let fileName: string = attachment.fileName;
          fileName = message.messageId.concat("_")
            .concat(message.registryId).concat('_')
            .concat(fileName.substring(0, fileName.lastIndexOf('.')))
            .concat(message.registryId).concat('_')
            .concat(fileName.substring(fileName.lastIndexOf('.'), fileName.length));
          const newPromise = message.listDocument.map(() => {
            const promiseFileName = Promise.resolve(fileName);
            const promiseAttachement = this._actionsService.getAttachmentContent(attachment, message.registryId);
            return Promise.all([promiseFileName, promiseAttachement]);
          })

          //TODO VERIFI
          if (attachmentByMessage != undefined && attachmentByMessage.length != 0) {
            attachmentByMessage.push(...newPromise);
          } else {
            attachmentByMessage = [...newPromise];

          }

          //catch: resolve is usefull to ignore download

        } else if (message.listDocument.length > 1) {
          let folderName: string = message.listDocument[0].fileName;
          folderName = message.messageId.concat("_")
            .concat(message.registryId).concat('_')
            .concat(folderName.replace(/  /g, ' ').replace(/ /g, '_').replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g, '_').replace(/_+/g, '_'));

          const fileNames = new Map();
          const newPromise = message.listDocument.map((attachment: AttachmentData) => {
            const lastIndex = fileNames.has(attachment.fileName) ? fileNames.get(attachment.fileName) : 0;
            let uniqueFileName;
            if (lastIndex < 0) {
              uniqueFileName = attachment.fileName;
            } else {
              const extensionLocation = attachment.fileName.lastIndexOf(".");
              const prefix = attachment.fileName.substring(0, extensionLocation);
              const suffix = attachment.fileName.substring(extensionLocation);
              uniqueFileName = prefix + "(" + (lastIndex + 1) + ")" + suffix;
            }
            fileNames.set(attachment.fileName, lastIndex + 1);


            const promiseFileName = Promise.resolve(folderName.concat('/').concat(uniqueFileName));
            const promiseAttachement = this._actionsService.getAttachmentContent(attachment, message.registryId);
            return Promise.all([promiseFileName, promiseAttachement]);

          });
          if (attachmentByMessage != undefined && attachmentByMessage.length != 0) {
            attachmentByMessage.push(...newPromise);
          } else {
            attachmentByMessage = newPromise;

          }


        }
        return Promise.all(attachmentByMessage);
      });



      return Promise.all(allPromiseDownload)
        .then(allPromises => {
          allPromises.forEach((promisesExecuted) => {
            promisesExecuted.forEach((promiseDownload: ResultDownload) => {
              const content = promiseDownload[1].content;
              if (content != undefined) {
                jszip.file(promiseDownload[0], content, { binary: true })
              } else {
                listErrorMessage.push(promiseDownload[1]);
              }
            });

          });
        })
        .then(() => {
          const loadingServiceMessage = this._loadingService;
          const responsiveService = this._responsiveService;
          const nbFiles = (Object.values(jszip.files).filter(v => !v["dir"])).length;

          if (Object.values(jszip.files).length != 0 || nbFiles > 0) {
            if (responsiveService.isIosDevice()) {
              return jszip.generateAsync({
                type: 'base64',
                streamFiles: true
              }, function updateCallback(metadata: { percent: number, currentFile: string }) {
                loadingServiceMessage.loadingPercentProgress = parseFloat(metadata.percent.toFixed(2));
              })
                .then((content) => location.href = 'data:application/zip;base64,' + content)
                .then(() => {
                  response.listMessageError = listErrorMessage;
                  response.status = listErrorMessage.length == 0 ? ResponseStatus.complete : ResponseStatus.partial;
                  return resolve(response);
                })
                .catch((ex) => {
                  response.listMessageError = listErrorMessage;
                  response.status = ResponseStatus.fail
                  return resolve(response);
                }
                ).finally(() => this._loadingService.stop('DownloadDocumentService zipFile error'));
            } else {
              return jszip.generateAsync({
                type: 'blob',
                streamFiles: true
              }, function updateCallback(metadata: { percent: number, currentFile: string }) {
                loadingServiceMessage.loadingPercentProgress = parseFloat(metadata.percent.toFixed(2));
              })
                .then((content) => saveAs(content, 'ebox_enterprise_document_' + (new Date().getTime()) + '.zip'))
                .then(() => {
                  response.listMessageError = listErrorMessage;
                  response.status = listErrorMessage.length == 0 ? ResponseStatus.complete : ResponseStatus.partial;
                  return resolve(response);
                })
                .catch((ex) => {
                  response.listMessageError = listErrorMessage;
                  response.status = ResponseStatus.fail
                  return resolve(response);
                }
                ).finally(() => this._loadingService.stop('DownloadDocumentService zipFile error'));
            }
          } else {
            response.listMessageError = listErrorMessage;
            response.status = ResponseStatus.fail;

            return reject(response)
          }

        })
        .finally(() => this._loadingService.stop("Finish process zip"));
    })
      .catch(error => {
        this._loadingService.stop("Finish process zip")
        response.status = ResponseStatus.fail;
        response.error = error;
        return Promise.reject(response);

      }).finally(() => this._loadingService.stop("Finish process zip"));



  }

}



