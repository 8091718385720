<div class="card border-0">

    <div class="modal-header bg-transparent border-0 p-0 d-flex flex-wrap">
        <h3>{{'messages.actionsMessage.move.modal.header.title'.concat(nbMessageSelected
            == 1 ? '.one' : '.many') | translate:{value: nbMessageSelected} }}</h3>

    </div>
    <form [formGroup]="movePartitionForm" method="post"
        class=" {{(movePartitionForm.status ==='INVALID') ? 'was-validated':''}}"
        (ngSubmit)="movePartitionSubmit()" enctype="multipart/form-data">

        <div class="modal-body px-0">
            <div class="card border-0 p-0">
                <div class="card-header alert-danger bg-transparent"
                    *ngIf="movePartitionForm.errors != null" role="alert">
                    <div class="text-danger">
                        <h4 class="alert-heading">{{'form.status.error' |
                            translate}}</h4>
                    </div>
                    <div class="p-2 d-block d-sm-flex align-items-center">
                        <p>{{'messages.actionsMessage.move.modal.body.forms.error.default'
                            | translate}}</p>
                    </div>

                </div>
                <div class="card-body"><p
                        [innerHTML]="('messages.actionsMessage.move.modal.body.text.main' | translate).concat(' ').concat(nbMessageSelected == 1 ? ('messages.actionsMessage.move.modal.body.text.one' | translate): ('messages.actionsMessage.move.modal.body.text.many' | translate))"></p><div
                        class="form-group col-12 px-0">
                        <label for="senderPartition" id="labelSenderPartition"
                            [class]=" (movePartitionForm.controls.senderPartition.errors && movePartitionForm.controls.senderPartition.errors.senderPartitionError) ? 'invalid-field' : '' ">
                            <span>{{'messages.actionsMessage.move.modal.body.select.label'
                                | translate}}</span>
                            <div role="alert" class="invalid-feedback"
                                id="errorSenderPartition">
                                <ng-container
                                    *ngIf="movePartitionForm.controls.senderPartition.errors && movePartitionForm.controls.senderPartition.errors.senderPartitionError">
                                    <span class="d-block"> ({{'messages.actionsMessage.move.modal.body.forms.error.none'
                                        | translate }}) </span>
                                </ng-container>
                            </div>
                        </label>
                        <div class="col-12 px-0">
                            <div class="alert alert-warning bg-yellow"
                                *ngIf="!hasAccessToPartition">
                                <p
                                    [innerHTML]="'messages.actionsMessage.move.modal.body.select.noAccessToPartition.default' | translate"></p>
                                <p
                                    [innerHTML]="'messages.actionsMessage.move.modal.body.select.noAccessToPartition'.concat(nbMessageSelected
                                   == 1 ? '.one' : '.many') | translate"></p>

                            </div>
                            <ng-container *ngIf="myPartitions.length==1">
                                <input id="senderPartition" type="text"
                                    [value]="movePartitionForm.controls.senderPartition.value"
                                    formControlName="senderPartition" class="border-0 form-control-plaintext" [attr.readonly]="true" />
                            </ng-container>
                            <ng-container *ngIf="myPartitions.length>1">

                                <select id="senderPartition" appSelectToDropdown
                                    #selectPartition
                                    class="form-select "
                                    formControlName="senderPartition">
                                    <option value="default"
                                        [selected]="movePartitionForm.controls.senderPartition.value == 'default' ? true : null">{{'messages.actionsMessage.move.modal.body.select.default'
                                        | translate}}</option>
                                    <option
                                        *ngFor="let partition of myPartitions; let i = index"
                                        [value]="partition.id"
                                        [attr.data-hasAccess]="partition.hasAccess"
                                        [selected]="movePartitionForm.controls.senderPartition.value == null ? true : null">{{partition.id
                                        | partitionNamePipe}}</option>
                                </select>
                            </ng-container>

                        </div>

                    </div></div>

            </div>
        </div>
        <div
            class="modal-footer justify-content-between border-0 d-xs-flex px-0">
            <a href="javascript:void(0)"
                class="text-underline btn btn-link p-0 ms-0"
                (click)="closeAndResetModal()">{{'messages.actionsMessage.move.modal.body.button.cancel'
                |translate }}</a>
            <button class="btn btn-primary" type="submit"
                [disabled]="movePartitionForm.controls.senderPartition.value == 'default' ? true : null">{{'messages.actionsMessage.move.modal.body.button.move'
                | translate }} </button>

        </div>
    </form>

</div>