<div class="modal-header">
  <h3 class="align-middle">{{"pacman.managementUser.forms.actionUser.delete" |
    translate:{value:userPartitionForm.user.id} }}</h3>
</div>
<div class="modal-body bg-transparent {{isMobile ?'p-0':''}}">
  <div class="alert alert-warning bg-yellow">
    <p>{{"pacman.managementUser.forms.actionUser.delete.question" | translate }}</p>

    <div class="justify-content-between  d-flex">
      <a href="javascript:void(0)"
        class="text-underline bg-transparent btn btn-link ms-0 p-0"
        (click)="closeAndResetModal()">{{"pacman.managementUser.forms.actionUser.button.cancel"
        | translate }}</a>
      <button (click)="deleteUser()" type="button" class=" btn btn-primary me-0"
        [title]="'pacman.managementUser.forms.actionUser.button.delete' | translate">{{"pacman.managementUser.forms.actionUser.button.delete"
        | translate }}</button>

    </div>
  </div>
</div>
