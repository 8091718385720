import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import {
  NgbActiveModal,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbModal,
  NgbModule,
  NgbPopoverModule,
  NgbProgressbarConfig
} from '@ng-bootstrap/ng-bootstrap';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslatedStringPipe } from './pipes/translated-string.pipe';
import { SenderApplicationPipe } from './pipes/sender-application.pipe';
import { SenderOrganizationPipe } from './pipes/sender-organization.pipe';
import { StreamToImage64Pipe } from './pipes/stream-to-image64.pipe';
import { SortPropertiesPipe } from './pipes/sort-properties.pipe';

import { OpenIdService } from './auth/openIdService';
import { Authentication } from './auth/authentication';

import { ApplicationConstants } from './app.constants';
import { config } from './configuration-data';
import { ConfigurationModule, ConfigurationService } from '@smals/ngx-configuration-service';

import { TranslateModule } from '@ngx-translate/core';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ManagementComponent } from './components/management/management.component';
import { DetailComponent } from './components/detail/detail.component';
import { FormsBidirectionalComponent } from './components/bidirectional/forms-bidirectional.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LogoFormatDirective } from './directives/logo-format.directive';
import { SameHeightDirective } from './directives/same-height.directive';
import { ExpiredDateFCDirective } from './directives/expired-date-fc.directive';
import { PaginationComponent } from './components/messages-from-ebox/pagination/pagination.component';

import { SelectItemsByPageComponent } from './components/search/elements/select-items-by-page.component';
import { FirstLetterUpperCasePipe } from './pipes/first-letter-upper-case.pipe';
import { MessageTypePipe } from './pipes/message-type.pipe';
import { SearchBarComponent } from './components/search/search-bar.component';
import { CommunicationComponent } from './components/communication/communication.component';
import { SearchFilterBarComponent } from './components/search/search-filter-bar.component';
import { DatepickerFormat } from './services/datepicker-format';
import { CustomDatepickerI18n } from './services/custom-datepicker-i18n';
import { FocusInputGroupDirective } from './directives/focus-input-group.directive';
import { WSHelper } from './helper/WSHelper';
import { BlankComponent } from './components/oauthCallback/blank.component';
import { DirectAccessComponent } from './components/oauthCallback/DirectAccessComponent';
import { EmailUpdateComponent } from './components/management/email-update/email-update.component';
import { ActionsService } from './store/actions.service';

import { MockLoginComponent } from './auth/mock-login.component';
import { ErrorPageComponent } from './error/error-page.component';
import { NavHelper } from "./helper/NavHelper";

import { DateHelper } from './helper/DateHelper';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ActionsStore } from './store/actions-store';
import { ObjectHelper } from './helper/ObjectHelper';
import { EboxClientProvider } from "./services/EboxClientProvider";
import { FilterBarModal } from "./components/modal-elements/filter-bar-modal.component";
import { StoreService } from './store/store.service';
import { StateFn } from './store/StateFn';
import { FileSizePipe } from './pipes/file-size.pipe';
import { createTranslateLoader, versionControlConfigFactory } from './app.external';
import { ManualPublicationComponent } from './components/manual-publication/manual-publication.component';

import { FormsHelper } from './helper/Forms-helper';
import { ConfigurationRegistries } from './registries/configuration-registries';
import { HttpIntercept } from "./httpinterceptor";
import { LeftnavComponent } from './components/leftnav/leftnav.component';
import { MainComponent } from './components/main/main.component';
import { SearchComponent } from './components/search/search.component';
import { ActionsComponent } from './components/actions/actions.component';
import { MessagesComponent } from './components/messages/messages.component';
import { MessageComponent } from './components/messages/message/message.component';
import { SummaryComponent } from './components/messages/message/summary.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { AttachmentComponent } from './components/attachments/attachment.component';
import { ManagePartitionUsersComponent } from "./pacman/managePartitionUsers/manage-partition-users.component";
import { AddUserComponent } from "./pacman/managePartitionUsers/add-user.component";

import { DetailsComponent } from './components/detail/details.component';
import { TagComponent } from './components/messages/message/tag.component';

import { ErrorService } from "./error.service";
import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_METADATA_SERVICE, TOKEN_LOGGER_WRITER_SERVICE } from 'ngx-logger';

import { DownloadDocumentService } from './services/download-document.service';
import { EnterprisePreferencesService } from './services/enterprise-preferences.service';
import { LoadingService } from './services/loading.service';
import { ResponsiveService } from './services/responsive.service';
import { PacmanActionService } from "./pacman/services/pacmanAction.service";
import { ManagementPartitionsComponent } from './pacman/managePartitions/management-partitions.component';
import { PacmanActionStore } from './pacman/store/pacmanActionStore';
import { DeleteUserComponent } from './pacman/managePartitionUsers/delete-user.component';
import { DeletePartitionComponent } from './pacman/managePartitions/delete-partition.component';
import { NgxSmalsUtilsValidationModule, SsinPipe } from '@smals/ngx-smalsutils-validation';
import { NGXLoggerMetadataServiceCustom } from './services/ngxlogger-metadata-service-custom';
import { NGXLoggerWriterServiceCustom } from './services/ngxlogger-writer-service-custom';
import { PartitionNamePipe } from './pacman/pipes/partition-name.pipe';
import { ConfigurationServiceExtended } from './services/configuration-service-extended';
import { OptinflowComponent } from "./components/optinFlow/optinflow.component";
import { MovePartitionComponent } from './components/actions/movePartition/move-partition.component';
import { AddEditPartitionComponent } from './pacman/managePartitions/add-edit-partition.component';
import { SelectToDropdownDirective } from './directives/select-to-dropdown.directive';
import { AuthConfigModule } from './auth/authOidcClientConfig';
import { AuthorizationEndpointParameterService } from './auth/open-id-configurator';
import { PartitionPolicyComponent } from './components/management/partition-policy/partition-policy.component';
import { DocconsumerPolicyComponent } from './components/management/docconsumer-policy/docconsumer-policy.component';
import { SentryManageStorage } from './services/sentry/sentry-manage-storage';

export const configNGXLogger = { level: NgxLoggerLevel.ERROR, timestampFormat: 'dd/MM/yyyy  HH:mm:ss ', enableSourceMaps: true };

const moduleImports = [
  BrowserModule,
  FormsModule,
  HttpClientModule,
  BrowserAnimationsModule,
  AppRoutingModule,
  ...versionControlConfigFactory(),
  ...createTranslateLoader(),
  NgbPopoverModule, NgbModule, FormsModule, ReactiveFormsModule, ConfigurationModule.forRoot(config),
  LoggerModule.forRoot(configNGXLogger, {
    writerProvider: {
      provide: TOKEN_LOGGER_WRITER_SERVICE, useClass: NGXLoggerWriterServiceCustom
    },
    metadataProvider: {
      provide: TOKEN_LOGGER_METADATA_SERVICE, useClass: NGXLoggerMetadataServiceCustom
    }
  }), NgxSmalsUtilsValidationModule,
  AuthConfigModule


]

@NgModule({
  declarations: [
    BlankComponent,
    DirectAccessComponent,
    AppComponent,
    TranslatedStringPipe,
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    ManagementComponent,
    LogoFormatDirective,
    SameHeightDirective,
    SenderOrganizationPipe,
    SenderApplicationPipe,
    StreamToImage64Pipe,
    SortPropertiesPipe,
    ExpiredDateFCDirective,
    PaginationComponent,
    FilterBarModal,
    SelectItemsByPageComponent,
    FirstLetterUpperCasePipe,
    MessageTypePipe,
    SearchBarComponent,
    CommunicationComponent,
    DetailComponent,
    SearchFilterBarComponent,
    FocusInputGroupDirective,
    FormsBidirectionalComponent,
    EmailUpdateComponent,
    MockLoginComponent,
    ErrorPageComponent,
    NotFoundComponent,
    FileSizePipe,
    ManualPublicationComponent,
    LeftnavComponent,
    MainComponent,
    SearchComponent,
    MessagesComponent,
    MessageComponent,
    SummaryComponent,
    AttachmentsComponent,
    ActionsComponent,
    AttachmentComponent,
    ManagePartitionUsersComponent,
    DetailsComponent,
    AddUserComponent,
    OptinflowComponent,
    TagComponent,
    ManagementPartitionsComponent,
    DeleteUserComponent,
    DeletePartitionComponent,
    PartitionNamePipe,
    MovePartitionComponent,
    AddEditPartitionComponent,
    SelectToDropdownDirective,
    PartitionPolicyComponent,
    DocconsumerPolicyComponent
  ],
  imports: moduleImports,
  exports: [
    TranslateModule
  ],
  providers: [
    ApplicationConstants,
    DownloadDocumentService, StoreService, StateFn,
    ActionsService, TranslatedStringPipe, SortPropertiesPipe, LoadingService, Authentication, DatePipe,
    NgbModal, NgbActiveModal, NgbProgressbarConfig, ResponsiveService, EnterprisePreferencesService, PacmanActionService,
    { provide: ErrorHandler, useClass: ErrorService },
    { provide: NgbDateParserFormatter, useClass: DatepickerFormat },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    OpenIdService, WSHelper, NavHelper, DateHelper, ActionsStore, ObjectHelper, EboxClientProvider, FormsHelper, ConfigurationRegistries, PacmanActionService, PacmanActionStore,
    { provide: HTTP_INTERCEPTORS, useClass: HttpIntercept, multi: true },
    { provide: APP_BASE_HREF, useValue: '/' }, { provide: ConfigurationService, useClass: ConfigurationServiceExtended },
    SsinPipe, AuthorizationEndpointParameterService, SentryManageStorage
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
