<div class="modal-header">

    <h3 class="align-middle">{{'pacman.managementUser.forms.actionUser.new' |
        translate:{value:(userPartitionForm ? '' :'')} }}</h3>
    <button *ngIf="!isMobile" type="button" class=" close me-0 pe-0"
        aria-label="Close" (click)="closeAndResetModal()">
        <i class="fas fa-times  me-0" aria-hidden="true"></i>
        <span class="visually-hidden">{{"form.action.cancel" | translate}}</span>
    </button>

</div>
<form [formGroup]="addUserToPartitionForm" accept-charset="utf-8" method="post"
    enctype="multipart/form-data"
    class=" {{(addUserToPartitionForm.status ==='INVALID') ? 'was-validated':''}}"
    (ngSubmit)="onMailFormDataSubmit()">
    <div class="modal-body {{isMobile?'bg-transparent':'bg-white'}}">

        <div class="card mb-3 col p-0 alert-danger"
            *ngIf="addUserToPartitionForm.errors != null && addUserToPartitionForm.errors.addUserToPartitionFormHttpError != undefined && statusSendingForm==StatusFormsEnum.error"
            role="alert">
            <div class="card-header text-danger">
                <h4 class="alert-heading">{{'form.status.error' | translate}}</h4>
            </div>
            <div class="card-body p-2">
                <div class=" d-block d-sm-flex align-items-center">

                    <p
                        [ngSwitch]="addUserToPartitionForm.errors.addUserToPartitionFormHttpError.type">
                        <ng-container *ngSwitchCase="'userIsNotPartOfEbox'">
                            {{'pacman.pacmanProblem.'.concat(addUserToPartitionForm.errors.addUserToPartitionFormHttpError.type)
                            |
                            translate:{value1:addUserToPartitionForm.errors.addUserToPartitionFormHttpError.args.value1
                            ,
                            value2:addUserToPartitionForm.errors.addUserToPartitionFormHttpError.args.value2}
                            }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'userAlreadyAssigned'">
                            {{'pacman.pacmanProblem.'.concat(addUserToPartitionForm.errors.addUserToPartitionFormHttpError.type)
                            |
                            translate:{value1:addUserToPartitionForm.errors.addUserToPartitionFormHttpError.args.value1
                            ,
                            value2:addUserToPartitionForm.errors.addUserToPartitionFormHttpError.args.value2}
                            }}
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{'pacman.pacmanProblem.'.concat(addUserToPartitionForm.errors.addUserToPartitionFormHttpError.type)
                            | translate }}
                        </ng-container>
                    </p>

                </div>
            </div>
        </div>
        <div class="alert alert-info mb-3 ">
            <div class=" container-fluid">
                <div class="row">
                    <p class="g-0 d-flex py-1">
                        <i class="fas fa-info-circle col-auto"
                            aria-hidden="true" aria-describedby="#srOnlyInfo"></i>
                        <span class="visually-hidden" id="srOnlyInfo">{{'communications.info.title'
                            | translate}}</span>

                        <span class="col pe-1"
                            [innerHTML]="'pacman.managementUser.addUserInfo' | translate:{value1:urlUman}">
                        </span>

                    </p>
                </div>
            </div>
        </div>

        <div class="form-group {{!isMobile ? 'g-0' :''}}">

            <label id="nissUserLabel" for="userNissInput"
                [class]="(addUserToPartitionForm.controls.userSearchNiss.errors && addUserToPartitionForm.controls.userSearchNiss.errors.userNissError) ? 'col invalid-field mb-2 mt-1' : 'col mb-2 mt-1' ">
                <span>{{'pacman.managementUser.forms.humanUser.label' |
                    translate}} ({{'form.required.label' | translate}})</span>

            </label>
            <div class="col">
                <input id="typeahead-basic"
                    class="col form-control{{userPartitionForm && userPartitionForm.user != undefined ? '-plaintext border-0':''}}  {{(addUserToPartitionForm.controls.userSearchNiss.errors && addUserToPartitionForm.controls.userSearchNiss.errors.userNissError) ? 'is-invalid' :'' }}"
                    type="text" class="form-control"
                    formControlName="userSearchNiss"
                    [popupClass]="isMobile ? 'mobileTypeHead' :
                     null" [ngbTypeahead]="search"
                    [inputFormatter]="formatterResultSearchNiss"
                    [resultFormatter]="formatterResultSearchNiss"
                    [editable]='false'
                    aria-labelledby="nissUserLabel errorUserNissInput"
                    (blur)="blurEvent($event.target || $event.srcElement)"
                    ngbAutofocus />

                <div role="alert" class="invalid-feedback d-block w-100"
                    id="errorUserNissInput">
                    <ng-container
                        *ngIf="addUserToPartitionForm.controls.userSearchNiss.errors && addUserToPartitionForm.controls.userSearchNiss.errors.userNissError && addUserToPartitionForm.controls.userSearchNiss.errors && addUserToPartitionForm.controls.userSearchNiss.errors.userNissError != 'userIsNotPartOfEbox'">
                        <span class="d-block"> {{'pacman.managementUser.forms.humanUser.error.'.concat(addUserToPartitionForm.controls.userSearchNiss.errors.userNissError)|
                            translate }} <ng-container
                                *ngIf="addUserToPartitionForm.controls.userSearchNiss.errors.userNissError =='errorFormat'">"{{'pacman.managementUser.forms.humanUser.description' | translate}}"</ng-container>
                        </span>
                    </ng-container>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer justify-content-between  d-xs-flex">
        <a href="javascript:void(0)"
            class="text-underline btn btn-link p-0 ms-0"
            (click)="closeAndResetModal()">{{'pacman.managementUser.forms.actionUser.button.cancel'
            | translate}}</a>
        <button [disabled]="errorsForms" type="submit"
            class="btn btn-primary me-0"
            [title]="'form.action.submit.label' | translate">{{'pacman.managementUser.forms.actionUser.button.'.concat('add')
            | translate}}
        </button>

    </div>
</form>